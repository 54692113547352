import React ,{ useState }from 'react'


const PaymentDetail = ({ openModalthankyou}) => {
   
    const [orderId, setOrderId,] = React.useState(0); 
    const [orderCalculator,setOrderCalculator]=React.useState();   
    const [cardMember, setcardMember,] = useState('');  
    const [name, setNAME,] = useState('');  
    const [expiryDate, setExpiryDate,] = useState('');  
    const [cvv, setCvv,] = useState('');  
    


    React.useEffect(() => {
      
    }, []);

    const onSubmit=()=>
    {       
        openModalthankyou();
    }

    return (
        <div>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='paymentDetailsheader'>
                            <h1 className='paymentdet'>Payment Details</h1>
                            <span className='paysub'> Please enter your payment details and confirm your payment method.</span>
                        </div>
                    </div>
                    <div className='col-lg-12'>
                        <div className='cardDetails-box'>
                            <div className='cardDetails'>
                                <h1>Card Details</h1>
                                <div class="did-floating-label-content">
                                    <input className="did-floating-input" id  placeholder={'CARD NUMBER'} type="text"
                                    onChange={(e)=> setcardMember(e.target.value)}
                                    >
                                    </input>
                                    <label className="did-floating-label">CARD NUMBER</label>
                                </div>
                                <div class="did-floating-label-content">
                                    <input className="did-floating-input" placeholder={'NAME'} type="text"
                                     onChange={(e)=> setNAME(e.target.value)}>

                                    </input>
                                    <label className="did-floating-label">NAME</label>
                                </div>
                                <div class="did-floating-label-content">
                                    <input className="did-floating-input" placeholder={'EXPIRED DAY'} type="text" 
                                     onChange={(e)=> setExpiryDate(e.target.value)}>
                                    </input>
                                    <label className="did-floating-label">EXPIRED DAY</label>
                                </div>
                                <div class="did-floating-label-content">
                                    <input className="did-floating-input" placeholder={'CVV'} type="text"
                                     onChange={(e)=> setCvv(e.target.value)}>
                                    </input>
                                    <label className="did-floating-label">CVV</label>
                                </div>
                            </div>
                            <div>
                                <div className='pay-button'>
                                    <button onClick={ onSubmit} className='btnpaynow'>Pay Now</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default PaymentDetail;