import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { LanguageContext } from "../../context/language.context";

import { baseApiUrl, baseInstanceDetailsURL } from "../../utils/base";

import PreLoader from "../../components/preLoader/preLoader";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

import {
  convertDate,
  convertTime,
  addHours,
  getLocalStorage,
} from "../../utils/common";

import LeftArrow from "../../images/leftarrow.svg";

const PastWorkshopDetails = () => {
  const { booking_id, workshop_id, origin } = useParams();

  const [language] = useContext(LanguageContext);

  const [showPreLoader, setShowPreLoader] = useState(true);
  const [workshop, setWorkshop] = useState(false);

  const usedLanguage = language.translations[language.selectedLanguage];

  const gobacklink = `/${origin}?lang=${language.selectedLanguage}`;
  const { VH_goBack } = usedLanguage.ViewAll;

  const {
    Dash_Yes,
    Dash_No,
    Dash_MB_1,
    Dash_MB_2,
    Dash_MB_3,
    Dash_MB_3_1,
    Dash_MB_5,
    Dash_MB_30,
    Dash_MB_31,
  } = usedLanguage.Dashboard;

  const {
    workshopHeading,
    past_teamprofile_download,
    past_participants_heading,
    past_participants_info,
    past_mod,
    past_mod_info,
    past_mod_button,
  } = usedLanguage.workshopDetailsPage;

  const calculateWorkshopStartTimeZeroOffset = (timeZoneDiff, date, time) => {
    const difference = Number(timeZoneDiff) * 0.6;
    const updatedHours = Number(time.split(":")[0]) + difference;
    const updatedHoursEnd = updatedHours + 3;

    const datePart = date.split("T")[0];
    const updatedTimePart = `${updatedHours}:00:00.000+00:00`;
    const updatedTimePartEnd = `${updatedHoursEnd}:00:00.000+00:00`;
    const startDateTime = `${datePart}T${updatedTimePart}`;
    const endDateTime = `${datePart}T${updatedTimePartEnd}`;

    return { startDateTime, endDateTime };
  };

  useEffect(() => {
    var getAuthDetails = getLocalStorage("authDetailsLocal");
    const customer_id = getAuthDetails.userId;

    fetch(`${baseApiUrl}client/bookedworkshop`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getAuthDetails.token}`,
      },
      body: JSON.stringify({
        customerId: customer_id,
        bookingid: booking_id,
        workshopid: workshop_id,
        date: "past",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setWorkshop(data.data.bookingList[0]);
        setShowPreLoader(false);
        console.log(data);
        const { timezonediff, workshopDate, workshopTime } =
          data.data.bookingList[0];
        const { startDateTime, endDateTime } =
          calculateWorkshopStartTimeZeroOffset(
            timezonediff,
            workshopDate,
            workshopTime
          );

        fetch(
          `${baseInstanceDetailsURL}sessions/findbytime/${workshop_id}/${startDateTime}/${endDateTime}`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <section>
      <Navbar showPortalLink={false} />
      {showPreLoader && <PreLoader />}
      {workshop && (
        <>
          <div className="container">
            <div className="row">
              <div
                className="col-12"
                style={{ textAlign: "left", marginTop: "40px" }}
              >
                <img src={LeftArrow} className="leftarrow" alt="" />
                <Link to={gobacklink} id="goback" className="gobacktext">
                  {VH_goBack}
                </Link>
              </div>
            </div>
          </div>

          <div className="container team_heading">
            <h1 className="team_name">
              {workshopHeading}:{" "}
              <span className="black">{workshop.teamName}</span>
            </h1>
          </div>

          <div className="container gridcard workshopcard">
            <div className="row">
              <div className="col-lg-12">
                <div className="table-responsive">
                  <table className="table table-borderless single_workshop">
                    <thead className="thead tableheaders">
                      <tr>
                        <td>{Dash_MB_1}</td>
                        <td>{Dash_MB_2}</td>
                        <td>{Dash_MB_3}</td>
                        <td>{Dash_MB_3_1}</td>
                        <td>{Dash_MB_5}</td>
                        <td>{Dash_MB_30}</td>
                        <td>{Dash_MB_31}</td>
                      </tr>
                    </thead>
                    <tbody className="tablebodycolumn1">
                      <tr>
                        <td>
                          {convertDate(workshop.workshopDate, "dd/MM/yyyy")}
                        </td>
                        <td>
                          {convertTime(workshop.workshopTime) +
                            "-" +
                            convertTime(addHours(workshop.workshopTime))}
                        </td>
                        <td>{workshop.personInGroup}</td>
                        <td>{workshop.facilitated ? Dash_Yes : Dash_No}</td>
                        <td>{workshop.language.toUpperCase()}</td>
                        <td>100%</td>
                        <td>
                          <button className="download_teamprofile">
                            {past_teamprofile_download}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="container gridcard workshopcard co-mod">
            <h2 className="links_heading total_participants_past_workshop">
              <span>{past_participants_heading}</span>
              <span className="black">6</span>
            </h2>
            <p className="sub_info">{past_participants_info}</p>
            <div className="participants_list">
              <div className="participant">Participant 1</div>
              <div className="participant">Participant 2</div>
              <div className="participant">Participant 3</div>
              <div className="participant">Participant 4</div>
              <div className="participant">Participant 5</div>
              <div className="participant">Participant 6</div>
            </div>
          </div>

          <div className="container co-mod">
            <h2 className="links_heading total_participants_past_workshop">
              <span>{past_mod}</span>
              <span className="black">1</span>
            </h2>
            <p className="sub_info">{past_mod_info}</p>
            <div className="participants_list">
              <div className="participant">{past_mod_button}</div>
            </div>
          </div>
        </>
      )}
      <Footer />
    </section>
  );
};

export default PastWorkshopDetails;
