import React, { createContext, useState } from "react";
import defaultValues from "../utils/data/defaultValues.json";

export const AppContext = createContext(null);

export const AppProvider = (props) => {
  //var defaultAppstate=;
  const [appState, setAppState] = useState({
    paxMembersCount: defaultValues.paxMembersCount,
    orderDetailsForm: defaultValues.orderDetailsForm,
    orderDetailsFormtow: defaultValues.orderDetailsFormtow,
    contactDetailsForm: defaultValues.contactDetailsForm,
    authDetails: defaultValues.authDetails,
    bookingList: defaultValues.bookingList,
    liceneceCount: 8,
    facilitatorCount: 8,
    ordercalculation: defaultValues.ordercalculation,
    accountStatus: false,
    clientList: defaultValues.clientList,
    availableCurrencies: ["EUR", "CHF", "GBP"],
    cost: {
      CHF: 130,
      EUR: 125,
      GBP: 110,
    },
    selectedCurrency: "EUR",
    timer: { minutes: 10, seconds: 0 },
    selectedLanguage: "en",
    licenseCalculator: {
      total: 0,
      available: 0,
    },
    loader: false,
  });

  return (
    <AppContext.Provider value={[appState, setAppState]}>
      {props.children}
    </AppContext.Provider>
  );
};
