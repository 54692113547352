import React, { useState, useContext } from "react";
import "./ManageBookings.css";
import {} from "react-bootstrap";
import settings from "../../images/settings.svg";
import { AppContext } from "../../context/app.context";
import QuantityField from "../../components/quantity";
import PickDateTime from "../../components/Dashboard/PickDateTime";
import Modal from "react-modal";
import close from "../../images/CloseIcon.svg";
import { baseApiUrl } from "../../utils/base";
import { getLanguageCode } from "../../utils/common";
import FacilitatedWorkshopRescheduleModal from "./modals/facilitated-workshop-reschedule";

import {
  convertTo12Hour,
  dateLessThanToday,
  convertToDate,
  convertDate,
  convertTime,
  addHours,
  getBookingCount,
  fnPurchasedLic,
  fnTotalLic,
  getLocalStorage,
  setLocalStorage,
  fndisableButtonStyle,
  getTimeSlots,
} from "../../utils/common";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../../context/language.context";

const ManageBooking = (props) => {
  const navigate = useNavigate();
  const apiUrlBooking = baseApiUrl + "client/booking/";
  const [appState, setAppState] = useContext(AppContext);
  const [totalLicHook, setTotalLic] = useState(0);
  const [availableLicHook, setAvailableLic] = useState(0);
  const [modalIsOpendelete, setIsOpendelete] = useState(false);
  const [modalIsOpenWdelete, setIsOpenwdelete] = useState(false);
  const [modalIsOpenNumberUpdated, setIsOpenNumberUpdated] = useState(false);
  const [modalIsOpenPaymentDetails, setIsOpenPaymentDetails] = useState(false);
  const [modalIsOpenUpdatedWorkshop, setIsOpenUpdatedWorkshop] =
    useState(false);
  const [modalIsOpenUnavailable, setIsOpenUnavailable] = useState(false);
  const [bookingListHook, setBookingList] = useState(null);
  const [timeSlots, setTimeSlots] = useState(getTimeSlots());
  const [userData, setUserData] = useState(null);
  const [authDetails, setAuthDetails] = useState(null);
  const [languageCode, setLanguageCode] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [workshopId, setWorkshopId] = useState("");
  const [personInGroupCount, setPersonInGroupCount] = useState(2);
  const [teamName, setTeamName] = useState("");
  const [bookingIdHook, setBookingIdHook] = useState("");
  const [selectedBookingItem, set_selectedBookingItem] = useState(null);
  const [rescheduable, set_rescheduable] = useState(false);

  const [language] = useContext(LanguageContext);
  const sellang = language.selectedLanguage;
  if (sellang === "") {
    sellang = "en";
  }
  const usedLanguage = language.translations[language.selectedLanguage];

  const {
    Dash_Yes,
    Dash_No,
    Dash_MB_H1,
    Dash_MB_1,
    Dash_MB_2,
    Dash_MB_3,
    Dash_MB_3_1,
    Dash_MB_4,
    Dash_MB_5,
    Dash_MB_6,
    Dash_MB_6_1,
    Dash_MB_7,
    Dash_MB_8,
    Dash_MB_9,
    Dash_MB_10,
    Dash_MB_11,
    Dash_MB_12,
    Dash_MB_13,
    Dash_MB_14,
    Dash_MB_15,
    Dash_MB_16,
    Dash_MB_17,
    Dash_MB_18,
    Dash_MB_19,
    Dash_MB_20,
    Dash_MB_21,
    Dash_MB_20_F,
    Dash_MB_21_F,
    Dash_MB_22,
    Dash_MB_23,
    Dash_MB_24,
    Dash_MB_25,
    Dash_MB_26,
  } = usedLanguage.Dashboard;

  React.useEffect(() => {
    //convertDateAndTime('2022-07-29T00:00:00.000Z', '10.30')
    var authDetails = getLocalStorage("authDetailsLocal");

    loadBookingDetails(authDetails);
    var bookings = getLocalStorage("bookingListLocal");
    var userData = getLocalStorage("userDataLocal");
    if (bookings !== null && bookings !== undefined) setBookingList(bookings);
    if (userData !== null && userData !== undefined) setUserData(userData);
    if (authDetails !== null && authDetails !== undefined)
      setAuthDetails(userData);

    var licDetails = getLocalStorage("licLocal");
    setTotalLic(licDetails.total);
    setAvailableLic(licDetails.available);
  }, []);

  const loadBookingDetails = (authDetails) => {
    var getAuthDetails = getLocalStorage("authDetailsLocal");

    if (getAuthDetails === null) getAuthDetails = authDetails;

    fetch(apiUrlBooking + getAuthDetails.userId, {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getAuthDetails.token}`,
      },
    })
      .then((response) => response.json())
      .then(function (response) {
        if (response.code === 200) {
          setAppState({
            ...appState,
            bookingList: response.data.bookingList,
            userData: response.data.userData,
          });
          setLocalStorage(response.data.bookingList, "bookingListLocal");
          setLocalStorage(response.data.userData, "userDataLocal");

          var templicenseCalculator = {
            total: response.data.userData.totalLicenses,
            available: response.data.userData.availableLicenses,
            totalFacilitators: response.data.userData.totalFacilitators,
            availableFacilitators: response.data.userData.availableFacilitators,
          };

          if (templicenseCalculator.availableFacilitators)
            set_rescheduable(true);

          setLocalStorage(templicenseCalculator, "licLocal");
          setAppState({
            ...appState,
            licenseCalculator: templicenseCalculator,
          });
        }
      })
      .catch((err) => {});
  };

  const updateUserData = () => {
    var userDataTemp = getLocalStorage("userDataLocal");
    var licDetails = getLocalStorage("licLocal");
    userDataTemp.totalLicenses = licDetails.total;
    userDataTemp.availableLicenses = licDetails.available;
    localStorage.removeItem("userDataLocal");
    setLocalStorage(userDataTemp, "userDataLocal");
  };
  const storeGroupSize = (bookingId, workshopId, personInGroup) => {
    var getAuthDetails = getLocalStorage("authDetailsLocal");
    const url = baseApiUrl + "client/workshop/group_size";
    var data = {
      customerId: getAuthDetails.userId,
      bookingId: bookingId,
      workshopId: workshopId,
      personInGroup: personInGroup,
    };
    fetch(url, {
      body: JSON.stringify(data),
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getAuthDetails.token}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (promise) {
        return promise.json();
      })
      .then(function (response) {
        if (response.code === 200) {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onPersonInGoupCalculate = (id, bookingId, operator) => {
    var CallApi = false;
    var licDetails = getLocalStorage("licLocal");
    setTotalLic(licDetails.total);
    setAvailableLic(licDetails.available);

    var filtredList = bookingListHook.filter(
      (items) => items._id === id && items.bookingId === bookingId
    );
    var totalLic = licDetails.total; //fnTotalLic(userDataHook);
    var availableLic = licDetails.available; //fnTotalLic(userDataHook) - fnPurchasedLic(bookingListHook);
    var personasInallgroups = fnPurchasedLic(bookingListHook);
    var personinGroup = filtredList[0].personInGroup;
    if (operator === "+") {
      personasInallgroups = personasInallgroups - personinGroup;
      personinGroup = personinGroup + 1;
      personasInallgroups = personasInallgroups + personinGroup;

      if (personinGroup <= 18) {
        if (personasInallgroups <= totalLic && availableLic >= 1) {
          var bookinglistTemp = bookingListHook;
          var updaetdbookinglistTemp = bookinglistTemp.map((item) => {
            var temp = Object.assign({}, item);
            if (temp.bookingId === bookingId && temp._id === id) {
              temp.personInGroup = personinGroup;
            }
            return temp;
          });
          CallApi = true;
          availableLic = availableLic - 1;
          setAvailableLic(availableLic);

          var templicenseCalculator = {
            total: totalLicHook,
            available: availableLic,
            totalFacilitators: licDetails.totalFacilitators,
            availableFacilitators: licDetails.availableFacilitators,
          };
          setLocalStorage(templicenseCalculator, "licLocal");

          setAppState({
            ...appState,
            licenseCalculator: templicenseCalculator,
            bookingList: updaetdbookinglistTemp,
          });
          setBookingList(updaetdbookinglistTemp);
          setLocalStorage(updaetdbookinglistTemp, "bookingListLocal");
          //setAppState({ ...appState, bookingList: updaetdbookinglistTemp });
        }
      }
    }

    if (operator === "-") {
      personasInallgroups = personasInallgroups - personinGroup;
      personinGroup = personinGroup - 1;
      personasInallgroups = personasInallgroups + personinGroup;

      if (personinGroup >= 2) {
        if (personasInallgroups >= 0) {
          var bookinglistTemp = bookingListHook;
          var updaetdbookinglistTemp = bookinglistTemp.map((item) => {
            var temp = Object.assign({}, item);
            if (temp.bookingId === bookingId && temp._id === id) {
              temp.personInGroup = personinGroup;
            }
            return temp;
          });
          CallApi = true;
          availableLic = availableLic + 1;
          setAvailableLic(availableLic);

          var templicenseCalculator = {
            total: totalLicHook,
            available: availableLic,
            totalFacilitators: licDetails.totalFacilitators,
            availableFacilitators: licDetails.availableFacilitators,
          };

          setAppState({
            ...appState,
            licenseCalculator: templicenseCalculator,
            bookingList: updaetdbookinglistTemp,
          });

          setLocalStorage(templicenseCalculator, "licLocal");
          setBookingList(updaetdbookinglistTemp);
          setLocalStorage(updaetdbookinglistTemp, "bookingListLocal");
          //setAppState({ ...appState, bookingList: updaetdbookinglistTemp });
        }
      }
    }
    if (CallApi) {
      updateUserData();
      storeGroupSize(
        filtredList[0].bookingId,
        filtredList[0].workshopId,
        personinGroup
      );
      OpenNumberUpdated();
    }
  };

  function onReschedule(bokingItem) {}

  function openModalUnavailable() {
    setIsOpenUnavailable(true);
    setIsOpenPaymentDetails(false);
  }

  function openModalSuccessfullybooked() {
    setIsOpenUpdatedWorkshop(true);
    setIsOpenPaymentDetails(false);
  }

  const showLoader = () => {
    setAppState({ ...appState, loader: true });
  };

  const hideLoader = () => {
    setAppState({ ...appState, loader: false });
  };

  const OpenPaymentDetails = (bookingItem) => {
    console.log("bookingItem.language", bookingItem.language);
    const languageCode = getLanguageCode(bookingItem.language);
    setLanguageCode(languageCode);
    localStorage.removeItem("workshops");
    setSelectedDate(
      new Date(convertDate(bookingItem.workshopDate, "yyyy-MM-dd"))
    );
    setWorkshopId(bookingItem.workshopId);
    setPersonInGroupCount(bookingItem.personInGroup);
    setTeamName(bookingItem.teamName);
    setBookingIdHook(bookingItem.bookingId);

    setIsOpenPaymentDetails(true);
    //loadBookingDetails();
    //setLocalStorage(bookingItem, "rescheduleBookingLocale");
    //getBookedSlots(bookingItem.workshopDate, bookingItem.workshopId);
  };

  function OpenNumberUpdated() {
    setIsOpenNumberUpdated(true);
  }

  function openModalwdelete() {
    setIsOpenwdelete(true);
    setIsOpendelete(false);
  }

  function openModaldelete() {
    setIsOpendelete(true);
  }

  function afterOpenModal() {}

  function closeModal(e) {
    if (e !== null && e !== undefined && e === "refresh") {
      window.location.href = "/dashboard?lang=" + sellang;
    }
    setIsOpendelete(false);
    setIsOpenwdelete(false);
    setIsOpenNumberUpdated(false);
    setIsOpenPaymentDetails(false);
    setIsOpenUpdatedWorkshop(false);
    setIsOpenUnavailable(false);
  }

  const customStyleslogin = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "90%",
      padding: "0px",
      maxWidth: "901px",
      transform: "translate(-50%, -50%)",
      borderRadius: "36px",
      border: "none",
    },
  };

  const customStylesPayment = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "90%",
      padding: "0px",
      maxWidth: "1124px",
      transform: "translate(-50%, -50%)",
      borderRadius: "50px",
      border: "none",
    },
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "90%",
      padding: "0px",
      maxWidth: "900px",
      transform: "translate(-50%, -50%)",
      borderRadius: "50px",
      border: "none",
    },
  };

  const convertDateAndTime = (dateString, time24hrs) => {
    var dateFormat = convertDate(dateString, "dd/MM/yyyy").split("/");
    var dateFormatConverted =
      dateFormat[1] + "-" + dateFormat[0] + "-" + dateFormat[2].substring(2, 4);
    var dateFormatWithTime = dateFormatConverted + " " + time24hrs + ":00";
    var newDate = new Date(Date.parse(dateFormatWithTime));
    return newDate;
  };

  return (
    <div>
      {/* <button className="" id="test" onClick={getBookingDetails}>Check</button> */}
      <section>
        <div className="container gridcard workshopcard">
          <div className="UpcomingWorkshops">
            <h1 className="upcomingworkshop">
              {getBookingCount(bookingListHook)} {Dash_MB_H1}
            </h1>
            <div className="row">
              <div className="col-lg-12">
                <div className="table-responsive">
                  <table className="table table-borderless">
                    <thead className="thead tableheaders">
                      <tr>
                        <td>{Dash_MB_1}</td>
                        <td>{Dash_MB_2}</td>
                        <td>{Dash_MB_3}</td>
                        <td>{Dash_MB_4}</td>
                        <td>{Dash_MB_3_1}</td>
                        <td>{Dash_MB_5}</td>
                        <td>{Dash_MB_6}</td>
                        <td>{Dash_MB_6_1}</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody className="tablebodycolumn1">
                      {(getLocalStorage("bookingListLocal") || [])
                        .sort(
                          (b, a) =>
                            convertDateAndTime(a.workshopDate, a.workshopTime) -
                            convertDateAndTime(b.workshopDate, b.workshopTime)
                        )
                        .slice(0, 5)
                        .map((bookingItem, index) => {
                          if (bookingItem.bookingId != "") {
                            return (
                              <tr key={index}>
                                <td>
                                  {convertDate(
                                    bookingItem.workshopDate,
                                    "dd/MM/yyyy"
                                  )}
                                </td>
                                <td>
                                  {convertTime(bookingItem.workshopTime) +
                                    "-" +
                                    convertTime(
                                      addHours(bookingItem.workshopTime)
                                    )}
                                </td>
                                <td>
                                  <QuantityField
                                    qty={bookingItem.personInGroup}
                                    onIncrement={() => {
                                      onPersonInGoupCalculate(
                                        bookingItem._id,
                                        bookingItem.bookingId,
                                        "+"
                                      );
                                    }}
                                    onDecrement={() => {
                                      onPersonInGoupCalculate(
                                        bookingItem._id,
                                        bookingItem.bookingId,
                                        "-"
                                      );
                                    }}
                                    //OpenNumberUpdated={OpenNumberUpdated}
                                    disabled={dateLessThanToday(
                                      bookingItem.workshopDate
                                    )}
                                  />
                                </td>
                                <td>{bookingItem.teamName}</td>
                                <td>
                                  {bookingItem.facilitated ? Dash_Yes : Dash_No}
                                </td>
                                <td>{bookingItem.language.toUpperCase()}</td>
                                <td>
                                  {(() => {
                                    if (
                                      !dateLessThanToday(
                                        bookingItem.workshopDate
                                      )
                                    ) {
                                      return (
                                        <button
                                          className="btnres"
                                          onClick={() => {
                                            set_selectedBookingItem(
                                              bookingItem
                                            );

                                            if (bookingItem.facilitated) {
                                              setAppState({
                                                ...appState,
                                                facilitatedWorkshopRescheduleModalOpen: true,
                                              });

                                              return;
                                            }

                                            OpenPaymentDetails(bookingItem);
                                          }}
                                        >
                                          {Dash_MB_26}
                                        </button>
                                      );
                                    } else {
                                      return (
                                        <button
                                          className="btnres"
                                          style={fndisableButtonStyle()}
                                        >
                                          {Dash_MB_26}
                                        </button>
                                      );
                                    }
                                  })()}
                                </td>
                                <td>
                                  <div className="copy-action">
                                    <a
                                      href={`/upcomingWorkshopDetails/${bookingItem.bookingId}/${bookingItem.workshopId}/dashboard?lang=${language.selectedLanguage}`}
                                    >
                                      <img src={settings} />
                                    </a>
                                    {/* {(() => {
                                      if (
                                        !dateLessThanToday(
                                          bookingItem.workshopDate
                                        )
                                      ) {
                                        return (
                                          <img
                                            src={copy}
                                            alt=""
                                            onClick={() => {
                                              CopytoClipboard(
                                                cdata,
                                                bookingItem.workshopId
                                              );
                                            }}
                                          />
                                        );
                                      } else {
                                        return (
                                          <img
                                            src={copy}
                                            alt=""
                                            style={fndisableButtonStyle()}
                                          />
                                        );
                                      }
                                    })()} */}
                                  </div>
                                </td>
                              </tr>
                            );
                          } else {
                            return (
                              <tr key={index}>
                                <td colSpan={9}>No Data Found</td>
                              </tr>
                            );
                          }
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container" style={{ marginTop: "12px" }}>
          <div className="row">
            <div className="col-lg-12">
              <span className="subh5txt1">{Dash_MB_7}</span>
            </div>
          </div>
        </div>
      </section>

      <Modal
        isOpen={modalIsOpenNumberUpdated}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyleslogin}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div className="modal-number">
          <div className="close-modal" style={{ textAlign: "right" }}>
            <img src={close} alt="" onClick={closeModal} />
          </div>
          <h1 className="noofparttextmodel">{Dash_MB_8}</h1>
          <p className="popuptext">{Dash_MB_9}</p>
          <p className="popuptext">{Dash_MB_10}</p>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpenWdelete}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyleslogin}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div className="modal-workshop-deleted">
          <h3 className="workshpdel">{Dash_MB_11}</h3>
          <p className="wrkshpdelsub">{Dash_MB_12}</p>
          <p className="wrkshpdelsub text-center">{Dash_MB_13}</p>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpendelete}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyleslogin}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div className="modal-delete">
          <h2 className="delbooking">{Dash_MB_14}</h2>
          <p className="delsure">{Dash_MB_15}</p>
          <div className="button-group modal-action">
            <button onClick={closeModal} className="keepbtn">
              {Dash_MB_16}
            </button>
            <button className="yesdel" onClick={openModalwdelete}>
              {Dash_MB_17}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpenPaymentDetails}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStylesPayment}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div className="">
          <PickDateTime
            openModalLanguage={false}
            openModalSuccessfullybooked={openModalSuccessfullybooked}
            changelanguage={false}
            heading={Dash_MB_18}
            dateUnavailable={true}
            openModalUnavailable={openModalUnavailable}
            languageCode={languageCode}
            IsRechedule={true}
            WorkshopId={workshopId}
            SelectedDate={selectedDate}
            PersonInGroupCount={personInGroupCount}
            TeamName={teamName}
            BookingId={bookingIdHook}
            //onChange={(date) => onDateChange(date)}
          ></PickDateTime>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpenUpdatedWorkshop}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div className="UpdatedWorkshop">
          <div className="close-modal" style={{ textAlign: "right" }}>
            <img
              src={close}
              alt=""
              onClick={() => {
                closeModal("refresh");
              }}
            />
          </div>
          <h1>{Dash_MB_19}</h1>
          <p className="first-info">
            {selectedBookingItem?.facilitated ? Dash_MB_20_F : Dash_MB_20}
          </p>
          <p className="second-info">
            {selectedBookingItem?.facilitated ? Dash_MB_21_F : Dash_MB_21}
          </p>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpenUnavailable}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div className="modalUnavailable">
          <div className="close-modal" style={{ textAlign: "right" }}>
            <img src={close} alt="" onClick={closeModal} />
          </div>
          <h1>{Dash_MB_22}</h1>
          <p className="second-info">{Dash_MB_23} </p>
          <p className="second-info">
            {Dash_MB_24}{" "}
            <a href="mailto:booking@mission-team.com">
              booking@mission-team.com
            </a>{" "}
            {Dash_MB_25} <a href="#">+41 41 500 04 77</a>.
          </p>
        </div>
      </Modal>
      <FacilitatedWorkshopRescheduleModal
        bookingItem={selectedBookingItem}
        rescheduleCallback={OpenPaymentDetails}
        availableFacilitators={rescheduable}
      />
    </div>
  );
};

export default ManageBooking;
