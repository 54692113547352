import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import "./ManageOrder.css";
import { AppContext } from "../../context/app.context";
import { baseApiUrl } from "../../utils/base";
import { getLocalStorage, setLocalStorage } from "../../utils/common";
import { LanguageContext } from "../../context/language.context";

const ManageOrder = () => {
  const navigate = useNavigate();
  const apiUrlLogin = baseApiUrl + "login";
  const apiUrlBooking = baseApiUrl + "client/booking/";
  const [appState, setAppState] = useContext(AppContext);
  const [code, setCode] = useState("");
  const [modalIsOpenrequest, setIsOpenrequest] = useState(false);
  const [modalIsOpencodesent, setIsOpencodesent] = useState(false);
  const [output, setOutput] = useState({
    status: false,
    apiData: null,
    showmodel: false,
  });
  const [email, setEmail] = useState("");

  const [language, setLangauge] = useContext(LanguageContext);
  const sellang = language.selectedLanguage;
  if (sellang === "") {
    sellang = "en";
  }
  const usedLanguage = language.translations[language.selectedLanguage];

  const {
    Login_h1_1,
    Login_h1_2,
    Login_SubTxt,
    Login_Code_WtMrk,
    Login_Code_BTntxt,
    Login_forgtlnk_1,
    Login_forgtlnk_2,
    Login_Frgtbx,
    Login_Frgtbx_subtxt1,
    Login_Frgtbx_subtxt2,
    Login_Frgtbx_subtxt3,
    Login_Frgtbx_subtxt4,
    Login_Frgtbx_subtxt5,
    Login_Frgtbx_subtxt6,
    Login_Frgtbx_subtxt7,
  } = usedLanguage.LoginPage;

  const clearValues = () => {
    setOutput({
      status: true,
      apiData: "",
      showmodel: false,
    });
  };
  function openModalcodesent() {
    setIsOpencodesent(true);
    setIsOpenrequest(false);
  }

  const sentCodeApi = () => {
    clearValues();
    let validationmsg = "Please enter your registered email id";
    if (sellang === "de") {
      validationmsg = "Bitte geben Sie Ihre registrierte E-Mail-Adresse ein";
    }
    if (sellang === "it") {
      validationmsg = "Inserisci il tuo ID e-mail registrato";
    }
    if (sellang === "fr") {
      validationmsg = "Veuillez saisir votre adresse e-mail enregistrée";
    }

    var apiurl = baseApiUrl + "client/new_code/";
    var checkCode = email === "";
    if (checkCode) {
      setOutput({
        status: false,
        apiData: validationmsg,
        showmodel: true,
      });
      return;
    }
    const apiData = { email: email, user_type: "customer" };
    fetch(apiurl, {
      body: JSON.stringify(apiData),
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(function (response) {
        console.log(response.code);
        if (response.code === 200) {
          openModalcodesent();
        } else {
          setOutput({
            status: false,
            apiData: response.message,
            showmodel: true,
          });
        }
      })
      .catch((err) => {
        setOutput({
          status: false,
          apiData: err,
          showmodel: true,
        });
      });
  };

  function openModalrequest() {
    clearValues();
    setIsOpenrequest(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpenrequest(false);
    setIsOpencodesent(false);
    setOutput({ status: false, apiData: null, showmodel: false });
  }
  const fnLogin = () => {
    clearValues();
    let validationmsg = "Please enter your login code";
    if (sellang === "de") {
      validationmsg = "Bitte geben Sie Ihren Login-Code ein";
    }
    if (sellang === "it") {
      validationmsg = "Inserisci il tuo codice di accesso";
    }
    if (sellang === "fr") {
      validationmsg = "Veuillez entrer votre code de connexion";
    }
    var checkCode = code === "";
    if (checkCode) {
      setOutput({
        status: false,
        apiData: validationmsg,
        showmodel: true,
      });
      return;
    }
    const apiLoginData = { login_code: code, user_type: "customer" };
    fetch(apiUrlLogin, {
      body: JSON.stringify(apiLoginData),
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(function (response) {
        console.log(response);
        if (response.code === 200) {
          setAppState({
            ...appState,
            authDetails: response.data,
          });
          setLocalStorage(response.data, "authDetailsLocal");
          loadBookingDetails(response.data);
        } else {
          setOutput({
            status: false,
            apiData: response.message,
            showmodel: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        // setOutput({
        //     status:false,
        //     apiData:err,
        //     showmodel:true
        // })
      });
  };

  const onProceed = () => {
    fnLogin();
  };

  const loadBookingDetails = (authDetails) => {
    var getAuthDetails = getLocalStorage("authDetailsLocal");
    if (getAuthDetails === null) getAuthDetails = authDetails;

    fetch(apiUrlBooking + getAuthDetails.userId, {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getAuthDetails.token}`,
      },
    })
      .then((response) => response.json())
      .then(function (response) {
        console.log(response);
        if (response.code === 200) {
          setAppState({
            ...appState,
            bookingList: response.data.bookingList,
            userData: response.data.userData,
          });
          setLocalStorage(response.data.bookingList, "bookingListLocal");
          setLocalStorage(response.data.userData, "userDataLocal");
          setLocalStorage(response.data.userData._id, "customerid");
          var templicenseCalculator = {
            total: response.data.userData.totalLicenses,
            available: response.data.userData.availableLicenses,
          };
          setLocalStorage(templicenseCalculator, "licLocal");
          setAppState({
            ...appState,
            licenseCalculator: templicenseCalculator,
          });
          var userlang = response.data.userData.language;
          var usrlang = "en";
          if (userlang === "german") {
            usrlang = "de";
          } else if (userlang === "french") {
            usrlang = "fr";
          } else if (userlang === "italian") {
            usrlang = "it";
          }
          setLangauge({ ...language, selectedLanguage: usrlang });
          navigate("/dashboard?lang=" + usrlang);
        } else {
          setOutput({
            status: false,
            apiData: response.message,
            showmodel: false,
          });
        }
      })
      .catch((err) => {
        setOutput({
          status: false,
          apiData: err,
          showmodel: true,
        });
      });
  };

  const customStyleslogin = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "90%",
      padding: "0px",
      maxWidth: "901px",
      transform: "translate(-50%, -50%)",
      borderRadius: "36px",
      border: "none",
    },
  };

  const errorCss = {
    color: "red",
    // marginLeft: '0px'
  };

  return (
    <div>
      <section className="staf-manage-order">
        <div className="container staf-manage-container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="headertext">
                {" "}
                {Login_h1_1} <br /> {Login_h1_2}
              </h1>
              <p className="subtext">{Login_SubTxt} </p>
            </div>
            <div className="col-md-12">
              <div className="backeffect">
                <div className="input-group">
                  <input
                    type="text"
                    id="txtcode"
                    placeholder={Login_Code_WtMrk}
                    className="form-control textboxpcode"
                    onChange={(e) => setCode(e.target.value)}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        onProceed();
                      }
                    }}
                  ></input>
                  <div className="input-group-append">
                    <button
                      className="proceedlink"
                      onClick={() => {
                        onProceed();
                      }}
                    >
                      {Login_Code_BTntxt}{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12" style={{ textAlign: "center" }}>
              <span className="forgotcust" style={errorCss}>
                {!output.status ? output.apiData : ""}
              </span>
            </div>
            <div className="col-lg-12">
              <p className="forgotcust">
                {Login_forgtlnk_1}{" "}
                <a href="#" className="clickhere" onClick={openModalrequest}>
                  {Login_forgtlnk_2}
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <Modal
        isOpen={modalIsOpenrequest}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyleslogin}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div className="container requestModal">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="pophdtext">{Login_Frgtbx} </h1>
              <p className="poheadsubtxt">{Login_Frgtbx_subtxt1}.</p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <div className="backeffectpopup">
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="E-mail"
                    className="form-control textboxpcode"
                    onChange={(e) => setEmail(e.target.value)}
                  ></input>
                  <div className="input-group-append">
                    <button
                      className="proceedlink"
                      onClick={() => {
                        sentCodeApi();
                      }}
                    >
                      {Login_Frgtbx_subtxt2}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <span className="forgotcust" style={errorCss}>
                {!output.status ? output.apiData : ""}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <span className="forgotcust">
                {Login_Frgtbx_subtxt3}{" "}
                <a href="mailto:booking@mission-team.com" className="clickhere">
                  booking@mission-team.com
                </a>
              </span>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpencodesent}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyleslogin}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div className="container modalcodesent">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="pophdtext">{Login_Frgtbx_subtxt4}</h1>
              <p className="poheadsubtxt">{Login_Frgtbx_subtxt5}</p>
              <p className="poheadsubtxt">
                {Login_Frgtbx_subtxt6} <br></br>{" "}
                <a href="mailto:booking@mission-team.com" className="clickhere">
                  booking@mission-team.com
                </a>{" "}
                {Login_Frgtbx_subtxt7}{" "}
                <span className="clickhere">+41 41 500 04 77.</span>
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ManageOrder;
