import React from 'react'
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import PurchaseHist from '../../components/Purchase/PurchaseHist';
const PurchaseHistory = () => {
  return (
    <div className='purchase-page home-page'>
      <Navbar />
      <PurchaseHist />
      <Footer />
    </div>
  )
}

export default PurchaseHistory