import React, { useState } from 'react'
import logo from "../../images/logo.png";
import logout from "../../images/LogOutIcon.svg";
import "./DashboardNav.css";
import { } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { clearSession } from '../../utils/common';


const StaffNav = () => {
    const navigate = useNavigate();
    const onLogout = () => {
        clearSession();
        navigate("/");
    }
    return (
        <div>
            <header className="d-flex flex-wrap justify-content-center">
                <div className="container" style={{ borderBottom: "1px solid #E9E9E9" }}>
                    <div className="row align-items-center">
                        <div className="col-lg-3">
                            <div className='logo-page'>
                                <img src={logo} className="logo" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-7" >
                            <div className="input-box">
                                {/* <i className="searchicon" >
                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.6">
                                            <path d="M18.3333 18.8333L16.25 16.75" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
                                            <circle cx="8.75065" cy="9.25004" r="7.16667" stroke="black" strokeWidth="1.5" />
                                        </g>
                                    </svg> </i>
                                <input type="text" className='Searchbox' placeholder="Search Prospect "></input> */}
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className='link-logout'>
                                <button name="btnLougout" className="ms-auto link-dark logout-button" onClick={onLogout}>
                                    <img src={logout} alt="" />
                                    Logout
                                </button>
                                {/* <Link to="/" className="ms-auto link-dark logout-button"><img src={logout} alt="" /> Logout</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
};

export default StaffNav;