import React from 'react'
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import NewAccount from '../../components/NewAccount/NewAccount';
const CreateAccount = () => {
  return (
    <div className='home-page new-account'>
      <Navbar showPortalLink={false} />
      <NewAccount />
      <Footer />
    </div>
  )
}

export default CreateAccount