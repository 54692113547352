import React, { useContext, useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import LeftArrow from "../../images/leftarrow.svg";
import {} from "react-bootstrap";
// import copy from "../../images/copy.svg";
import settings from "../../images/settings.svg";
import deleteicon from "../../images/delete.svg";
import close from "../../images/CloseIcon.svg";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import PickDateTime from "../../components/Dashboard/PickDateTime";
import QuantityField from "../../components/quantity";
import { AppContext } from "../../context/app.context";
import "./View.css";
import copytoclipboard from "copy-to-clipboard";
import { LanguageContext } from "../../context/language.context";
import FacilitatedWorkshopRescheduleModal from "../../components/Dashboard/modals/facilitated-workshop-reschedule";

import {
  dateLessThanToday,
  convertDate,
  convertTime,
  addHours,
  getBookingCount,
  getLocalStorage,
  setLocalStorage,
  fnPurchasedLic,
  fnTotalLic,
  convertToDate,
  fndisableButtonStyle,
  getLanguageCode,
} from "../../utils/common";
import { baseApiUrl } from "../../utils/base";

const customStyleslogin = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "90%",
    padding: "0px",
    maxWidth: "901px",
    transform: "translate(-50%, -50%)",
    borderRadius: "36px",
    border: "none",
  },
};

const View = () => {
  const apiUrl = baseApiUrl + "client/workshop/group_size";
  const [appState, setAppState] = useContext(AppContext);
  const paxMembersCount = appState.paxMembersCount;
  const orderDetailsForm = appState.orderDetailsForm;
  const [modalIsOpenPaymentDetails, setIsOpenPaymentDetails] =
    React.useState(false);
  const [modalIsOpenUnavailable, setIsOpenUnavailable] = React.useState(false);
  const [modalIsOpenUpdatedWorkshop, setIsOpenUpdatedWorkshop] =
    React.useState(false);
  const [modalIsOpenDelte, setIsOpenDelte] = React.useState(false);
  const [modalIsOpenNumberUpdated, setIsOpenNumberUpdated] =
    React.useState(false);
  const [modalIsOpenWorkshopDeleted, setIsOpenWorkshopDeleted] =
    React.useState(false);
  const [bookingListHook, setBookingList] = React.useState(null);
  const [userDataHook, setUserData] = React.useState(null);
  const [authDetailsHook, setAuthDetails] = React.useState(null);
  const [totalLicHook, setTotalLic] = React.useState(0);
  const [availableLicHook, setAvailableLic] = React.useState(0);

  const [languageCode, setLanguageCode] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [workshopId, setWorkshopId] = React.useState("");
  const [personInGroupCount, setPersonInGroupCount] = React.useState(2);
  const [teamName, setTeamName] = React.useState("");
  const [bookingIdHook, setBookingIdHook] = React.useState("");
  const [copyText, setCopyText] = useState("");
  const [show_upcomingWorkshops, set_show_UpcomingWorkshops] = useState(true);
  const [show_pastWorkshops, set_show_PastWorkshops] = useState(false);
  const [upcomingWorkshops, setUpcomingWorkshops] = useState([]);
  const [pastWorkshops, setPastWorkshops] = useState([]);
  const [rescheduable, set_rescheduable] = useState(false);
  const [openPurchaseLicensesModal, set_openPurchaseLicensesModal] =
    useState(false);
  const [selectedBookingItem, set_selectedBookingItem] = useState(null);

  const apiUrlBooking = baseApiUrl + "client/booking/";

  const [language] = useContext(LanguageContext);
  const usedLanguage = language.translations[language.selectedLanguage];

  const sellang = language.selectedLanguage;
  if (sellang === "") {
    sellang = "en";
  }
  const {
    VH_goBack,
    VH_upcoming,
    VH_past,
    VH_Ttl,
    VH_SubTtl,
    VH_Wksbkd,
    VH_LSt_H1,
    VH_LSt_H2,
    VH_LSt_H3,
    VH_LSt_H4,
    VH_LSt_H5,
    VH_LSt_H6,
    VH_noData,
    VH_Res,
  } = usedLanguage.ViewAll;

  const {
    Dash_Yes,
    Dash_No,
    Dash_MB_3_1,
    Dash_MB_6_1,
    Dash_MB_19,
    Dash_MB_20,
    Dash_MB_21,
    Dash_MB_20_F,
    Dash_MB_21_F,
    Dash_MB_22,
    Dash_MB_23,
    Dash_MB_24,
    Dash_MB_25,
    Dash_MB_32,
    Dash_MB_33,
    Dash_MB_34,
    Dash_MB_35,
  } = usedLanguage.Dashboard;

  const gobacklink = "/dashboard?lang=" + sellang;
  React.useEffect(() => {
    var bookings = getLocalStorage("bookingListLocal");
    var userData = getLocalStorage("userDataLocal");
    var authDetails = getLocalStorage("authDetailsLocal");
    if (bookings !== null) setBookingList(bookings);
    if (userData !== null && userData !== undefined) setUserData(userData);
    if (authDetails !== null && authDetails !== undefined)
      setAuthDetails(userData);
    var licDetails = getLocalStorage("licLocal");
    setTotalLic(licDetails.total);
    setAvailableLic(licDetails.available);

    // Fetch workshops
    var getAuthDetails = getLocalStorage("authDetailsLocal");
    const customerId = getAuthDetails.userId;
    fetch(`${baseApiUrl}client/booking/${customerId}?date=past`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getAuthDetails.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPastWorkshops(data.data.bookingList);
      });

    fetch(`${baseApiUrl}client/booking/${customerId}?date=null`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getAuthDetails.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUpcomingWorkshops(data.data.bookingList);
      });
  }, []);

  setTimeout(() => {
    setBookingList((old) => old);
  }, 700);

  useEffect(() => {
    var getAuthDetails = getLocalStorage("authDetailsLocal");

    fetch(apiUrlBooking + getAuthDetails.userId, {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getAuthDetails.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.data.userData?.availableFacilitators) set_rescheduable(false);
        else set_rescheduable(true);
      });
  }, []);

  const updateUserData = () => {
    var userDataTemp = getLocalStorage("userDataLocal");
    var licDetails = getLocalStorage("licLocal");
    userDataTemp.totalLicenses = licDetails.total;
    userDataTemp.availableLicenses = licDetails.available;
    localStorage.removeItem("userDataLocal");
    setLocalStorage(userDataTemp, "userDataLocal");
  };

  const storeGroupSize = (bookingId, workshopId, personInGroup) => {
    var getAuthDetails = getLocalStorage("authDetailsLocal");
    const url = baseApiUrl + "client/workshop/group_size";
    var data = {
      customerId: getAuthDetails.userId,
      bookingId: bookingId,
      workshopId: workshopId,
      personInGroup: personInGroup,
    };

    fetch(url, {
      body: JSON.stringify(data),
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getAuthDetails.token}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (promise) {
        return promise.json();
      })
      .then(function (response) {
        console.log(response);
        if (response.code === 200) {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onPersonInGoupCalculate = (id, bookingId, operator) => {
    var CallApi = false;
    var licDetails = getLocalStorage("licLocal");
    setTotalLic(licDetails.total);
    setAvailableLic(licDetails.available);

    var filtredList = bookingListHook.filter(
      (items) => items._id === id && items.bookingId === bookingId
    );
    var totalLic = licDetails.total; //fnTotalLic(userDataHook);
    var availableLic = licDetails.available; //fnTotalLic(userDataHook) - fnPurchasedLic(bookingListHook);
    var personasInallgroups = fnPurchasedLic(bookingListHook);
    var personinGroup = filtredList[0].personInGroup;
    if (operator === "+") {
      personasInallgroups = personasInallgroups - personinGroup;
      personinGroup = personinGroup + 1;
      personasInallgroups = personasInallgroups + personinGroup;
      console.log(personasInallgroups);
      console.log(availableLic);
      if (personinGroup <= 18) {
        if (personasInallgroups <= totalLic && availableLic >= 1) {
          var bookinglistTemp = bookingListHook;
          var updaetdbookinglistTemp = bookinglistTemp.map((item) => {
            var temp = Object.assign({}, item);
            if (temp.bookingId === bookingId && temp._id === id) {
              temp.personInGroup = personinGroup;
            }
            return temp;
          });
          CallApi = true;
          availableLic = availableLic - 1;
          setAvailableLic(availableLic);

          var templicenseCalculator = {
            total: totalLicHook,
            available: availableLic,
          };
          setLocalStorage(templicenseCalculator, "licLocal");
          setAppState({
            ...appState,
            licenseCalculator: templicenseCalculator,
          });
          setBookingList(updaetdbookinglistTemp);
          setLocalStorage(updaetdbookinglistTemp, "bookingListLocal");
          setAppState({ ...appState, bookingList: updaetdbookinglistTemp });
        }
      }
    }

    if (operator === "-") {
      personasInallgroups = personasInallgroups - personinGroup;
      personinGroup = personinGroup - 1;
      personasInallgroups = personasInallgroups + personinGroup;

      if (personinGroup >= 2) {
        if (personasInallgroups >= 0) {
          var bookinglistTemp = bookingListHook;
          var updaetdbookinglistTemp = bookinglistTemp.map((item) => {
            var temp = Object.assign({}, item);
            if (temp.bookingId === bookingId && temp._id === id) {
              temp.personInGroup = personinGroup;
            }
            return temp;
          });
          CallApi = true;
          availableLic = availableLic + 1;
          setAvailableLic(availableLic);

          var templicenseCalculator = {
            total: totalLicHook,
            available: availableLic,
          };

          setAppState({
            ...appState,
            licenseCalculator: templicenseCalculator,
          });
          setLocalStorage(templicenseCalculator, "licLocal");
          setBookingList(updaetdbookinglistTemp);
          setLocalStorage(updaetdbookinglistTemp, "bookingListLocal");
          setAppState({ ...appState, bookingList: updaetdbookinglistTemp });
        }
      }
    }
    if (CallApi) {
      updateUserData();
      storeGroupSize(
        filtredList[0].bookingId,
        filtredList[0].workshopId,
        personinGroup
      );
      OpenNumberUpdated();
    }
  };

  function OpenWorkshopDeleted() {
    setIsOpenWorkshopDeleted(true);
    setIsOpenDelte(false);
  }

  function OpenNumberUpdated() {
    setIsOpenNumberUpdated(true);
  }

  function modaOpenDelte() {
    setIsOpenDelte(true);
  }

  function OpenPaymentDetails(bookingItem) {
    console.log(bookingItem);
    const languageCode = getLanguageCode(bookingItem.language);
    setLanguageCode(languageCode);
    localStorage.removeItem("workshops");
    setSelectedDate(
      new Date(convertDate(bookingItem.workshopDate, "yyyy-MM-dd"))
    );
    setWorkshopId(bookingItem.workshopId);
    setPersonInGroupCount(bookingItem.personInGroup);
    setTeamName(bookingItem.teamName);
    setBookingIdHook(bookingItem.bookingId);
    console.log(selectedDate);
    console.log(workshopId);
    console.log(personInGroupCount);
    console.log(teamName);
    setIsOpenPaymentDetails(true);
  }

  function afterOpenModal() {}

  function closeModal(e) {
    if (e !== null && e !== undefined && e === "refresh") {
      //window.location.reload();
    }
    setIsOpenPaymentDetails(false);
    setIsOpenUnavailable(false);
    setIsOpenUpdatedWorkshop(false);
    setIsOpenDelte(false);
    setIsOpenWorkshopDeleted(false);
    setIsOpenNumberUpdated(false);
  }

  function openModalUnavailable() {
    setIsOpenUnavailable(true);
    setIsOpenPaymentDetails(false);
  }

  function openModalSuccessfullybooked() {
    setIsOpenUpdatedWorkshop(true);
    setIsOpenPaymentDetails(false);
  }

  function onReschedule(bookingItem) {
    OpenPaymentDetails();
  }
  const customStylesPayment = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "90%",
      padding: "0px",
      maxWidth: "1124px",
      transform: "translate(-50%, -50%)",
      borderRadius: "50px",
      border: "none",
    },
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "90%",
      padding: "0px",
      maxWidth: "900px",
      transform: "translate(-50%, -50%)",
      borderRadius: "50px",
      border: "none",
    },
  };

  const customStylesDelte = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "90%",
      padding: "0px",
      maxWidth: "800px",
      transform: "translate(-50%, -50%)",
      borderRadius: "50px",
      border: "none",
    },
  };

  const customStyleslogin = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "90%",
      padding: "0px",
      maxWidth: "901px",
      transform: "translate(-50%, -50%)",
      borderRadius: "36px",
      border: "none",
    },
  };

  return (
    <div>
      <Navbar showPortalLink={false} />
      <section>
        <div className="container">
          <div className="row">
            <div
              className="col-12"
              style={{ textAlign: "left", marginTop: "30px" }}
            >
              <img src={LeftArrow} className="leftarrow" alt="" />
              <Link to={gobacklink} id="goback" className="gobacktext">
                {VH_goBack}
              </Link>
            </div>
          </div>
          <div className="row" style={{ marginTop: "40px" }}>
            <div className="col-lg-8">
              <span className="orange-text">{VH_Ttl}</span>
            </div>

            <div className="col-lg-4">
              <div className="workshop-title">
                <span className="twotxt">
                  {getBookingCount(bookingListHook)}
                </span>
                <span className="wrkshptxt">{VH_Wksbkd}</span>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col-lg-12">
              <p className="viewsubtxt">{VH_SubTtl}.</p>
            </div>
          </div>
          <div className="workshopPastUpcoming">
            <button
              className={`period_btn ${
                show_upcomingWorkshops ? "selected" : ""
              }`}
              onClick={() => {
                set_show_UpcomingWorkshops(true);
                set_show_PastWorkshops(false);
              }}
            >
              {VH_upcoming}
            </button>
            <button
              className={`period_btn ${show_pastWorkshops ? "selected" : ""}`}
              onClick={() => {
                set_show_UpcomingWorkshops(false);
                set_show_PastWorkshops(true);
              }}
            >
              {VH_past}
            </button>
          </div>
          <div className="row">
            <div className="col-lg-12" style={{ marginTop: "24px" }}>
              <div className="table-responsive">
                <table className="table table-borderless">
                  <thead className="thead tableheaders">
                    <tr>
                      <td>{VH_LSt_H1}</td>
                      <td>{VH_LSt_H2}</td>
                      <td>{VH_LSt_H3}</td>
                      <td>{VH_LSt_H4}</td>
                      <td>{Dash_MB_3_1}</td>
                      <td>{VH_LSt_H5}</td>
                      <td>{VH_LSt_H6}</td>
                      <td>{show_upcomingWorkshops && Dash_MB_6_1}</td>
                      <td></td>
                    </tr>
                  </thead>
                  {/* deploy */}
                  {show_upcomingWorkshops && (
                    <tbody className="tablebodycolumn1">
                      {(upcomingWorkshops || [])
                        .sort(
                          (b, a) =>
                            new Date(convertToDate(a.workshopDate)) -
                            new Date(convertToDate(b.workshopDate))
                        )
                        .map((bookingItem, index) => {
                          if (bookingItem.bookingId != "") {
                            var cdata =
                              convertDate(
                                bookingItem.workshopDate,
                                "dd/MM/yyyy"
                              ) +
                              " || " +
                              bookingItem.teamName +
                              " || " +
                              bookingItem.language +
                              " || " +
                              bookingItem.workshopLink[0].participantLink;
                            return (
                              <tr key={index}>
                                <td>
                                  {convertDate(
                                    bookingItem.workshopDate,
                                    "dd/MM/yyyy"
                                  )}
                                </td>
                                <td>
                                  {convertTime(bookingItem.workshopTime) +
                                    "-" +
                                    convertTime(
                                      addHours(bookingItem.workshopTime)
                                    )}
                                </td>

                                <td>
                                  <QuantityField
                                    qty={bookingItem.personInGroup}
                                    onIncrement={() => {
                                      onPersonInGoupCalculate(
                                        bookingItem._id,
                                        bookingItem.bookingId,
                                        "+"
                                      );
                                    }}
                                    onDecrement={() => {
                                      onPersonInGoupCalculate(
                                        bookingItem._id,
                                        bookingItem.bookingId,
                                        "-"
                                      );
                                    }}
                                    disabled={dateLessThanToday(
                                      bookingItem.workshopDate
                                    )}
                                  />
                                </td>
                                <td>{bookingItem.teamName}</td>
                                <td>
                                  {bookingItem.facilitated ? Dash_Yes : Dash_No}
                                </td>
                                <td>{bookingItem.language}</td>
                                <td>
                                  {(() => {
                                    if (
                                      !dateLessThanToday(
                                        bookingItem.workshopDate
                                      )
                                    ) {
                                      return (
                                        <button
                                          className="btnres"
                                          onClick={() => {
                                            set_selectedBookingItem(
                                              bookingItem
                                            );

                                            if (bookingItem.facilitated) {
                                              setAppState({
                                                ...appState,
                                                facilitatedWorkshopRescheduleModalOpen: true,
                                              });

                                              return;
                                            }

                                            OpenPaymentDetails(bookingItem);
                                          }}
                                        >
                                          {VH_Res}
                                        </button>
                                      );
                                    } else {
                                      return (
                                        <button
                                          className="btnres"
                                          style={fndisableButtonStyle()}
                                        >
                                          {VH_Res}
                                        </button>
                                      );
                                    }
                                  })()}
                                </td>
                                <td>
                                  <div className="copy-action">
                                    <a
                                      href={`/upcomingWorkshopDetails/${bookingItem.bookingId}/${bookingItem.workshopId}/view?lang=${language.selectedLanguage}`}
                                    >
                                      <img src={settings} />
                                    </a>
                                    {/* <img
                                    src={copy}
                                    alt=""
                                    onClick={() => {
                                      CopytoClipboard(
                                        cdata,
                                        bookingItem.workshopId
                                      );
                                    }}
                                  /> */}
                                  </div>
                                </td>
                              </tr>
                            );
                          } else {
                            return (
                              <tr key={index}>
                                <td colSpan={9}>{VH_noData}</td>
                              </tr>
                            );
                          }
                        })}
                    </tbody>
                  )}
                  {show_pastWorkshops && (
                    <tbody className="tablebodycolumn1">
                      {(pastWorkshops || [])
                        .sort(
                          (b, a) =>
                            new Date(convertToDate(a.workshopDate)) -
                            new Date(convertToDate(b.workshopDate))
                        )
                        .map((bookingItem, index) => {
                          if (bookingItem.bookingId != "") {
                            return (
                              <tr key={index}>
                                <td>
                                  {convertDate(
                                    bookingItem.workshopDate,
                                    "dd/MM/yyyy"
                                  )}
                                </td>
                                <td>
                                  {convertTime(bookingItem.workshopTime) +
                                    "-" +
                                    convertTime(
                                      addHours(bookingItem.workshopTime)
                                    )}
                                </td>

                                <td>
                                  <QuantityField
                                    qty={bookingItem.personInGroup}
                                    onIncrement={() => {
                                      onPersonInGoupCalculate(
                                        bookingItem._id,
                                        bookingItem.bookingId,
                                        "+"
                                      );
                                    }}
                                    onDecrement={() => {
                                      onPersonInGoupCalculate(
                                        bookingItem._id,
                                        bookingItem.bookingId,
                                        "-"
                                      );
                                    }}
                                    disabled={dateLessThanToday(
                                      bookingItem.workshopDate
                                    )}
                                  />
                                </td>
                                <td>{bookingItem.teamName}</td>
                                <td>
                                  {bookingItem.facilitated ? Dash_Yes : Dash_No}
                                </td>
                                <td>{bookingItem.language}</td>
                                <td>
                                  {(() => {
                                    if (
                                      !dateLessThanToday(
                                        bookingItem.workshopDate
                                      )
                                    ) {
                                      return (
                                        <button
                                          className="btnres"
                                          onClick={() => {
                                            OpenPaymentDetails(bookingItem);
                                          }}
                                        >
                                          {VH_Res}
                                        </button>
                                      );
                                    } else {
                                      return (
                                        <button
                                          className="btnres"
                                          style={fndisableButtonStyle()}
                                        >
                                          {VH_Res}
                                        </button>
                                      );
                                    }
                                  })()}
                                </td>
                                <td>
                                  {/* <div className="copy-action">
                                    <a
                                      href={`/upcomingWorkshopDetails/${bookingItem.bookingId}/${bookingItem.workshopId}/view?lang=${language.selectedLanguage}`}
                                    >
                                      <img src={settings} />
                                    </a>
                                  </div> */}
                                </td>
                              </tr>
                            );
                          } else {
                            return (
                              <tr key={index}>
                                <td colSpan={9}>{VH_noData}</td>
                              </tr>
                            );
                          }
                        })}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

      <Modal
        isOpen={modalIsOpenPaymentDetails}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStylesPayment}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div className="">
          <PickDateTime
            openModalLanguage={false}
            openModalSuccessfullybooked={openModalSuccessfullybooked}
            changelanguage={false}
            heading={"Reschedule your workshop date and starting time"}
            dateUnavailable={true}
            openModalUnavailable={openModalUnavailable}
            languageCode={languageCode}
            IsRechedule={true}
            WorkshopId={workshopId}
            SelectedDate={selectedDate}
            PersonInGroupCount={personInGroupCount}
            TeamName={teamName}
            BookingId={bookingIdHook}
          ></PickDateTime>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpenUnavailable}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div className="modalUnavailable">
          <div className="close-modal" style={{ textAlign: "right" }}>
            <img src={close} alt="" onClick={closeModal} />
          </div>
          <h1>{Dash_MB_22}</h1>
          <p className="second-info">{Dash_MB_23} </p>
          <p className="second-info">
            {Dash_MB_24}{" "}
            <a href="mailto:booking@mission-team.com">
              booking@mission-team.com
            </a>{" "}
            {Dash_MB_25} <a href="#">+41 41 500 04 77</a>.
          </p>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpenUpdatedWorkshop}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div className="UpdatedWorkshop">
          <div className="close-modal" style={{ textAlign: "right" }}>
            <img
              src={close}
              alt=""
              onClick={() => {
                closeModal("refresh");
              }}
            />
          </div>
          <h1>{Dash_MB_19}</h1>
          <p className="first-info">
            {selectedBookingItem?.facilitated ? Dash_MB_20_F : Dash_MB_20}
          </p>
          <p className="second-info">
            {selectedBookingItem?.facilitated ? Dash_MB_21_F : Dash_MB_21}
          </p>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpenDelte}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStylesDelte}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div className="moldaDeleteBooking">
          <h2 className="delbooking">Delete Booking?</h2>
          <p className="delsure">
            Are you sure you would like to delete this booking
          </p>
          <div className="button-group">
            <button onClick={closeModal} className="keepbtn">
              No Keep
            </button>
            <button onClick={OpenWorkshopDeleted} className="yesdel">
              Yes Delete
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpenWorkshopDeleted}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStylesDelte}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div className="WorkshopDeleted">
          <h3 className="workshpdel">Workshop Deleted!</h3>
          <p className="wrkshpdelsub">You have deleted an existing workshop.</p>
          <p className="wrkshpdelsub text-center">
            The number of seats allocated to the deleted workshop has now become
            available again.You may book a new workshop.
          </p>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpenNumberUpdated}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyleslogin}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div className="modal-number">
          <div className="close-modal" style={{ textAlign: "right" }}>
            <img src={close} alt="" onClick={closeModal} />
          </div>
          <h1 className="noofparttextmodel">Number of Participants Updated!</h1>
          <p className="popuptext">
            If you added a participant, please do not forget to send a VIP
            Access Code as well as the link to the VIP and the link to the
            workshop to the new participant.
          </p>
          <p className="popuptext">
            If you deleted a participant, you may send the unused VIP Access
            Code to another participant for this workshop or for a new workshop.
          </p>
        </div>
      </Modal>

      <Modal
        isOpen={openPurchaseLicensesModal}
        onRequestClose={() => {
          set_openPurchaseLicensesModal(false);
        }}
        style={customStyleslogin}
        ariaHideApp={false}
        contentLabel="Purchase Licenses"
      >
        <div className="modal-number">
          <div className="close-modal" style={{ textAlign: "right" }}>
            <img
              src={close}
              alt=""
              onClick={() => {
                set_openPurchaseLicensesModal(false);
              }}
            />
          </div>
          <h1 className="noofparttextmodel" style={{ textAlign: "center" }}>
            {Dash_MB_32}
          </h1>
          <p className="popuptext" style={{ textAlign: "center" }}>
            {Dash_MB_33}
          </p>
          <div className="purchase_btns">
            <button
              className="yes"
              onClick={() => {
                set_openPurchaseLicensesModal(false);
                window.location.href = `/dashboard?lang=${language.selectedLanguage}`;
              }}
            >
              {Dash_MB_34}
            </button>
            <button
              className="no"
              onClick={() => set_openPurchaseLicensesModal(false)}
            >
              {Dash_MB_35}
            </button>
          </div>
        </div>
      </Modal>
      <FacilitatedWorkshopRescheduleModal
        bookingItem={selectedBookingItem}
        rescheduleCallback={OpenPaymentDetails}
        purchaseRedirect={true}
        availableFacilitators={rescheduable}
      />
    </div>
  );
};

export default View;
