import { baseApiUrl } from "../../utils/base";
import { getLocalStorage } from "../../utils/common";

export const downloadAccessCodes = (customerId, _id) => {
  const getAuthDetails = getLocalStorage("authDetailsLocal");
  fetch(`${baseApiUrl}vip_access_code/${customerId}?order_id=${_id}`, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${getAuthDetails.token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (!data.data[0] || !data.data[0]?.accessCode) {
        alert("No VIP Access Codes found in this order");
        return;
      }

      let csvContent =
        "data:text/csv;charset=utf-8," + data.data[0].accessCode.join("\r\n");
      var encodedUri = encodeURI(csvContent);
      var link = window.document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${data.data[0].orderId} Access Codes`);
      window.document.body.appendChild(link); // Required for FF
      link.click();
    });
};
