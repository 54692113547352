import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/app.context";
import QuantityField from "../../components/quantity";
import QuantityFieldText from "../../components/quantity/qtytxt";
import {
  getLocalStorage,
  setLocalStorage,
  GetPrice,
  GetPriceFacilitator,
} from "../../utils/common";
import { calculateNewValue } from "@testing-library/user-event/dist/utils";
import { LanguageContext } from "../../context/language.context";
import { baseApiUrl } from "../../utils/base";

const DynamicCal = ({ type = "participant" }) => {
  let currencyFormat = "EUR";
  const [language] = useContext(LanguageContext);
  const usedLanguage = language.translations[language.selectedLanguage];

  const {
    Dash_AVB_7_DC_1,
    Dash_AVB_8_DC_2,
    Dash_AVB_8_DC_2_1,
    Dash_AVB_8_DC_3,
    Dash_AVB_8_DC_4,
  } = usedLanguage.Dashboard;

  const [appState, setAppState] = useContext(AppContext);
  const [orderId, setOrderId] = React.useState(0);
  const [unitPrice, setunitPrice] = React.useState(0);
  const [unitPriceFacilitator, setunitPriceFacilitator] = React.useState(0);

  React.useEffect(() => {
    let customeunitPrice = GetPrice();
    setunitPrice(customeunitPrice);
    setunitPriceFacilitator(GetPriceFacilitator());
    setAppState({
      ...appState,
      liceneceCount: 8,
      facilitatorCount: 8,
      unitPriceParticipant: customeunitPrice,
      unitPriceFacilitator: GetPriceFacilitator(),
    });
  }, []);

  const orderDataCalculator = () => {
    const url = baseApiUrl + `order_id`;
    var fnorderId = 0;
    var TotalPrice = appState.liceneceCount * unitPrice;
    var TotalPrice_f = appState.facilitatorCount * unitPriceFacilitator;

    var TempVatAmt = (TotalPrice + TotalPrice_f * 7.7) / 100;
    var userData = getLocalStorage("userDataLocal");
    var existingOrderId = getLocalStorage("orderCalculatorLocale");
    var UserCountry = userData.country.toLowerCase();
    var countryExcludeForVat = ["switzerland", "ch"];
    var IsVatApplicable = countryExcludeForVat.indexOf(UserCountry) !== -1;
    var ToggleId = getLocalStorage("toggleOrderId"); //1 use existing ,0 use new

    if (ToggleId === 1) {
      if (existingOrderId !== null && existingOrderId !== undefined) {
        if (existingOrderId.orderId !== 0) {
          fnorderId = existingOrderId.orderId;
          setOrderId(existingOrderId.orderId);
          if (
            fnorderId === null ||
            fnorderId === undefined ||
            fnorderId === 0
          ) {
            return;
          }
          var TempOrderCalculator = {
            orderId: fnorderId,
            licenceCount: appState.liceneceCount,
            unitPrice: unitPrice,
            unitPriceFacilitator: unitPriceFacilitator,
            totalFacilitators: appState.facilitatorCount,
            TotalPrice: TotalPrice,
            InvoiceAmount: IsVatApplicable
              ? parseFloat(TotalPrice + TotalPrice_f + TempVatAmt)
              : parseFloat(TotalPrice + TotalPrice_f),
            VatAmount: IsVatApplicable ? TempVatAmt : 0,
            SumAmount: IsVatApplicable
              ? TotalPrice + TotalPrice_f + TempVatAmt
              : TotalPrice + TotalPrice_f,
            SeatAmount: IsVatApplicable
              ? TotalPrice + TotalPrice_f + TempVatAmt
              : TotalPrice + TotalPrice_f,
            UserCountry: UserCountry,
          };
          console.log(TempOrderCalculator);
          setAppState({
            ...appState,
            orderCalculator: TempOrderCalculator,
          });
          setLocalStorage(1, "toggleOrderId");
          setLocalStorage(TempOrderCalculator, "orderCalculatorLocale");
        } else {
          fnorderId = 0;
          //New Generate Id
          fetch(url)
            .then(function (promise) {
              return promise.json();
            })
            .then(function (response) {
              console.log(response);
              if (response.code === 200) {
                setOrderId("MTDT" + response.data);
                fnorderId = "MTDT" + response.data; //  "2124563";
                if (
                  fnorderId === null ||
                  fnorderId === undefined ||
                  fnorderId === 0
                ) {
                  return;
                }
                var TempOrderCalculator = {
                  orderId: fnorderId,
                  licenceCount: appState.liceneceCount,
                  unitPrice: unitPrice,
                  unitPriceFacilitator: unitPriceFacilitator,
                  totalFacilitators: appState.facilitatorCount,
                  TotalPrice: TotalPrice,
                  InvoiceAmount: IsVatApplicable
                    ? parseFloat(TotalPrice + TotalPrice_f + TempVatAmt)
                    : parseFloat(TotalPrice + TotalPrice_f),
                  VatAmount: IsVatApplicable ? TempVatAmt : 0,
                  SumAmount: IsVatApplicable
                    ? TotalPrice + TotalPrice_f + TempVatAmt
                    : TotalPrice + TotalPrice_f,
                  SeatAmount: IsVatApplicable
                    ? TotalPrice + TotalPrice_f + TempVatAmt
                    : TotalPrice + TotalPrice_f,
                  UserCountry: UserCountry,
                };
                console.log(TempOrderCalculator);
                setAppState({
                  ...appState,
                  orderCalculator: TempOrderCalculator,
                });
                setLocalStorage(1, "toggleOrderId");
                setLocalStorage(TempOrderCalculator, "orderCalculatorLocale");
              } else {
                console.log(response);
                setOrderId(0);
                fnorderId = 0;
              }
            })
            .catch((error) => {
              console.log(error);
              setOrderId(0);
              fnorderId = 0;
            });
          ////////////////////
        }
      }
    } else {
      fnorderId = 0;
      //////Generate New Id
      fetch(url)
        .then(function (promise) {
          return promise.json();
        })
        .then(function (response) {
          console.log(response);
          if (response.code === 200) {
            setOrderId("MTDT" + response.data);
            fnorderId = "MTDT" + response.data; //   "2124563";
            if (
              fnorderId === null ||
              fnorderId === undefined ||
              fnorderId === 0
            ) {
              return;
            }
            var TempOrderCalculator = {
              orderId: fnorderId,
              licenceCount: appState.liceneceCount,
              unitPrice: unitPrice,
              unitPriceFacilitator: unitPriceFacilitator,
              totalFacilitators: appState.facilitatorCount,
              TotalPrice: TotalPrice,
              InvoiceAmount: IsVatApplicable
                ? parseFloat(TotalPrice + TotalPrice_f + TempVatAmt)
                : parseFloat(TotalPrice + TotalPrice_f),
              VatAmount: IsVatApplicable ? TempVatAmt : 0,
              SumAmount: IsVatApplicable
                ? TotalPrice + TotalPrice_f + TempVatAmt
                : TotalPrice + TotalPrice_f,
              SeatAmount: IsVatApplicable
                ? TotalPrice + TotalPrice_f + TempVatAmt
                : TotalPrice + TotalPrice_f,
              UserCountry: UserCountry,
            };
            console.log(TempOrderCalculator);
            setAppState({
              ...appState,
              orderCalculator: TempOrderCalculator,
            });
            setLocalStorage(1, "toggleOrderId");
            setLocalStorage(TempOrderCalculator, "orderCalculatorLocale");
          } else {
            console.log(response);
            setOrderId(0);
            fnorderId = 0;
          }
        })
        .catch((error) => {
          console.log(error);
          setOrderId(0);
          fnorderId = 0;
        });

      /////////////////////
    }
  };

  const getOrderIdAPi = () => {
    const url = `https://missionteam-api.herokuapp.com/api/v1/portal/order_id`;
    fetch(url)
      .then(function (promise) {
        return promise.json();
      })
      .then(function (response) {
        console.log(response);
        if (response.code === 200) {
          return response.data;
        } else {
          console.log(response);
          return 0;
        }
      })
      .catch((error) => {
        console.log(error);
        return 0;
      });
  };

  const onLiceneceAddRemove = (orderId, operator) => {
    var totalLic = appState.liceneceCount ?? 0; //licensesCountHook;

    if (operator === "+") {
      totalLic = totalLic + 1;
    }
    if (operator === "-") {
      if (totalLic !== 0) {
        totalLic = totalLic - 1;
      }
    }
    setAppState({
      ...appState,
      liceneceCount: totalLic,
    });

    setOrderId(orderId);
  };
  const onLiceneceAddRemoveFacilitator = (orderId, operator) => {
    var totalLic = appState.facilitatorCount ?? 0; //licensesCountHook;

    if (operator === "+") {
      totalLic = totalLic + 1;
    }
    if (operator === "-") {
      if (totalLic !== 0) {
        totalLic = totalLic - 1;
      }
    }
    setAppState({
      ...appState,
      facilitatorCount: totalLic,
    });

    setOrderId(orderId);
  };

  return (
    <div className="dynamiccal-section">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h1 className="mdlsec1hdtxt">{Dash_AVB_7_DC_1}</h1>
          </div>
          <div className="col-sm-12">
            <div className="table-dynamic-calculator">
              <div className="table-responsive">
                <table className="three_column_table">
                  <tbody>
                    <tr className="table-lable">
                      <td className="orange">{Dash_AVB_8_DC_2}</td>
                      <td>{Dash_AVB_8_DC_3}</td>
                      <td>{Dash_AVB_8_DC_4}</td>
                    </tr>
                    <tr className="bg-table">
                      <td>
                        <QuantityFieldText
                          qty={appState.liceneceCount}
                          onIncrement={() => {
                            onLiceneceAddRemove(orderId, "+");
                          }}
                          onDecrement={() => {
                            onLiceneceAddRemove(orderId, "-");
                          }}
                        />
                      </td>
                      <td>
                        <span className="notext">{unitPrice} </span>
                        <span className="curtxt">{currencyFormat}</span>
                      </td>
                      <td>
                        <span className="notext">
                          {unitPrice * appState.liceneceCount}
                        </span>{" "}
                        <span className="curtxt">{currencyFormat}</span>
                      </td>
                    </tr>
                    <tr className="table-lable facilitator_table">
                      <td className="black" style={{ textAlign: "left" }}>
                        {Dash_AVB_8_DC_2_1}
                      </td>
                      {/* <td></td>
                      <td></td> */}
                    </tr>
                    <tr className="bg-table">
                      <td>
                        <QuantityFieldText
                          qty={appState.facilitatorCount}
                          onIncrement={() => {
                            onLiceneceAddRemoveFacilitator(orderId, "+");
                          }}
                          onDecrement={() => {
                            onLiceneceAddRemoveFacilitator(orderId, "-");
                          }}
                          facilitator={true}
                        />
                      </td>
                      <td>
                        <span className="notext">{unitPriceFacilitator} </span>
                        <span className="curtxt">{currencyFormat}</span>
                      </td>
                      <td>
                        <span className="notext">
                          {unitPriceFacilitator * appState.facilitatorCount}
                        </span>{" "}
                        <span className="curtxt">{currencyFormat}</span>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicCal;
