import React from 'react';

import Footer from '../../components/Footer/Footer';

import Navbar from '../../components/Navbar/Navbar';
import ManageOrder from '../../components/ManageOrder/ManageOrder'



const ViewHome = () => {


    return (
        <div style={{ overflow: 'hidden' }} className="stafhomepage">
            <Navbar showPortalLink={true}  />
            <ManageOrder></ManageOrder>
            <Footer></Footer>
        </div>
    )
}

export default ViewHome