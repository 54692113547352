import React, { useContext} from "react";
import "./Business.css";
import { Link, Navigate } from 'react-router-dom';
import Person from "../../images/person.svg";
import { useNavigate, useHistory } from "react-router-dom";
import { convertDate, getLocalStorage, setLocalStorage } from "../../utils/common";
import { AppContext } from "../../context/app.context";
import { LanguageContext } from "../../context/language.context";
import { getfullcountrylist } from "../../utils/common";

const Business = () => {
    const navigate = useNavigate()
    const [appState, setAppState] = useContext(AppContext);

   
    const [language] = useContext(LanguageContext);
    const usedLanguage = language.translations[language.selectedLanguage];
    const sellang = language.selectedLanguage;
    if(sellang ==="")
    {
        sellang ="en";
    }
    const {
        Dash_AC_H1,
        Dash_AC_H2,
        Dash_AC_H3,


    } = usedLanguage.Dashboard;

    const [userDataHook, setUserData] = React.useState(null);
    React.useEffect(() => {
        var userData = getLocalStorage("userDataLocal");
        if (userData !== null && userData !== undefined)
            setUserData(userData);
    }, []);
    const onButtonClick = () => {
        var userData = getLocalStorage("userDataLocal");
        //getcountrycode
        var ccode="";
        if(userData.country.length <=3)
        {
            const ctlist = getfullcountrylist();
            let countryname = ctlist.find((x) => x.code === userData.country);
             ccode = countryname.name;
        }
        else{
            //const ctlist = getfullcountrylist();
            //let countryname = ctlist.find((x) => x.code === userData.country);
             ccode = userData.country;
        }
       

        var formUserData = {
            "customerId": userData._id,
            "email": userData.email,
            "organization": userData.organization,
            "firstName": userData.firstName,
            "lastName": userData.lastName,
            "country": ccode,
            "address": userData.address,
            "houseNo": userData.houseNo,
            "postCode": userData.postCode,
            "town": userData.town,
            "language": userData.language,
            "mobileNo": userData.mobileNo,
            "landlineNo": userData.landlineNo,
            "lastUpdateAt": convertDate(userData.updatedAt, 'dd/MM/yyy')
        }
        console.log(formUserData);
        setAppState({...appState,contactDetailsForm:formUserData});
        setLocalStorage(formUserData, "contactdetailsLocal");
        navigate("/update?lang="+sellang);
    }



    return (
        <>
            <section style={{ marginTop: '60px' }}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <span className='youraccount'>
                                {Dash_AC_H1}
                            </span>
                        </div>
                    </div>
                </div>
            </section>
            <section style={{ marginTop: '12px' }}>
                <div className='container'>
                    <div className='backcard'>
                        <div className='row '>
                            <div className='col-lg-6 d-flex align-items-center'>
                                <span className='businessx'>
                                    {
                                        (() => {
                                            var userData = getLocalStorage("userDataLocal");
                                            if (userData !== null) {
                                                return (userData.organization);
                                            }
                                            else {
                                                return ("My Account");
                                            }
                                        })()
                                    }                                    
                                </span>
                            </div>
                            <div className='col-lg-6'>
                                <div className='AccountDetails'>
                                    <div className='btnframe'>
                                        <img src={Person} alt="" />
                                        <button id="btnupdateaccount" className="mdlsec1btnproceed" onClick={() => { onButtonClick(); }}>
                                                {Dash_AC_H2}
                                            </button>
                                        
                                        
                                        {/* <Link to="/update" id='btnupdateaccount' className="btntxtupdate" >Update Account Details</Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section style={{ marginTop: '12px' }}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <span className='notetext1'>
                               {Dash_AC_H3} 
                            </span>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default Business