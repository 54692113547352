import "./App.css";
import React, { useEffect, useState, useContext, createContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./pages/MainDashboard/Dashboard";
import UpdateAccount from "./pages/Create-Update-Account/UpdateAccount";
import Purchasehistory from "./pages/PurchaseHistory/Purchasehistory";
import CreateAccount from "./pages/Create-Update-Account/CreateAccount";
import StaffHome from "./pages/StaffHome/StaffHome";
import ViewHome from "./pages/StartPage/ViewHome";
import TempCheck from "./pages/StartPage/TempCheck";
import ScrollToTop from "./pages/ScrollToTop//ScrollToTop";
import { LanguageContext } from "./context/language.context";
import View from "./pages/ViewWorkshops/View";
import UpcomingWorkshopDetails from "./pages/workshopManagement/upcomingWorkshop";
import PastWorkshopDetails from "./pages/workshopManagement/pastWorkshop";
import defaultValues from "./utils/data/defaultValues.json";
import ThankYou from "./pages/thank-you";
import BTThankYou from "./pages/bt-thank-you";

export const AppContext = createContext(null);
const App = () => {
  //var defaultAppstate=;
  const [language, setLangauge] = useContext(LanguageContext);
  const searchParams = new URLSearchParams(window.location.search);
  let lang = "en";

  const [appState, setAppState] = useState({
    loader: false,
    paxMembersCount: defaultValues.paxMembersCount,
    orderDetailsForm: defaultValues.orderDetailsForm,
    orderDetailsFormtow: defaultValues.orderDetailsFormtow,
    contactDetailsForm: defaultValues.contactDetailsForm,
    authDetails: defaultValues.authDetails,
    bookingList: defaultValues.bookingList,
    liceneceCount: 10,
    facilitatorCount: 8,
    ordercalculation: defaultValues.ordercalculation,
    clientList: defaultValues.clientList,
    availableCurrencies: ["EUR", "CHF", "GBP"],
    cost: {
      CHF: 130,
      EUR: 125,
      GBP: 110,
    },
    selectedCurrency: "EUR",
    timer: { minutes: 10, seconds: 0 },
    selectedLanguage: "en",
    licenseCalculator: {
      total: 0,
      available: 0,
    },
  });

  useEffect(() => {
    // Select language here
    const userLanguage = searchParams.get("lang");
    if (!userLanguage) return;
    lang = userLanguage.toLowerCase();
    if (language.availableLanguages.includes(lang)) {
      setLangauge({ ...language, selectedLanguage: lang });
    }
    localStorage.setItem("lang", lang);
  }, []);

  console.log(process.env);

  return (
    <>
      <AppContext.Provider value={{ appState, setAppState }}>
        <ScrollToTop>
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/update" element={<UpdateAccount />} />
            <Route path="/purchasehistory" element={<Purchasehistory />} />
            <Route path="/createaccount" element={<CreateAccount />} />
            <Route path="/staffhome" element={<StaffHome />} />
            <Route path="/view" element={<View />} />
            <Route
              path="/upcomingWorkshopDetails/:booking_id/:workshop_id/:origin"
              element={<UpcomingWorkshopDetails />}
            />
            <Route
              path="/pastWorkshopDetails/:booking_id/:workshop_id/:origin"
              element={<PastWorkshopDetails />}
            />
            <Route path="/thank-you" element={<ThankYou />}></Route>
            <Route path="/bt-thank-you" element={<BTThankYou />}></Route>
            <Route path="/tempcheck" element={<TempCheck />} />
            <Route path="/" element={<ViewHome />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </ScrollToTop>
      </AppContext.Provider>
    </>
  );
};

export default App;
