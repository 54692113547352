import React, { useContext, useEffect, useState, useRef } from "react";
import "./NewAccount.css";
import {} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LeftArrow from "../../images/leftarrow.svg";
import { Field, Form, Formik } from "formik";
import countriesList from "../../utils/data/countriesList.json";
import { AppContext } from "../../context/app.context";
import classNames from "classnames";
import * as Yup from "yup";
import { getfullcountrylist } from "../../utils/common";

import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import InputField from "../../components/input";
import Modal from "react-modal";
import { baseApiUrl } from "../../utils/base";
import { FieldMandatoryView } from "../../utils/common";
import {
  convertDate,
  getLocalStorage,
  setLocalStorage,
  unitPrice,
} from "../../utils/common";
import defaultValues from "../../utils/data/defaultValues.json";

const NewAccount = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const formRef = useRef();
  const [input, setInput] = useState({});
  const [appState] = useContext(AppContext);
  const [countryCallingCodes, setCountryCallingCodes, target] = useState([]);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [checkConsent, setcheckConsent] = useState(false);
  const [modalIsOpenconfirmAccount, setIsOpenconfirmAccount] =
    React.useState(false);

  const [modalIsOpenconfirmRestore, setIsOpenconfirmAccountRestore] =
    React.useState(false);

  const [accountalreadyexists, setaccountalreadyexists] = React.useState(false);

  const [unitPriceUpdated, setUnitPriceUpdated] = React.useState(0);
  const [email, setEmail] = React.useState("");
  const [consentError, setConsentError] = useState("");

  let initialValues = defaultValues.newclient;
  initialValues.language = appState.selectedLanguage.toUpperCase();

  if (state !== null) {
    initialValues.Email = state.email;
    initialValues.Organisation = state.organization;
    initialValues.firstname = state.firstName;
    initialValues.lastname = state.lastName;
    initialValues.MobileNumber = state.mobileNo;
  }

  const getLanguage = (code) => {
    switch (code) {
      case "en":
        return "english";
      case "de":
        return "german";
      case "it":
        return "italian";
      case "fr":
        return "french";
      default:
        return "english";
    }
  };

  function openconfirmAccount() {
    if (input.Email !== "") {
      setIsOpenconfirmAccount(true);
    }
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpenconfirmAccount(false);
    formSubmitting(true);
  }
  function closeModalaccount() {
    setaccountalreadyexists(false);
    formSubmitting(true);
  }
  function closeModalaccountrestore() {
    setIsOpenconfirmAccountRestore(false);
    formSubmitting(true);
  }

  useEffect(() => {
    if (!countryCallingCodes.length) {
      const countriesCodeList = getCountries();
      let callingCodesList = [];
      for (const countryCode of countriesCodeList) {
        const callingCode = getCountryCallingCode(countryCode);
        if (callingCodesList.indexOf(callingCode) === -1) {
          callingCodesList.push(callingCode);
        }
      }
      callingCodesList.sort((a, b) => a - b);
      setCountryCallingCodes(callingCodesList);
    }
  }, [countryCallingCodes]);

  useEffect(() => {
    if (!countryCallingCodes.length) {
      const countriesCodeList = getCountries();
      let callingCodesList = [];
      for (const countryCode of countriesCodeList) {
        const callingCode = getCountryCallingCode(countryCode);
        if (callingCodesList.indexOf(callingCode) === -1) {
          callingCodesList.push(callingCode);
        }
      }
      callingCodesList.sort((a, b) => a - b);
      setCountryCallingCodes(callingCodesList);
    }
  }, []);

  const saveInput = () => {
    console.log(input);
    if (formRef.current) {
      formRef.current.handleSubmit();
      //console.log(input);
      if (formRef.current.isValid) {
        submitForm(input);
      } else {
        setcheckConsent(false);
      }
    }
  };

  const SignupSchema = Yup.object().shape({
    Organisation: Yup.string().required("This field is mandatory."),
    firstname: Yup.string().required("This field is mandatory."),
    lastname: Yup.string().required("This field is mandatory."),
    address: Yup.string().required("This field is mandatory."),
    PostCode: Yup.string().required("This field is mandatory."),
    MobileNumber: Yup.string().required("This field is mandatory."),
    licencePrice: Yup.string().required("This field is mandatory."),
    f_licencePrice: Yup.string().required("This field is mandatory."),
    country: Yup.string().required("This field is mandatory."),
    Town: Yup.string().required("This field is mandatory."),
    // consent: Yup.boolean().oneOf([true], "This field is mandatory."),
    Email: Yup.string()
      .email("Invalid email")
      .required("This field is mandatory."),
  });

  const submitForm = (values) => {
    if (
      values.Organisation === "" ||
      values.firstname === "" ||
      values.lastname === "" ||
      values.address === "" ||
      values.PostCode === "" ||
      values.Email === "" ||
      values.MobileNumber === ""
    )
      return;
    const ctlist = getfullcountrylist();
    let countryname = ctlist.find((x) => x.code === values.country);
    var cname = countryname.name;

    var tempformDetails = {
      email: values.Email,
      organization: values.Organisation,
      firstName: values.firstname,
      lastName: values.lastname,
      country: cname,
      address: values.address,
      houseNo: values.HouseNumber,
      postCode: values.PostCode,
      town: values.Town,
      language: getLanguage(values.language),
      mobileNo: values.MobileNumber,
      landlineNo: values.LandLineNumber,
      licencePrice: values.licencePrice,
      facilitatorsPrice: values.f_licencePrice,
    };
    console.log(tempformDetails);
    setFormSubmitting(true);
    const url = baseApiUrl + "client";
    var authDetails = getLocalStorage("authDetailsLocalstaff");
    fetch(url, {
      body: JSON.stringify(tempformDetails),
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authDetails.token}`,
      },
    })
      .then((response) => response.json())
      .then(function (response) {
        console.log(response);
        if (response.code === 200) {
          setIsOpenconfirmAccount(false);
          navigate("/staffhome");
        } else {
          if (response.message === "account is deleted for this email") {
            setIsOpenconfirmAccount(false);
            setIsOpenconfirmAccountRestore(true);
          } else {
            setIsOpenconfirmAccount(false);
            setaccountalreadyexists(true);
            setFormSubmitting(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        //setFormSubmitting(false);
      });
  };

  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = () => {
    setIsDisabled(!isDisabled);
  };

  const customhandleChange = (e) => {
    const { value } = e.target;
    setUnitPriceUpdated(value);
  };

  const customhandleChangeEmail = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleSubmit = () => {
    submitForm();
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "90%",
      padding: "0px",
      maxWidth: "900px",
      transform: "translate(-50%, -50%)",
      borderRadius: "36px",
      border: "none",
    },
  };
  const mandatoryFieldStyle = (touched, value) => {
    return {
      border: value === "" ? "#d10707 2px solid" : "rgb(204, 204, 204)",
    };
  };

  const checkSubmit = (values) => {
    if (checkConsent) {
      setInput(values);
      setIsOpenconfirmAccount(true);
    } else {
      setConsentError("* Please accept the terms and conditions");
    }
  };

  function restore(email) {
    //alert(email);
    var authDetails = getLocalStorage("authDetailsLocalstaff");
    const url = baseApiUrl + "restore_profile";
    var datas = {
      email: email,
    };
    console.log(datas);
    fetch(url, {
      body: JSON.stringify(datas),
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authDetails.token}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (promise) {
        return promise.json();
      })
      .then(function (response) {
        console.log(response);
        if (response.code === 200) {
          navigate("/staffhome");
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div>
      <div>
        <section>
          <div className="container">
            <div className="row mb-3">
              <div className="col-12">
                <div className="goback-button">
                  <img src={LeftArrow} className="leftarrow" alt="" />
                  <Link to="/staffhome" id="goback" className="gobacktext">
                    Go Back
                  </Link>
                </div>
                <div className="createNewClient-header">
                  <h1>Create New Client Account</h1>
                  <p>Please enter your clients’ details in to continue</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <Formik
              initialValues={initialValues}
              onSubmit={checkSubmit}
              innerRef={formRef}
              validationSchema={SignupSchema}
            >
              {({ values, errors, touched, setFieldValue, handleChange }) => (
                <Form id="salesContact">
                  <div className="row">
                    <div className="col-md-5 mb-4">
                      <InputField
                        name="Organisation"
                        placeholder="Organisation"
                        form={{ errors, touched }}
                        // style={mandatoryFieldStyle(
                        //   touched.Organisation,
                        //   values.Organisation
                        // )}
                      />
                    </div>
                    <div className="col-md-5 mb-4">
                      <InputField
                        name="firstname"
                        placeholder="First Name"
                        form={{ errors, touched }}
                        // style={mandatoryFieldStyle(
                        //   touched.firstname,
                        //   values.firstname
                        // )}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5 mb-4">
                      <Field
                        as="select"
                        name="country"
                        className={classNames(
                          "input_field",
                          errors.country && touched.country
                            ? "input_error"
                            : null
                        )}
                        onChange={(e) => {
                          setFieldValue(
                            "countryCallingCode",
                            getCountryCallingCode(e.target.value || "CH")
                          );
                          handleChange(e);
                        }}
                        // style={mandatoryFieldStyle(
                        //   touched.country,
                        //   values.country
                        // )}
                      >
                        <option value="">Country</option>
                        {countriesList.map((country) => (
                          <option key={country.code} value={country.code}>
                            {country.name}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div className="col-md-5 mb-4">
                      <InputField
                        name="lastname"
                        placeholder="Last Name"
                        form={{ errors, touched }}
                        // style={mandatoryFieldStyle(
                        //   touched.lastname,
                        //   values.lastname
                        // )}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5 mb-4">
                      <InputField
                        name="address"
                        placeholder="Address"
                        form={{ errors, touched }}
                        // style={mandatoryFieldStyle(
                        //   touched.address,
                        //   values.address
                        // )}
                      />
                    </div>
                    <div className="col-md-5 mb-4">
                      <InputField
                        name="HouseNumber"
                        placeholder="House Number"
                        form={{ errors, touched }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5 mb-4">
                      <InputField
                        name="PostCode"
                        placeholder="Post Code"
                        form={{ errors, touched }}
                        // style={mandatoryFieldStyle(
                        //   touched.PostCode,
                        //   values.PostCode
                        // )}
                      />
                    </div>
                    <div className="col-md-5 mb-4">
                      <InputField
                        name="Town"
                        placeholder="Town"
                        form={{ errors, touched }}
                        //         style={mandatoryFieldStyle(touched.Town, values.Town)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5 mb-4">
                      <Field
                        as="select"
                        className="input_field"
                        name="language"
                        form={{ errors, touched }}
                        // style={mandatoryFieldStyle(
                        //   touched.language,
                        //   values.language
                        // )}
                      >
                        <option value="" disabled>
                          {"language"}
                        </option>
                        {["EN", "DE", "IT", "FR"].map((language) => (
                          <option key={language} value={language}>
                            {language}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div className="col-md-5 mb-4">
                      <InputField
                        name="Email"
                        placeholder="Email"
                        form={{ errors, touched }}
                        //         style={mandatoryFieldStyle(touched.Email, values.Email)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5 mb-4">
                      <div className="set-back">
                        <div className="price-box">
                          <div className="number-block">
                            <InputField
                              name="licencePrice"
                              form={{ errors, touched }}
                            />
                          </div>
                          <div className="currencytext">EUR</div>
                        </div>
                        <button
                          type="button"
                          onClick={handleClick}
                          className="set"
                        >
                          {isDisabled ? "RESET" : "SET"}
                        </button>
                      </div>
                      <div className="priceperlis">
                        PRICE PER PARTICIPANT LICENSE
                      </div>
                    </div>

                    <div className="col-md-1 mb-4">
                      <Field
                        as="select"
                        className="input_field ccode"
                        name="countryCallingCode"
                      >
                        {countryCallingCodes.map((countryCode) => (
                          <option key={countryCode} value={countryCode}>
                            +{countryCode}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div className="col-md-4 mb-4">
                      <InputField
                        name="MobileNumber"
                        placeholder="Tel/Mob"
                        form={{ errors, touched }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-5 col-12  mb-4">
                      <div className="set-back">
                        <div className="price-box">
                          <div className="number-block">
                            <InputField
                              name="f_licencePrice"
                              form={{ errors, touched }}
                            />
                          </div>
                          <div className="currencytext">EUR</div>
                        </div>
                        <button
                          type="button"
                          onClick={handleClick}
                          className="set"
                        >
                          {isDisabled ? "RESET" : "SET"}
                        </button>
                      </div>
                      <div className="priceperlis">
                        PRICE PER FACILITATOR LICENSE
                      </div>
                    </div>

                    <div className="col-xl-3  col-12 mb-4">
                      <div className="form-outline account-Confirmation">
                        <span className="accountconfirm">
                          Account Confirmation
                        </span>
                        <span className="confirmdet">
                          <div className="checkbox-block">
                            <input
                              id="Business"
                              type="checkbox"
                              name="consent"
                              onChange={(e) => {
                                setcheckConsent(e.target.checked);
                                setConsentError("");
                              }}
                              checked={checkConsent}
                            />
                            <label htmlFor="Business">
                              I confirm all details are accurate
                            </label>
                          </div>
                          {consentError && (
                            <p className="error">{consentError}</p>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-xl-2  col-12 mb-4">
                      <div className="form-outline">
                        <button type="submit" className="createaccount">
                          Create Account
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </section>
      </div>

      <Modal
        isOpen={modalIsOpenconfirmAccount}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        {" "}
        <section className="confirmAccountModal">
          <h1 className="pophdtext">Confirm Account Setup </h1>
          <p className="poheadsubtxt">
            Account login details will be sent to{" "}
            <span className="clickhere">{input.Email}</span> with a participant
            license price of{" "}
            <b style={{ fontWeight: "800", color: "black" }}>
              {input.licencePrice} EUR
            </b>{" "}
            and a facilitator license price of{" "}
            <b style={{ fontWeight: "800", color: "black" }}>
              {input.f_licencePrice} EUR
            </b>{" "}
            EUR.
          </p>
          <div className="button-action">
            <button className="btndiscard" onClick={closeModal}>
              Discard
            </button>
            <button className="btncon" onClick={() => saveInput()}>
              Confirm & Send Details
            </button>
          </div>
        </section>
      </Modal>

      <Modal
        isOpen={accountalreadyexists}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalaccount}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example "
      >
        {" "}
        <section className="confirmAccountModal">
          <h1 className="pophdtext">This account already exists </h1>
          <p className="poheadsubtxt">
            Please look for this user in the “My Clients” or "Prospect" tab.
          </p>
        </section>
      </Modal>

      <Modal
        isOpen={modalIsOpenconfirmRestore}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalaccountrestore}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        {" "}
        <section className="confirmAccountModal">
          <h1 className="pophdtext">This account was deleted </h1>
          <p className="poheadsubtxt">
            Would you like to restore this account?
          </p>
          <div className="button-action">
            <button className="btndiscard" onClick={closeModalaccountrestore}>
              Discard
            </button>
            <button className="btncon" onClick={() => restore(input.Email)}>
              Restore
            </button>
          </div>
        </section>
      </Modal>
    </div>
  );
};

export default NewAccount;
