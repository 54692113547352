import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";

import { AppContext } from "../../context/app.context";
import { LanguageContext } from "../../context/language.context";

import PreLoader from "../../components/preLoader/preLoader";
import Navbar from "../../components/Navbar/Navbar";
import QuantityField from "../../components/quantity";
import Modal from "react-modal";
import PickDateTime from "../../components/Dashboard/PickDateTime";
import Footer from "../../components/Footer/Footer";
import FacilitatedWorkshopRescheduleModal from "../../components/Dashboard/modals/facilitated-workshop-reschedule";

import "./workshopManagement.scss";
import copy from "../../images/copy.svg";
import LeftArrow from "../../images/leftarrow.svg";
import close from "../../images/CloseIcon.svg";

import copytoclipboard from "copy-to-clipboard";

import {
  dateLessThanToday,
  convertDate,
  convertTime,
  addHours,
  getBookingCount,
  getLocalStorage,
  setLocalStorage,
  fnPurchasedLic,
  fnTotalLic,
  convertToDate,
  fndisableButtonStyle,
  getLanguageCode,
} from "../../utils/common";

import { baseApiUrl, baseInstanceDetailsURL } from "../../utils/base";

const customStyleslogin = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "90%",
    padding: "0px",
    maxWidth: "901px",
    transform: "translate(-50%, -50%)",
    borderRadius: "36px",
    border: "none",
  },
};

const UpcomingWorkshopDetails = () => {
  const { booking_id, workshop_id, origin } = useParams();

  const [appState, setAppState] = useContext(AppContext);
  const [language] = useContext(LanguageContext);

  const usedLanguage = language.translations[language.selectedLanguage];

  const gobacklink = `/${origin}?lang=${language.selectedLanguage}`;

  const [showPreLoader, setShowPreLoader] = useState(true);
  const [workshop, setWorkshop] = useState(false);
  const [bookingListHook, setBookingListHook] = useState([]);
  const [totalLicHook, setTotalLic] = useState(0);
  const [modalIsOpenNumberUpdated, setIsOpenNumberUpdated] = useState(false);
  const [modalIsOpenPaymentDetails, setIsOpenPaymentDetails] = useState(false);
  const [modalIsOpenUpdatedWorkshop, setIsOpenUpdatedWorkshop] =
    useState(false);
  const [modalIsOpenUnavailable, setIsOpenUnavailable] = useState(false);
  const [languageCode, setLanguageCode] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [workshopId, setWorkshopId] = useState("");
  const [personInGroupCount, setPersonInGroupCount] = useState(2);
  const [teamName, setTeamName] = useState("");
  const [bookingIdHook, setBookingIdHook] = useState("");
  const [m_link, set_m_link] = useState("");
  const [p_link, set_p_link] = useState("");
  const [resendEmailSuccessful, set_resendEmailSuccessful] = useState(false);
  const [client_email, set_client_email] = useState("client@business.com");
  const [facilitated_workshop, set_facilitated_workshop] = useState(false);
  const [rescheduable, set_rescheduable] = useState(false);
  const [openPurchaseLicensesModal, set_openPurchaseLicensesModal] =
    useState(false);

  const apiUrlBooking = baseApiUrl + "client/booking/";

  const customStylesPayment = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "90%",
      padding: "0px",
      maxWidth: "1124px",
      transform: "translate(-50%, -50%)",
      borderRadius: "50px",
      border: "none",
    },
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "90%",
      padding: "0px",
      maxWidth: "900px",
      transform: "translate(-50%, -50%)",
      borderRadius: "50px",
      border: "none",
    },
  };

  const {
    Dash_Yes,
    Dash_No,
    Dash_MB_1,
    Dash_MB_2,
    Dash_MB_3,
    Dash_MB_3_1,
    Dash_MB_5,
    Dash_MB_6,
    Dash_MB_7,
    Dash_MB_19,
    Dash_MB_20,
    Dash_MB_21,
    Dash_MB_20_F,
    Dash_MB_21_F,
    Dash_MB_22,
    Dash_MB_23,
    Dash_MB_24,
    Dash_MB_25,
    Dash_MB_26,
    Dash_MB_27,
    Dash_MB_28,
    Dash_MB_29,
    Dash_MB_32,
    Dash_MB_33,
    Dash_MB_34,
    Dash_MB_35,
  } = usedLanguage.Dashboard;

  const { VH_goBack } = usedLanguage.ViewAll;

  const {
    workshopHeading,
    resendBookingEmail,
    p_links,
    vip_access_links,
    vip_access_links_info,
    copy_link,
    system_check_link,
    system_check_link_info,
    vip_access_codes,
    vip_access_codes_view,
    p_access_link,
    p_links_info,
    m_links,
    m_training_link,
    m_training_link_info,
    m_workshop_access_link,
    m_workshop_access_link_info,
    m_checklist,
    m_checklist_download,
    m_training_link_link,
    m_checklist_link,
  } = usedLanguage.workshopDetailsPage;

  const CopyLinksData = (workshopid) => {
    let apiurl =
      "https://spatialchat.mission-team.com/dashboard/instance/all/" +
      workshopid;

    fetch(apiurl, {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then(function (response) {
        // console.log(response);
        if (response.status === 1) {
          //console.log(response.result[0].participantLink);

          let cdata =
            response.result[0].participantLink +
            " || " +
            response.result[0].moderatorLink;
          copytoclipboard(cdata);
          alert("copied to clipboard");
        } else {
        }
      })
      .catch((err) => {});
  };

  function OpenPaymentDetails(bookingItem) {
    console.log(bookingItem);
    const languageCode = getLanguageCode(bookingItem.language);
    setLanguageCode(languageCode);
    localStorage.removeItem("workshops");
    setSelectedDate(
      new Date(convertDate(bookingItem.workshopDate, "yyyy-MM-dd"))
    );
    setWorkshopId(bookingItem.workshopId);
    setPersonInGroupCount(bookingItem.personInGroup);
    setTeamName(bookingItem.teamName);
    setBookingIdHook(bookingItem.bookingId);
    console.log(selectedDate);
    console.log(workshopId);
    console.log(personInGroupCount);
    console.log(teamName);
    setIsOpenPaymentDetails(true);
  }

  function openModalSuccessfullybooked() {
    setIsOpenUpdatedWorkshop(true);
    setIsOpenPaymentDetails(false);
  }

  function openModalUnavailable() {
    setIsOpenUnavailable(true);
    setIsOpenPaymentDetails(false);
  }

  function OpenNumberUpdated() {
    setIsOpenNumberUpdated(true);
  }

  const storeGroupSize = (bookingId, workshopId, personInGroup) => {
    var getAuthDetails = getLocalStorage("authDetailsLocal");
    const url = baseApiUrl + "client/workshop/group_size";
    var data = {
      customerId: getAuthDetails.userId,
      bookingId: bookingId,
      workshopId: workshopId,
      personInGroup: personInGroup,
    };

    fetch(url, {
      body: JSON.stringify(data),
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getAuthDetails.token}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (promise) {
        return promise.json();
      })
      .then(function (response) {
        console.log(response);
        if (response.code === 200) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateUserData = () => {
    var userDataTemp = getLocalStorage("userDataLocal");
    var licDetails = getLocalStorage("licLocal");
    userDataTemp.totalLicenses = licDetails.total;
    userDataTemp.availableLicenses = licDetails.available;
    localStorage.removeItem("userDataLocal");
    setLocalStorage(userDataTemp, "userDataLocal");
  };

  const onPersonInGoupCalculate = (id, bookingId, operator) => {
    var CallApi = false;
    var licDetails = getLocalStorage("licLocal");
    setTotalLic(licDetails.total);

    var filtredList = bookingListHook.filter(
      (items) => items._id === id && items.bookingId === bookingId
    );
    var totalLic = licDetails.total; //fnTotalLic(userDataHook);
    var availableLic = licDetails.available; //fnTotalLic(userDataHook) - fnPurchasedLic(bookingListHook);
    var personasInallgroups = fnPurchasedLic(bookingListHook);
    var personinGroup = filtredList[0].personInGroup;
    if (operator === "+") {
      personasInallgroups = personasInallgroups - personinGroup;
      personinGroup = personinGroup + 1;
      personasInallgroups = personasInallgroups + personinGroup;
      console.log(personasInallgroups, personinGroup);
      console.log(availableLic);
      if (personinGroup <= 18) {
        console.log(personasInallgroups, totalLic, availableLic);
        if (personasInallgroups <= totalLic && availableLic >= 1) {
          console.log(personinGroup);
          var bookinglistTemp = bookingListHook;
          var updaetdbookinglistTemp = bookinglistTemp.map((item) => {
            var temp = Object.assign({}, item);
            if (temp.bookingId === bookingId && temp._id === id) {
              temp.personInGroup = personinGroup;
            }
            return temp;
          });
          CallApi = true;
          availableLic = availableLic - 1;

          var templicenseCalculator = {
            total: totalLicHook,
            available: availableLic,
          };
          setLocalStorage(templicenseCalculator, "licLocal");
          setAppState({
            ...appState,
            licenseCalculator: templicenseCalculator,
          });
          setLocalStorage(updaetdbookinglistTemp, "bookingListLocal");
          setAppState({ ...appState, bookingList: updaetdbookinglistTemp });
        }
      }
    }

    if (operator === "-") {
      personasInallgroups = personasInallgroups - personinGroup;
      personinGroup = personinGroup - 1;
      personasInallgroups = personasInallgroups + personinGroup;

      if (personinGroup >= 2) {
        if (personasInallgroups >= 0) {
          var bookinglistTemp = bookingListHook;
          var updaetdbookinglistTemp = bookinglistTemp.map((item) => {
            var temp = Object.assign({}, item);
            if (temp.bookingId === bookingId && temp._id === id) {
              temp.personInGroup = personinGroup;
            }
            return temp;
          });
          CallApi = true;
          availableLic = availableLic + 1;

          var templicenseCalculator = {
            total: totalLicHook,
            available: availableLic,
          };

          setAppState({
            ...appState,
            licenseCalculator: templicenseCalculator,
          });
          setLocalStorage(templicenseCalculator, "licLocal");
          setLocalStorage(updaetdbookinglistTemp, "bookingListLocal");
          setAppState({ ...appState, bookingList: updaetdbookinglistTemp });
        }
      }
    }
    if (CallApi) {
      updateUserData();
      storeGroupSize(
        filtredList[0].bookingId,
        filtredList[0].workshopId,
        personinGroup
      );
      OpenNumberUpdated();
    }
  };

  useEffect(() => {
    var licDetails = getLocalStorage("licLocal");
    setTotalLic(licDetails.total);

    var getAuthDetails = getLocalStorage("authDetailsLocal");
    const customer_id = getAuthDetails.userId;

    fetch(`${baseApiUrl}client/bookedworkshop`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getAuthDetails.token}`,
      },
      body: JSON.stringify({
        customerId: customer_id,
        bookingid: booking_id,
        workshopid: workshop_id,
        date: "",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        set_facilitated_workshop(
          data.data.bookingList[0]?.facilitated ?? false
        );
        setBookingListHook(data.data.bookingList);
        setWorkshop(data.data.bookingList[0]);

        // Get participant Link
        fetch(
          `${baseInstanceDetailsURL}all/${data.data.bookingList[0].workshopId}`
        )
          .then((response) => response.json())
          .then((data) => {
            const { moderatorLink, participantLink, personInGroup } =
              data.result[0];
            set_m_link(moderatorLink);
            set_p_link(participantLink);
            setPersonInGroupCount(personInGroup);
            setShowPreLoader(false);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    if (!workshop?.facilitated) return;
    var getAuthDetails = getLocalStorage("authDetailsLocal");

    fetch(apiUrlBooking + getAuthDetails.userId, {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getAuthDetails.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.data.userData?.availableFacilitators) set_rescheduable(false);
        else set_rescheduable(true);
      });
  }, [workshop]);

  return (
    <section>
      <Navbar showPortalLink={false} />
      {showPreLoader && <PreLoader />}
      {workshop && (
        <>
          <div className="container">
            <div className="row">
              <div
                className="col-12"
                style={{ textAlign: "left", marginTop: "40px" }}
              >
                <img src={LeftArrow} className="leftarrow" alt="" />
                <Link to={gobacklink} id="goback" className="gobacktext">
                  {VH_goBack}
                </Link>
              </div>
            </div>
          </div>

          <div className="container team_heading">
            <h1 className="team_name">
              {workshopHeading}:{" "}
              <span className="black">{workshop.teamName}</span>
            </h1>
            <button
              className="resend_email"
              onClick={() => {
                var getAuthDetails = getLocalStorage("authDetailsLocal");
                fetch(
                  `${baseApiUrl}resend_workshop_email?bookingid=${workshop.bookingId}&workshop_id=${workshop.workshopId}`,
                  {
                    headers: {
                      Accept: "application/json",
                      Authorization: `Bearer ${getAuthDetails.token}`,
                    },
                  }
                )
                  .then((response) => response.json())
                  .then((data) => {
                    if (data.status) {
                      set_resendEmailSuccessful(true);
                      set_client_email(data.data.client_email);
                    }
                  });
              }}
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6 5C4.34315 5 3 6.34315 3 8V20C3 21.6569 4.34315 23 6 23H22C23.6569 23 25 21.6569 25 20V8C25 6.34315 23.6569 5 22 5H6ZM8.64021 9.2318C8.21593 8.87824 7.58537 8.93556 7.2318 9.35984C6.87824 9.78412 6.93556 10.4147 7.35984 10.7682L12.0795 14.7013C13.192 15.6284 14.808 15.6284 15.9206 14.7013L20.6402 10.7682C21.0645 10.4147 21.1218 9.78412 20.7682 9.35984C20.4147 8.93556 19.7841 8.87824 19.3598 9.2318L14.6402 13.1648C14.2694 13.4739 13.7307 13.4739 13.3598 13.1648L8.64021 9.2318Z"
                  fill="white"
                />
              </svg>
              {resendBookingEmail}
            </button>
          </div>

          <div className="container gridcard workshopcard">
            <div className="row">
              <div className="col-lg-12">
                <div className="table-responsive">
                  <table className="table table-borderless single_workshop">
                    <thead className="thead tableheaders">
                      <tr>
                        <td>{Dash_MB_1}</td>
                        <td>{Dash_MB_2}</td>
                        <td>{Dash_MB_3}</td>
                        <td>{Dash_MB_3_1}</td>
                        <td>{Dash_MB_5}</td>
                        <td>{Dash_MB_6}</td>
                        <td></td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody className="tablebodycolumn1">
                      <tr>
                        <td>
                          {convertDate(workshop.workshopDate, "dd/MM/yyyy")}
                        </td>
                        <td>
                          {convertTime(workshop.workshopTime) +
                            "-" +
                            convertTime(addHours(workshop.workshopTime))}
                        </td>
                        <td>
                          <QuantityField
                            qty={workshop.personInGroup}
                            onIncrement={() => {
                              onPersonInGoupCalculate(
                                workshop._id,
                                workshop.bookingId,
                                "+"
                              );
                            }}
                            onDecrement={() => {
                              onPersonInGoupCalculate(
                                workshop._id,
                                workshop.bookingId,
                                "-"
                              );
                            }}
                            // OpenNumberUpdated={OpenNumberUpdated}
                            disabled={dateLessThanToday(workshop.workshopDate)}
                          />
                        </td>
                        <td>{workshop.facilitated ? Dash_Yes : Dash_No}</td>
                        <td>{workshop.language.toUpperCase()}</td>
                        <td>
                          {(() => {
                            if (!dateLessThanToday(workshop.workshopDate)) {
                              return (
                                <button
                                  className="btnres"
                                  onClick={() => {
                                    if (workshop.facilitated) {
                                      setAppState({
                                        ...appState,
                                        facilitatedWorkshopRescheduleModalOpen: true,
                                      });

                                      return;
                                    }

                                    // if (workshop.facilitated && !rescheduable) {
                                    //   set_openPurchaseLicensesModal(true);
                                    //   return;
                                    // }
                                    OpenPaymentDetails(workshop);
                                  }}
                                >
                                  {Dash_MB_26}
                                </button>
                              );
                            } else {
                              return (
                                <button
                                  className="btnres"
                                  style={fndisableButtonStyle()}
                                >
                                  {Dash_MB_26}
                                </button>
                              );
                            }
                          })()}
                        </td>
                        <td>
                          <div className="copy-action">
                            <img
                              src={copy}
                              alt=""
                              onClick={() => {
                                CopyLinksData(workshop.workshopId);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="container" style={{ marginTop: "12px" }}>
            <div className="row">
              <div className="col-lg-12">
                <span className="subh5txt1">{Dash_MB_7}</span>
              </div>
            </div>
          </div>

          <div className="container gridcard workshopcard">
            <h2 className="links_heading">{p_links}</h2>
            <div className="links_boxes">
              <div className="link_box left">
                <h3 className="link_heading">{vip_access_links}</h3>
                <p className="link_info">{vip_access_links_info}</p>
                <div className="link_action_div">
                  <div className="copy_link_div seventy">
                    https://service.psyfiers.ch/missionteamdigitalteambuilding242/480/?lang=
                    {language.selectedLanguage}
                  </div>
                  <button
                    className="link_action_btn thirty"
                    onClick={(e) => {
                      copytoclipboard(
                        `https://service.psyfiers.ch/missionteamdigitalteambuilding242/480/?lang=${language.selectedLanguage}`
                      );
                      e.target.classList.add("copied");
                    }}
                  >
                    {copy_link}
                  </button>
                </div>
              </div>
              <div className="link_box right">
                <h3 className="link_heading">{system_check_link}</h3>
                <p className="link_info">{system_check_link_info}</p>
                <div className="link_action_div">
                  <div className="copy_link_div seventy">
                    https://mission-team.com/check-system
                  </div>
                  <button
                    className="link_action_btn thirty"
                    onClick={(e) => {
                      copytoclipboard("https://mission-team.com/check-system");
                      e.target.classList.add("copied");
                    }}
                  >
                    {copy_link}
                  </button>
                </div>
              </div>
              <div className="link_box left">
                <h3 className="link_heading">{vip_access_codes}</h3>
                <div className="link_action_div">
                  <a
                    href={`/PurchaseHistory?lang=${language.selectedLanguage}`}
                    className="link_action_btn"
                  >
                    {vip_access_codes_view}
                  </a>
                </div>
              </div>
              <div className="link_box right">
                <h3 className="link_heading">{p_access_link}</h3>
                <div className="link_action_div">
                  <div className="copy_link_div seventy">{p_link}</div>
                  <button
                    className="link_action_btn thirty"
                    onClick={(e) => {
                      copytoclipboard(p_link);
                      e.target.classList.add("copied");
                    }}
                  >
                    {copy_link}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container" style={{ marginTop: "12px" }}>
            <div className="row">
              <div className="col-lg-12">
                <span className="subh5txt1">{p_links_info}</span>
              </div>
            </div>
          </div>

          {!facilitated_workshop && (
            <div className="container gridcard workshopcard">
              <h2 className="links_heading">{m_links}</h2>
              <div className="links_boxes">
                <div className="link_box left">
                  <h3 className="link_heading">{m_training_link}</h3>
                  <p className="link_info">{m_training_link_info}</p>
                  <div className="link_action_div">
                    <div className="copy_link_div seventy">
                      {m_training_link_link}
                    </div>
                    <button
                      className="link_action_btn thirty"
                      onClick={(e) => {
                        copytoclipboard(m_training_link_link);
                        e.target.classList.add("copied");
                      }}
                    >
                      {copy_link}
                    </button>
                  </div>
                </div>
                <div className="link_box right">
                  <h3 className="link_heading">{m_workshop_access_link}</h3>
                  <p className="link_info">{m_workshop_access_link_info}</p>
                  <div className="link_action_div">
                    <div className="copy_link_div seventy">{m_link}</div>
                    <button
                      className="link_action_btn thirty"
                      onClick={(e) => {
                        copytoclipboard(m_link);
                        e.target.classList.add("copied");
                      }}
                    >
                      {copy_link}
                    </button>
                  </div>
                </div>
                <div className="link_box left">
                  <h3 className="link_heading">{m_checklist}</h3>
                  <div className="link_action_div">
                    <a
                      href={m_checklist_link}
                      target={"_blank"}
                      className="link_action_btn"
                    >
                      {m_checklist_download}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* For rescheduling */}
          <Modal
            isOpen={modalIsOpenPaymentDetails}
            onRequestClose={() => setIsOpenPaymentDetails(false)}
            style={customStylesPayment}
            ariaHideApp={false}
            contentLabel="Reschedule Workshop"
          >
            <div className="">
              <PickDateTime
                openModalLanguage={false}
                openModalSuccessfullybooked={openModalSuccessfullybooked}
                changelanguage={false}
                heading={"Reschedule your workshop date and starting time"}
                dateUnavailable={true}
                openModalUnavailable={openModalUnavailable}
                languageCode={languageCode}
                IsRechedule={true}
                WorkshopId={workshop.workshopId}
                SelectedDate={selectedDate}
                PersonInGroupCount={personInGroupCount}
                TeamName={teamName}
                BookingId={bookingIdHook}
              ></PickDateTime>
            </div>
          </Modal>

          <Modal
            isOpen={modalIsOpenUpdatedWorkshop}
            onRequestClose={() => setIsOpenUpdatedWorkshop(false)}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Workshop Updated"
          >
            <div className="UpdatedWorkshop">
              <div className="close-modal" style={{ textAlign: "right" }}>
                <img
                  src={close}
                  alt=""
                  onClick={() => setIsOpenUpdatedWorkshop(false)}
                />
              </div>
              <h1>{Dash_MB_19}</h1>
              <p className="first-info">
                {workshop?.facilitated ? Dash_MB_20_F : Dash_MB_20}
              </p>
              <p className="second-info">
                {workshop?.facilitated ? Dash_MB_21_F : Dash_MB_21}
              </p>
            </div>
          </Modal>

          <Modal
            isOpen={modalIsOpenUnavailable}
            onRequestClose={() => setIsOpenUnavailable(false)}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Action Unavailable"
          >
            <div className="modalUnavailable">
              <div className="close-modal" style={{ textAlign: "right" }}>
                <img
                  src={close}
                  alt=""
                  onClick={() => setIsOpenUnavailable(false)}
                />
              </div>
              <h1>{Dash_MB_22}</h1>
              <p className="second-info">{Dash_MB_23} </p>
              <p className="second-info">
                {Dash_MB_24}{" "}
                <a href="mailto:booking@mission-team.com">
                  booking@mission-team.com
                </a>{" "}
                {Dash_MB_25} <a href="#">+41 41 500 04 77</a>.
              </p>
            </div>
          </Modal>

          <Modal
            isOpen={resendEmailSuccessful}
            onRequestClose={() => set_resendEmailSuccessful(false)}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Resend Booking Email Successful"
          >
            <div className="modalUnavailable resend_popup">
              <h1>{Dash_MB_27}</h1>
              <p className="info">{Dash_MB_28} </p>
              <p className="info client_email">{client_email}</p>
              <button
                onClick={() => set_resendEmailSuccessful(false)}
                className="close_resendModal"
              >
                {Dash_MB_29}
              </button>
            </div>
          </Modal>

          <Modal
            isOpen={openPurchaseLicensesModal}
            onRequestClose={() => {
              set_openPurchaseLicensesModal(false);
            }}
            style={customStyleslogin}
            ariaHideApp={false}
            contentLabel="Purchase Licenses"
          >
            <div className="modal-number">
              <div className="close-modal" style={{ textAlign: "right" }}>
                <img
                  src={close}
                  alt=""
                  onClick={() => {
                    set_openPurchaseLicensesModal(false);
                  }}
                />
              </div>
              <h1 className="noofparttextmodel" style={{ textAlign: "center" }}>
                {Dash_MB_32}
              </h1>
              <p className="popuptext" style={{ textAlign: "center" }}>
                {Dash_MB_33}
              </p>
              <div className="purchase_btns">
                <button
                  className="yes"
                  onClick={() => {
                    set_openPurchaseLicensesModal(false);
                    window.location.href = `/dashboard?lang=${language.selectedLanguage}`;
                  }}
                >
                  {Dash_MB_34}
                </button>
                <button
                  className="no"
                  onClick={() => set_openPurchaseLicensesModal(false)}
                >
                  {Dash_MB_35}
                </button>
              </div>
            </div>
          </Modal>
          <FacilitatedWorkshopRescheduleModal
            bookingItem={workshop}
            rescheduleCallback={OpenPaymentDetails}
            purchaseRedirect={true}
            availableFacilitators={rescheduable}
          />
        </>
      )}

      <Footer />
    </section>
  );
};

export default UpcomingWorkshopDetails;
