import React from 'react'
import './Footer.css';

const Footer = () => {
  return (
    <>
      <div className="container" style={{ marginTop: '200px' }}>
        <footer className="py-4 border-top">
          <div className='row'>
            <div className="col-md-5 col-sm-12 col-lg-6 d-flex align-items-center" >
              <span className="mb-3 mb-md-0 footertxt">PSYfiers® © 2022</span>
            </div>
            <div className='col-md-7 col-sm-12 col-lg-6'>
              <ul className="nav justify-content-end list-unstyled d-flex">
                <li className="ms-3 footertxt">
                  <a href='#'>Terms of service</a>
                </li>
                <li className="ms-3 footertxt">
                  <a href='#'>Imprint</a>
                </li>
                <li className="ms-3 footertxt">
                  <a href='#'>Data protection</a>
                </li>
              </ul>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

export default Footer