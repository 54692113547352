import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./UpdateForm.css";
import { Link } from 'react-router-dom';
import classNames from "classnames";
import Input, {
    getCountries,
    getCountryCallingCode,
} from "react-phone-number-input/input";
import InputField from '../../components/input';
import LeftArrow from "../../images/leftarrow.svg";
import shoppingcart from "../../images/shoppingcart.svg";
import countriesList from "../../utils/data/countriesList.json";
import { AppContext } from "../../context/app.context";
import Modal from 'react-modal';
import { Field, Form, Formik } from "formik";
import { clearSession, convertDate, dateDurationInDays, FieldMandatoryView, fnGetDate, getLocalStorage, setLocalStorage } from '../../utils/common';
import { baseApiUrl } from "../../utils/base";

import { LanguageContext } from "../../context/language.context";
import { getfullcountrylist } from "../../utils/common";



const UpdateForm = () => {

    const navigate = useNavigate();
    const [appState, setAppState] = useContext(AppContext);
    const [countryCallingCodes, setCountryCallingCodes] = useState([]);
    const [FormSubmitting, setFormSubmitting] = useState(false);
    const [userDataHook, setUserData] = React.useState(null);
    const [modalIsOpendelete, setIsOpendelete] = React.useState(false);
    const [modalIsOpenDeleted, setIsOpenDeleted] = React.useState(false);
    const [apiResponse, setapiResponse] = React.useState();

    const [language,setLangauge] = useContext(LanguageContext);
    const usedLanguage = language.translations[language.selectedLanguage];

    const sellang = language.selectedLanguage;
    if (sellang === "") {
        sellang = "en";
    }

    const gobacklink = "/dashboard?lang=" + sellang;
    const purchasehisturl = "/PurchaseHistory?lang=" + sellang;

    const {
        UF_GoBack,
        UF_MyAccount,
        UF_BTNTxt,
        UF_SubTxt,
        UF_DelAcc,
        UF_Confm,
        UF_Cnf1,
        UF_Cnf2,
        UF_Acc_Del,
        UF_Acc_Del_Cnf,
        Uf_Acc_Mist,
        UF_Call_Txt,
        UF_BtnSave,
        UF_Lst_Upd,
        UF_Dl_Pf,
        PC_1,
        PC_2,
        PC_3,
        PC_4,
        PC_5,
        PC_6,
        PC_7,
        PC_8,
        PC_9,
        PC_10,
        PC_11,

    } = usedLanguage.UpdateForm;

    const getLanguageCode = (language) => {

        switch (language) {
            case "english":
                return "EN";
            case "german":
                return "DE";
            case "italian":
                return "IT";
            case "french":
                return "FR";
            default:
                return "english";
        }
    }

    var initialValues = getLocalStorage("contactdetailsLocal");
    var lastupdated = "";
    if (initialValues.lastUpdateAt != undefined) {
        lastupdated = initialValues.lastUpdateAt;
    }
    else {
        lastupdated = initialValues.updatedAt;
    }

    var lngname = getLanguageCode(initialValues.language);
    initialValues.language = lngname;

    const ctlistb = getfullcountrylist();
    if (initialValues.country.length <= 3) {

        let countryname = ctlistb.find((x) => x.code === initialValues.country);
        var cname1 = countryname.code;
        initialValues.country = cname1
    }
    else {
        let countryname = ctlistb.find((x) => x.name === initialValues.country);
        var cname1 = countryname.code;
        initialValues.country = cname1
    }




    const mandatoryFieldStyle = (touched, value) => {
        return {
            border: value === '' ? '#d10707 2px solid' : 'rgb(204, 204, 204)',
        }
    }
    const loaLoginDetails = async () => {
        var apiUrlBooking = baseApiUrl + "client/booking/"
        var getAuthDetails = getLocalStorage("authDetailsLocal");

        fetch(apiUrlBooking + getAuthDetails.userId,
            {
                method: "GET",
                mode: "cors",
                headers: {
                    Accept: "application/json",
                    'Authorization': `Bearer ${getAuthDetails.token}`,
                },
            }
        )
            .then(response => response.json())
            .then(({ data }) => {
                setLocalStorage(data.userData, "userDataLocal");
            }).catch((err) => {

            });
    }

    const submitForm = async (values) => {

        const ctlist = getfullcountrylist();
        let countryname = ctlist.find((x) => x.code === values.country);
        var cname = countryname.name;

        if ((values.companyName === '') || values.firstName === '' || values.lName === '' || values.address === '' ||
            values.postcode === '' || values.email === '' || values.phoneNumber === '') return;

        setFormSubmitting(true);
        var contactDetails = {
            customerId: getLocalStorage("contactdetailsLocal").customerId,
            email: values.email,
            organization: values.organization,
            firstName: values.firstName,
            lastName: values.lastName,
            country: cname,
            address: values.address,
            houseNo: values.houseNo,
            postCode: values.postCode,
            town: values.town,
            language: getLanguage(values.language),
            mobileNo: values.mobileNo,
            landlineNo: values.landlineNo,
        };

        console.log(contactDetails);
        const authDetails = getLocalStorage("authDetailsLocal");
        const url = baseApiUrl + 'client/account/';
        fetch(url, {
            body: JSON.stringify(contactDetails),
            method: "POST",
            mode: "cors",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Authorization': `Bearer ${authDetails.token}`,
            },
        })
            .then(function (promise) {
                return promise.json();
            })
            .then(function (response) {
                setFormSubmitting(false);
                if (response.code === 200) {
                    console.log(JSON.stringify(response.code));
                    contactDetails.updatedAt = convertDate(fnGetDate(new Date()), 'dd/MM/yyyy');
                    setLocalStorage(contactDetails, "contactdetailsLocal");
                    loaLoginDetails();
                    setLangauge({ ...language, selectedLanguage: values.language.toLowerCase() });
                    navigate("/update?lang=" + values.language.toLowerCase());
                }
                else if (response.code !== 200) {
                    setapiResponse(response.message);
                }
            })
            .catch((error) => {
                setFormSubmitting(false);
                setapiResponse(error);
                console.log(error);
            });
    };

    const getLanguage = (code) => {
        switch (code) {
            case "EN":
                return "english";
            case "DE":
                return "german";
            case "IT":
                return "italian";
            case "FR":
                return "french";
            default:
                return "english";
        }
    }


    function openModalDeleted() {
        onDeleteAccount();
        setIsOpenDeleted(true);
        setIsOpendelete(false);

    }

    function openModaldelete() {
        setIsOpendelete(true);
    }

    function afterOpenModal() { }

    function closeModal() {
        setIsOpendelete(false);
        setIsOpenDeleted(false);
    }

    useEffect(() => {
        if (!countryCallingCodes.length) {
            const countriesCodeList = getCountries();
            let callingCodesList = [];
            for (const countryCode of countriesCodeList) {
                const callingCode = getCountryCallingCode(countryCode);
                if (callingCodesList.indexOf(callingCode) === -1) {
                    callingCodesList.push(callingCode);
                }
            }
            callingCodesList.sort((a, b) => a - b);
            setCountryCallingCodes(callingCodesList);
        }
    }, [countryCallingCodes]);

    useEffect(() => {
        if (!countryCallingCodes.length) {
            const countriesCodeList = getCountries();
            let callingCodesList = [];
            for (const countryCode of countriesCodeList) {
                const callingCode = getCountryCallingCode(countryCode);
                if (callingCodesList.indexOf(callingCode) === -1) {
                    callingCodesList.push(callingCode);
                }
            }
            callingCodesList.sort((a, b) => a - b);
            setCountryCallingCodes(callingCodesList);
        }
    }, []);

    const onDeleteAccount = () => {
        
        var authDetails = getLocalStorage("authDetailsLocal");
        const url = baseApiUrl + 'delete_account';
        var datas =
        {
            customerId: authDetails.userId,
        }
        console.log(datas);
        fetch(url, {
            body: JSON.stringify(datas),
            method: "POST",
            mode: "cors",
            headers: {
                Accept: "application/json",
                'Authorization': `Bearer ${authDetails.token}`,
                "Content-Type": "application/json"
            }
        })
            .then(function (promise) {
                return promise.json();
            })
            .then(function (response) {
                console.log(response);
                if (response.code === 200) {
                    clearSession();
                    navigate("/");
                }
                else {
                    setapiResponse(response.message);
                    console.log(response);
                }
            })
            .catch((error) => {
                setapiResponse(error);
                console.log(error);
            });
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '90%',
            padding: '0px',
            maxWidth: '700px',
            transform: 'translate(-50%, -50%)',
            borderRadius: '36px',
            border: 'none',
        },
    };

    const customStylesDeleted = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '90%',
            padding: '0px',
            maxWidth: '900px',
            transform: 'translate(-50%, -50%)',
            borderRadius: '36px',
            border: 'none',
        },
    };



    return (
        <div>
            <section className="UpdateContact-Heading">
                <div className='container' >
                    <div className="row mb-3" >
                        <div className="col-12" style={{ textAlign: 'left', marginTop: '30px', marginBottom: '30px' }}>
                            <img src={LeftArrow} className="leftarrow" alt="" />
                            <Link to={gobacklink} id='goback' className='gobacktext'>{UF_GoBack}</Link>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-7" >
                            <div className='account-title'>
                                <h1>{UF_MyAccount}</h1>
                            </div>
                        </div>
                        <div className="col-lg-5" >
                            <div className='my-account-btn'>
                                <Link id='purchhist' to={purchasehisturl} className='btnpurc'> <img src={shoppingcart} alt="" />{UF_BTNTxt}</Link>
                            </div>
                        </div>
                        <div className="col-12" >
                            <h2 className='subheading'>
                                {UF_SubTxt}
                            </h2>
                        </div>
                    </div>
                </div>
            </section>
            <section className="UpdateContact">
                <div className='container'>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={submitForm}

                    >
                        {({ values, errors, touched, setFieldValue, handleChange }) => (
                            <Form id="salesContact">
                                <div className="row">
                                    <div className="col-md-5 mb-4">
                                        <InputField
                                            name="organization"
                                            placeholder={PC_1}
                                            form={{ errors, touched }}
                                            style={mandatoryFieldStyle(touched.postcode, values.organization)}
                                        />
                                        {values.organization === '' && <FieldMandatoryView />}
                                    </div>
                                    <div className="col-md-5 mb-4">
                                        <InputField
                                            name="firstName"
                                            placeholder={PC_2}
                                            form={{ errors, touched }}
                                            style={mandatoryFieldStyle(touched.firstName, values.firstName)}
                                        />
                                        {touched.firstName && values.firstName === '' && <FieldMandatoryView />}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-5 mb-4">
                                        <Field disabled
                                            as="select"
                                            name="country"
                                            className={classNames(
                                                'input_field',
                                                errors.country && touched.country ? 'input_error' : null
                                            )}
                                            onChange={(e) => {
                                                console.log('hello');
                                                setFieldValue(
                                                    'countryCallingCode',
                                                    getCountryCallingCode(e.target.value || 'CH')
                                                );
                                                handleChange(e);
                                            }}
                                            style={mandatoryFieldStyle(touched.country, values.country)}
                                        >
                                            <option value="" disabled>
                                                country
                                            </option>
                                            {countriesList.map((country) => (
                                                <option key={country.code} value={country.code}>
                                                    {country.name}
                                                </option>
                                            ))}
                                        </Field>
                                        {touched.country && values.country === '' && <FieldMandatoryView />}
                                    </div>
                                    <div className="col-md-5 mb-4">
                                        <InputField
                                            name="lastName"
                                            placeholder={PC_3}
                                            form={{ errors, touched }}
                                            style={mandatoryFieldStyle(touched.lastName, values.lastName)}
                                        />
                                        {values.lastName === '' && <FieldMandatoryView />}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-5 mb-4">
                                        <InputField
                                            name="address"
                                            placeholder={PC_7}
                                            form={{ errors, touched }}
                                            style={mandatoryFieldStyle(touched.address, values.address)}
                                        />
                                        {values.address === '' && <FieldMandatoryView />}
                                    </div>
                                    <div className="col-md-5 mb-4">
                                        <InputField
                                            name="houseNo"
                                            placeholder={PC_4}
                                            form={{ errors, touched }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-5 mb-4">
                                        <InputField
                                            name="postCode"
                                            placeholder={PC_8}
                                            form={{ errors, touched }}
                                            style={mandatoryFieldStyle(touched.postCode, values.postCode)}
                                        />
                                        {values.postCode === '' && <FieldMandatoryView />}
                                    </div>
                                    <div className="col-md-5 mb-4">
                                        <InputField
                                            name="town"
                                            placeholder={PC_5}
                                            form={{ errors, touched }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-5 mb-4">
                                        <Field
                                            as="select"
                                            className={classNames(
                                                'input_field',
                                                errors.language && touched.language ? 'input_error' : null
                                            )}
                                            name="language"
                                            form={{ errors, touched }}
                                        >
                                            <option value="" disabled>
                                                {"language"}
                                            </option>
                                            {["EN", "DE", "IT", "FR"].map((language) => (
                                                <option key={language} value={language}>
                                                    {language}
                                                </option>
                                            ))}
                                        </Field>
                                        {values.language === '' && <FieldMandatoryView />}
                                    </div>
                                    <div className="col-md-5 mb-4">
                                        <InputField
                                            disabled
                                            name="email"
                                            type="email"
                                            placeholder={PC_6}
                                            form={{ errors, touched }}
                                            style={mandatoryFieldStyle(touched.email, values.email)}
                                        />
                                        {values.email === '' && <FieldMandatoryView />}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-1 mb-4">
                                        <Field
                                            as="select"
                                            style={{maxWidth:'90px !important;',padding:'19px 16px !important;'}}
                                            className={classNames(
                                                'input_field number ccode',
                                                errors.countryCallingCode && touched.countryCallingCode ? 'input_error' : null
                                            )}
                                            name="countryCallingCode"
                                        >
                                            {countryCallingCodes.map((countryCode) => (
                                                <option key={countryCode} value={countryCode}>
                                                    +{countryCode}
                                                </option>
                                            ))}
                                        </Field>
                                        {values.countryCallingCodes && touched.countryCallingCodes === '' && <FieldMandatoryView />}
                                    </div>
                                    <div className="col-md-4 mb-4">
                                        <InputField
                                            name="mobileNo"
                                            placeholder="Tel/Mob"
                                            form={{ errors, touched }}
                                            style={mandatoryFieldStyle(touched.mobileNo, values.mobileNo)}
                                        />
                                        {values.mobileNo === '' && <FieldMandatoryView />}
                                    </div>
                                    {/* <div className="col-md-1 mb-4">
                                        <Field
                                            as="select"
                                            className="input_field number"
                                            name="countryCallingCode"
                                        >
                                            {countryCallingCodes.map((countryCode) => (
                                                <option key={countryCode} value={countryCode}>
                                                    +{countryCode}
                                                </option>
                                            ))}
                                        </Field>
                                    </div> */}
                                    {/* <div className="col-md-4 mb-4">
                                        <InputField
                                            name="landlineNo"
                                            placeholder="LandLine Number"
                                            form={{ errors, touched }}
                                        />
                                    </div> */}

                                    <div className="col-md-3 mb-2">
                                        <div className="lastUpdatedRow">
                                            <div className="lastUpdated">
                                                <span className='lastupdate'>{UF_Lst_Upd} {lastupdated}</span>
                                                <button onClick={openModaldelete} className='deleteprofile'>{UF_Dl_Pf}</button>
                                            </div>
                                            {/* */}
                                        </div>
                                    </div>
                                    <div className="col-md-2 mb-4">
                                        <div className="lastUpdatedRow">
                                            <button className='btnsave'>{UF_BtnSave}</button>
                                        </div>

                                    </div>
                                </div>

                                {/* <div className="row">
                                    <div className="col-md-2">{apiResponse}</div>
                                    <div className="col-md-10">
                                        <div className="lastUpdatedRow">
                                            <div className="lastUpdated">
                                                <span className='lastupdate'>{UF_Lst_Upd} {initialValues.lastUpdateAt}</span>
                                                <button onClick={openModaldelete} className='deleteprofile'>{UF_Dl_Pf}</button>
                                            </div>
                                            <button className='btnsave'>{UF_BtnSave}</button>
                                        </div>
                                    </div>
                                </div> */}
                            </Form>
                        )}
                    </Formik>
                </div>
                <Modal
                    isOpen={modalIsOpendelete}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    ariaHideApp={false}
                    contentLabel="Example Modal"
                >   <section className='modalddeleteBox'>
                        {/* <div className='close-modal'>
                            <img src={close} alt="" onClick={closeModal} />
                        </div> */}
                        <h1>{UF_DelAcc}</h1>
                        <p>{UF_Confm}</p>
                        <div className="deleteAccount-action">
                            <button type="button" onClick={closeModal} className="nokeep">{UF_Cnf1}</button>
                            <button type="button" onClick={openModalDeleted} className="yesDelete">{UF_Cnf2}</button>
                        </div>
                    </section>
                </Modal>

                <Modal
                    isOpen={modalIsOpenDeleted}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStylesDeleted}
                    ariaHideApp={false}
                    contentLabel="Example Modal"
                >   <section className='modalDeletedBox'>
                        <h1>{UF_Acc_Del}</h1>
                        <p>{UF_Acc_Del_Cnf}</p>
                        <p>{Uf_Acc_Mist} ,<a href="mailto:booking@mission-team.com">booking@mission-team.com</a>  {UF_Call_Txt} <a href="">+41 41 500 04 77.</a></p>
                    </section>
                </Modal>
            </section >
        </div >
    );
};

export default UpdateForm;