import React, { useContext, useState } from "react";
import logo from "../../images/logo.png";
import logout from "../../images/LogOutIcon.svg";
import logoBriefcase from "../../images/briefcase.svg";
import PropTypes from "prop-types";
import Modal from "react-modal";
import "./Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../context/app.context";
import { baseApiUrl } from "../../utils/base";
import { getLocalStorage, setLocalStorage } from "../../utils/common";

const Navbar = (props) => {
  const navigate = useNavigate();
  const [code, setCode] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [apiResponse, setApiResponse] = React.useState("");
  const [modalIsOpenlogin, setIsOpenlogin] = React.useState(false);
  const [modalIsOpenrequest, setIsOpenrequest] = React.useState(false);
  const [modalIsOpencodesent, setIsOpencodesent] = React.useState(false);
  const [appState, setAppState] = useContext(AppContext);
  var loader = false;
  const showLoader = () => {
    loader = true;
  };

  const hideLoader = () => {
    loader = false;
  };
  function openModalcodesent() {
    setIsOpencodesent(true);
    setIsOpenrequest(false);
  }

  function openModallogin() {
    clearValues();
    setIsOpenlogin(true);
  }

  function openModalrequest() {
    clearValues();
    setIsOpenrequest(true);
    setIsOpenlogin(false);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpenlogin(false);
    setIsOpenrequest(false);
    setIsOpencodesent(false);
  }

  const customStyleslogin = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "90%",
      padding: "0px",
      maxWidth: "901px",
      transform: "translate(-50%, -50%)",
      borderRadius: "36px",
      border: "none",
    },
  };

  const sentCodeApi = () => {
    clearValues();
    var checkCode = email === "";
    if (checkCode) {
      setApiResponse("Email sould not be blank");
      return;
    }
    var apiurl = baseApiUrl + "client/new_code/";
    const apiData = { email: email, user_type: "staff" };
    fetch(apiurl, {
      body: JSON.stringify(apiData),
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(function (response) {
        console.log(response.code);
        if (response.code === 200) {
          openModalcodesent();
        } else {
          setApiResponse(response.message);
        }
      })
      .catch((err) => {
        setApiResponse(err);
      });
  };
  const onStaffLogin = () => {
    clearValues();
    var apiUrlLogin = baseApiUrl + "login";

    var checkCode = code === "";
    if (checkCode) {
      setApiResponse("code should not be blank");
      return;
    }
    const apiLoginData = { login_code: code, user_type: "staff" };
    fetch(apiUrlLogin, {
      body: JSON.stringify(apiLoginData),
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(function (response) {
        if (response.code === 200) {
          setAppState({
            ...appState,
            authDetails: response.data,
          });
          setLocalStorage(response.data, "authDetailsLocalstaff");
          loadClient(response.data);

          navigate("/staffhome");
        } else {
          setApiResponse(response.message);
        }
      })
      .catch((err) => {
        setApiResponse(err);
      });
  };

  const loadClient = (paramauthDetails) => {
    clearValues();
    var authDetails = getLocalStorage("authDetailsLocalstaff");
    if (authDetails === null) {
      authDetails = paramauthDetails;
    }
    var apiUrl = baseApiUrl + "client";
    // var apiData={"customerId":customerIdhk,"licencePrice":unitPriceUpdatedhk };
    fetch(apiUrl, {
      //body: JSON.stringify(apiData),
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authDetails.token}`,
      },
    })
      .then((response) => response.json())
      .then(function (response) {
        if (response.code === 200) {
          setLocalStorage(response.data, "clientLocal");
          setAppState({ ...appState, clientList: response.data });
        } else {
          setApiResponse(response.message);
        }
      })
      .catch((err) => {
        setApiResponse(err);
      });
  };

  const errorCss = {
    color: "red",
    marginLeft: "67px",
  };

  const clearValues = () => {
    setApiResponse("");
  };
  return (
    <div>
      <header className="d-flex flex-wrap justify-content-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-sm-6 col-6">
              <img src={logo} className="logo" alt="" />
            </div>
            <div className="col-lg-6 col-sm-6 col-6">
              <div className="StaffPortalLink">
                {props.showPortalLink ? (
                  <a href="#" onClick={openModallogin}>
                    <img src={logoBriefcase} />
                    <span className="staffportal"> Staff Portal</span>
                  </a>
                ) : (
                  <Link className="ms-auto link-dark logout-button" to="/">
                    <img src={logout} alt="logo" /> Logout
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>

      <Modal
        isOpen={modalIsOpenlogin}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyleslogin}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div className="container login-modal">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="pophdtext">Log into Account </h1>
              <p className="poheadsubtxt">
                Please enter your unique PSYfiers login code
              </p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <div className="backeffectpopup">
                <div className="input-group">
                  <input
                    type="text"
                    id="txtcode"
                    onChange={(e) => setCode(e.target.value)}
                    placeholder="Enter the Code"
                    className="form-control textboxpcode"
                  ></input>
                  <div className="input-group-append">
                    <button
                      id="btnStaffLogin"
                      className="proceedlink"
                      onClick={() => {
                        onStaffLogin();
                      }}
                    >
                      {" "}
                      Log In{" "}
                    </button>
                  </div>
                </div>
              </div>
              <div className="forgotcust" style={errorCss}>
                {apiResponse !== "" ? apiResponse : ""}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <span className="forgotcust">
                Forgot Staff Identification Code?{" "}
                <a href="#" className="clickhere" onClick={openModalrequest}>
                  Click Here
                </a>
              </span>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpenrequest}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyleslogin}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div className="container requestModal">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="pophdtext">Request new Code </h1>
              <p className="poheadsubtxt">
                Please enter your e-mail address and we will send you a new
                code.
              </p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <div className="backeffectpopup">
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control textboxpcode"
                  ></input>
                  <div className="input-group-append">
                    <button
                      className="proceedlink"
                      onClick={() => sentCodeApi()}
                    >
                      Send Code
                    </button>
                  </div>
                </div>
              </div>
              <div className="forgotcust" style={errorCss}>
                {apiResponse !== "" ? apiResponse : ""}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <span className="forgotcust">
                Need more help?{" "}
                <a href="mailto:booking@mission-team.com" className="clickhere">
                  booking@mission-team.com
                </a>
              </span>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpencodesent}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyleslogin}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        {" "}
        <div className="container modalcodesent">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="pophdtext">Your code has been sent! </h1>
              <p className="poheadsubtxt">
                Your new Customer Identification Code has been sent to you.
              </p>
              <p className="poheadsubtxt">
                If you have not received the e-mail, please check first in your
                SPAM folder. If you still don’t find the e-mail contact us via{" "}
                <br></br>{" "}
                <a href="mailto:booking@mission-team.com" className="clickhere">
                  booking@mission-team.com
                </a>{" "}
                or call us{" "}
                <span className="clickhere">+41 41 41 500 04 76.</span>
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

Navbar.defaultProps = {
  showPortalLink: false,
};
Navbar.propTypes = {
  showPortalLink: PropTypes.bool,
};

export default Navbar;
