import React from "react";

import "./preLoader.scss";

const PreLoader = () => {
  return (
    <div className="pre_loader">
      <span className="rotating_icon"></span>
    </div>
  );
};

export default PreLoader;
