import React from "react";
import "./quantity.css";

const QuantityField = ({
  qty,
  onIncrement,
  onDecrement,
  OpenNumberUpdated,
  callback = null,
  disabled,
}) => {
  if (!disabled) {
    return (
      <div className="qty_field_container">
        <div className="qty_field_value">{qty}</div>
        <div className="qty_field_btns_container">
          <button
            className="qty_field_btn small"
            onClick={() => {
              if (OpenNumberUpdated) OpenNumberUpdated();
              if (onIncrement) onIncrement();
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 8.5C1.17157 8.5 0.5 9.17157 0.5 10C0.5 10.8284 1.17157 11.5 2 11.5V8.5ZM18 11.5C18.8284 11.5 19.5 10.8284 19.5 10C19.5 9.17157 18.8284 8.5 18 8.5V11.5ZM11.5 2C11.5 1.17157 10.8284 0.5 10 0.5C9.17157 0.5 8.5 1.17157 8.5 2L11.5 2ZM8.5 18C8.5 18.8284 9.17157 19.5 10 19.5C10.8284 19.5 11.5 18.8284 11.5 18H8.5ZM2 11.5H18V8.5H2V11.5ZM8.5 2L8.5 18H11.5L11.5 2L8.5 2Z"
                fill="#F19202"
              />
            </svg>
          </button>
          <button
            className="qty_field_btn small"
            onClick={() => {
              if (OpenNumberUpdated) OpenNumberUpdated();
              if (onDecrement) onDecrement();
            }}
          >
            <svg
              width="20"
              height="4"
              viewBox="0 0 20 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 0.5C1.17157 0.5 0.5 1.17157 0.5 2C0.5 2.82843 1.17157 3.5 2 3.5V0.5ZM18 3.5C18.8284 3.5 19.5 2.82843 19.5 2C19.5 1.17157 18.8284 0.5 18 0.5V3.5ZM2 3.5H18V0.5H2V3.5Z"
                fill="#F19202"
              />
            </svg>
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="qty_field_container" style={{ opacity: "30%" }}>
        <div className="qty_field_value">{qty}</div>
        <div className="qty_field_btns_container">
          <button className="qty_field_btn small">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 8.5C1.17157 8.5 0.5 9.17157 0.5 10C0.5 10.8284 1.17157 11.5 2 11.5V8.5ZM18 11.5C18.8284 11.5 19.5 10.8284 19.5 10C19.5 9.17157 18.8284 8.5 18 8.5V11.5ZM11.5 2C11.5 1.17157 10.8284 0.5 10 0.5C9.17157 0.5 8.5 1.17157 8.5 2L11.5 2ZM8.5 18C8.5 18.8284 9.17157 19.5 10 19.5C10.8284 19.5 11.5 18.8284 11.5 18H8.5ZM2 11.5H18V8.5H2V11.5ZM8.5 2L8.5 18H11.5L11.5 2L8.5 2Z"
                fill="#F19202"
              />
            </svg>
          </button>
          <button className="qty_field_btn small">
            <svg
              width="20"
              height="4"
              viewBox="0 0 20 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 0.5C1.17157 0.5 0.5 1.17157 0.5 2C0.5 2.82843 1.17157 3.5 2 3.5V0.5ZM18 3.5C18.8284 3.5 19.5 2.82843 19.5 2C19.5 1.17157 18.8284 0.5 18 0.5V3.5ZM2 3.5H18V0.5H2V3.5Z"
                fill="#F19202"
              />
            </svg>
          </button>
        </div>
      </div>
    );
  }
};

export default QuantityField;
