import React, { useContext } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { LanguageContext } from "../../context/language.context";
import { clearLocalStorage } from "../../utils/common";
import Footer from '../../components/Footer/Footer';
import LeftArrow from "../../images/leftarrow.svg";
import { Link } from 'react-router-dom';

import "./thank-you.css";

const ThankYou = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const isSalesContact = searchParams.get("sales");
  const [language] = useContext(LanguageContext);
  const usedLanguage = language.translations[language.selectedLanguage];
  const sellang = language.selectedLanguage;
  if(sellang ==="")
  {
      sellang ="en";
  }

  const dashboardlinl ="/dashboard?lang="+sellang ;

  const { PurchaseInvoice } = usedLanguage.thankYouSalesTrue;
  const { h2, part1,part1_a,part1_b,part1_c, part2, email: adminEmail, part3, phone } = PurchaseInvoice;
  
  useEffect(() => {
    clearLocalStorage();
  }, []);

  return (
    <>

      <section id="thank_you" className="page-gradient-bg bt-thank_you">
       
    
        <div
          className="thank_you_page_container"
          style={{ maxWidth: isSalesContact ? "1200px" : "1200px" }}
        >
            <div style={{marginTop:'10px',marginBottom:'30px'}}>
            <img src={LeftArrow} className="leftarrow" alt="" />
            <Link to={dashboardlinl} id='goback' className='gobacktext'>Dashboard</Link>
          </div>
          {/* icon */}
          <img
            src={require("../../assets/celebrate.png")}
            alt="Celebrate"
            className="celebrate_img"
          />
          
          {/* Purchase/Invoice*/}
          <div className="page_headings_container">
            <h2 className="page_heading">{h2}</h2>
            <div className="page_sub_heading grey">
              {part1}
              <br />
              <br />
              {part1_a}
              <br />
              {part1_b}
              <br />
              <br />
             
              {part1_c}
              <br />
              <br />
              {part2}
              <span style={{ color: '#f19202' }}> {adminEmail}
                </span>&nbsp;
              {part3}
              <span style={{ color: '#f19202' }}> {phone}</span>
           
            </div>
          </div>

        </div>
      </section>
      <section>
        <Footer />
      </section>
    </>
  );
};

export default ThankYou;
