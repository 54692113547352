export const base_url = "/mission-team/teambuilding";

// export const socket_URL = "http://localhost:3001";
export const socket_URL = "https://spatialchat.mission-team.com/";

export const baseApiUrl =
  process.env.REACT_APP_baseApiUrl ||
  "https://api.mission-team.com/api/v1/portal/";
export const baseApiUrlbooking =
  process.env.REACT_APP_baseApiUrlbooking ||
  "https://api.mission-team.com/api/v1/booking/";

// export const baseInstanceDetailsURL =
//   "http://localhost:3003/dashboard/instance/";
export const baseInstanceDetailsURL =
  "https://spatialchat.mission-team.com/dashboard/instance/";
