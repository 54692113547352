import React from 'react';
import logo_header from "../../images/header_logo.png";
import date from "../../images/Date.jpg";
import footer from "../../images/Group.jpg";
import solid from "../../images/Solid.jpg";
import web from "../../images/web.jpg";
import time from "../../images/Time.jpg";
import footer1 from "../../images/footer.jpg";








const TempCheck = () => {


    return (
        <>
        <div>
            <h1>this is test</h1>
        </div>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12"> header image
                        <img src={logo_header} height={'50px'} width={'50px'} alt="" />

                    </div>
                </div>
                <div className="row align-items-center">
                <div className="col-12">
                    <img src={date} height={'50px'} width={'50px'}  alt="" />
                    <img src={time} height={'50px'} width={'50px'}  alt="" />
                    <img src={web} height={'50px'} width={'50px'}  alt="" />
                    <img src={solid} height={'50px'} width={'50px'}  alt="" />
                    <img src={footer} height={'50px'} width={'50px'}  alt="" />
                    <img src={footer1} height={'50px'} width={'50px'}  alt="" />

                </div>
            </div>
            </div>
           

        </>

    )
}

export default TempCheck