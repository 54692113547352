import React, { useContext, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import "./Staff.css";
import createaccounticon from "../../images/Shape.png";
import {} from "react-bootstrap";
import Modal from "react-modal";
import { Link, Navigate, useLocation } from "react-router-dom";
import { AppContext } from "../../context/app.context";
import {
  convertDate,
  getLocalStorage,
  setLocalStorage,
  unitPrice,
} from "../../utils/common";
import { baseApiUrl } from "../../utils/base";
import defaultValues from "../../utils/data/defaultValues.json";
import { useNavigate } from "react-router-dom";

const Staff = ({ props }) => {
  const navigate = useNavigate();
  const [appState, setAppState] = useContext(AppContext);
  const [tabKey, initTabKey] = useState("one");
  const [modalIsOpendUnlocked, setIsOpenUnlocked] = React.useState(false);
  const [modalIsOpendConfirmSend, setIsOpenConfirmSend] = React.useState(false);
  const [modalIsOpendConfirmSend_F, setIsOpenConfirmSend_F] =
    React.useState(false);
  const [acUnlockStatus, setacUnlockStatus] = React.useState(false);
  const [unitPriceUpdatedhk, setUnitPrice] = React.useState(0);
  const [unitPriceUpdatedhk_F, setUnitPrice_F] = React.useState(0);
  const [lockedAccount, setLockedAccount] = useState(false);
  const [customerIdhk, setcustomerId] = React.useState("");
  const [apResponse, setapResponse] = React.useState();
  const [clientLocalhook, setclientLocalhook] = React.useState(
    defaultValues.clientList
  );
  const [status, setStatus] = React.useState();
  const [loaderstatus, setLoaderStatus] = React.useState(true);
  const [fname, setfname] = React.useState("");

  React.useEffect(() => {
    var auth = getLocalStorage("authDetailsLocalstaff");
    loadClient(auth);

    var tempStorage = getLocalStorage("clientLocal");
    setclientLocalhook(tempStorage);
    //setAppState((old) => old);
  }, []);

  const updateUnitPriceLocal = () => {
    var tempStorage = getLocalStorage("clientLocal");
    var temprecords = tempStorage.filter((i) => i._id === customerIdhk);
    var updatedRecords = tempStorage.filter((i) => i._id !== customerIdhk);
    temprecords.licencePrice = unitPriceUpdatedhk;
    updatedRecords.push(temprecords);
    setLocalStorage(updatedRecords, "clientLocal");
  };

  // React.useEffect(() => {
  //
  //     switch (tabKey) {
  //         case "one":
  //             loadClient();

  //             break;
  //         case "two":
  //             loadProspect();
  //             break;
  //         default: break;
  //     }
  // }, [tabKey]);

  const tabChanged = (tabKey) => {
    initTabKey(tabKey);
    switch (tabKey) {
      case "one":
        loadClient();
        break;
      case "two":
        loadProspect();
        break;
      default:
        break;
    }
  };

  function openModalConfirmSend(cutomerid) {
    setIsOpenConfirmSend(true);
    setcustomerId(cutomerid);
    // setUnitPrice(licencePrice);
  }

  function openModalConfirmSend_F(cutomerid) {
    setIsOpenConfirmSend_F(true);
    setcustomerId(cutomerid);
    // setUnitPrice(licencePrice);
  }

  function openModalUnlocked() {
    setIsOpenUnlocked(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpenUnlocked(false);
    setIsOpenConfirmSend(false);
  }
  const loadClient = () => {
    var authDetails = getLocalStorage("authDetailsLocalstaff");
    var apiUrl = baseApiUrl + "client";
    // var apiData={"customerId":customerIdhk,"licencePrice":unitPriceUpdatedhk };
    fetch(apiUrl, {
      //body: JSON.stringify(apiData),
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authDetails.token}`,
      },
    })
      .then((response) => response.json())
      .then(function (response) {
        //console.log(response);
        if (response.code === 200) {
          setLocalStorage(response.data, "clientLocal");
          setAppState({ ...appState, clientList: response.data });
          console.log(response.data);
        } else {
          console.log(response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const customStylesUnlocked = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "90%",
      padding: "0px",
      maxWidth: "804px",
      transform: "translate(-50%, -50%)",
      borderRadius: "36px",
      border: "none",
    },
  };

  const onUpdateClick = (e, cutomerId) => {
    setUnitPrice(e.target.value);
    setcustomerId(cutomerId);
  };

  const onUpdateClick_F = (e, cutomerId) => {
    setUnitPrice_F(e.target.value);
    setcustomerId(cutomerId);
  };

  const onUpdateUnitPrice = () => {
    if (customerIdhk === undefined) return;

    var authDetails = getLocalStorage("authDetailsLocalstaff");
    var apiUrl = baseApiUrl + "update_price";
    var jsonData = {
      customerId: customerIdhk,
      licencePrice: unitPriceUpdatedhk,
    };
    fetch(apiUrl, {
      body: JSON.stringify(jsonData),
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authDetails.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(function (response) {
        //console.log(response);
        if (response.code === 200) {
          loadClient();
        } else {
          console.log(response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setIsOpenConfirmSend(false);
  };

  const onUpdateUnitPrice_F = () => {
    if (customerIdhk === undefined) return;

    var authDetails = getLocalStorage("authDetailsLocalstaff");
    var apiUrl = baseApiUrl + "update_facprice";
    var jsonData = {
      customerId: customerIdhk,
      facilitatorsPrice: unitPriceUpdatedhk_F,
    };
    fetch(apiUrl, {
      body: JSON.stringify(jsonData),
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authDetails.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(function (response) {
        //console.log(response);
        if (response.code === 200) {
          loadClient();
        } else {
          console.log(response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setIsOpenConfirmSend_F(false);
  };

  const onUpdatePayLaterState = (payLaterState, c_id) => {
    if (customerIdhk === undefined) return;

    var authDetails = getLocalStorage("authDetailsLocalstaff");
    var apiUrl = baseApiUrl + "update_paylater";
    var jsonData = {
      customerId: c_id,
      isUseNow: payLaterState ? true : false,
      isPayLater: payLaterState,
    };
    fetch(apiUrl, {
      body: JSON.stringify(jsonData),
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authDetails.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(function (response) {
        //console.log(response);
        if (response.code === 200) {
          loadClient();
        } else {
          console.log(response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setIsOpenConfirmSend_F(false);
  };

  const showLoader = () => {
    setAppState({ ...appState, loader: true });
  };

  const hideLoader = () => {
    setAppState({ ...appState, loader: false });
  };
  const onUnlockAccount = (id, status, fname) => {
    // showLoader();
    setfname(fname);
    var authDetails = getLocalStorage("authDetailsLocalstaff");
    var apiUrl = baseApiUrl + "account_status";
    var apiData = { customerId: id, isLocked: !status };
    fetch(apiUrl, {
      body: JSON.stringify(apiData),
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authDetails.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(function (response) {
        if (response.code === 200) {
          //   loadClient();
          if (status === true) {
            setIsOpenUnlocked(true);
          } else {
            loadClient();
          }
        } else {
          console.log(response.code);
        }
      })
      .catch((err) => {
        console.log(err);
        hideLoader();
      });
    hideLoader();
  };

  const loadProspect = () => {
    var authDetails = getLocalStorage("authDetailsLocalstaff");
    var apiUrl = baseApiUrl + "prospects";
    // var apiData={"customerId":customerIdhk,"licencePrice":unitPriceUpdatedhk };
    fetch(apiUrl, {
      //body: JSON.stringify(apiData),
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authDetails.token}`,
      },
    })
      .then((response) => response.json())
      .then(function (response) {
        console.log(response);
        if (response.code === 200) {
          setLocalStorage(response.data, "prospectsLocal");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onManageChange = (customerId) => {
    var newCustomerDetails = {
      token: getLocalStorage("authDetailsLocalstaff").token,
      role: "staff",
      userId: customerId,
    };

    localStorage.removeItem("authDetailsLocal");
    setLocalStorage(customerId, "customerid");
    setLocalStorage(newCustomerDetails, "authDetailsLocal");
    loadBookingDetails(newCustomerDetails);
  };
  const onCreateAccount = (prospectitem) => {
    var prosepctData = {
      _id: prospectitem._id,
      email: prospectitem.email,
      organization: prospectitem.organization,
      firstName: prospectitem.firstName,
      lastName: prospectitem.lastName,
      mobileNo: prospectitem.mobileNo,
    };

    navigate("/createaccount", { state: prosepctData });
  };
  const loadBookingDetails = (authDetails) => {
    const apiUrlBooking = baseApiUrl + "client/booking/";

    var getAuthDetails = getLocalStorage("authDetailsLocal");
    if (getAuthDetails === null) getAuthDetails = authDetails;

    fetch(apiUrlBooking + getAuthDetails.userId, {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getAuthDetails.token}`,
      },
    })
      .then((response) => response.json())
      .then(function (response) {
        //console.log(response);
        if (response.code === 200) {
          setAppState({
            ...appState,
            bookingList: response.data.bookingList,
            userData: response.data.userData,
          });
          setLocalStorage(response.data.bookingList, "bookingListLocal");
          setLocalStorage(response.data.userData, "userDataLocal");

          var templicenseCalculator = {
            total: response.data.userData.totalLicenses,
            available: response.data.userData.availableLicenses,
            totalFacilitators: response.data.userData.totalFacilitators,
            availableFacilitators: response.data.userData.availableFacilitators,
          };
          setLocalStorage(templicenseCalculator, "licLocal");
          setAppState({
            ...appState,
            licenseCalculator: templicenseCalculator,
          });
          window.open("/dashboard");
          //navigate("/dashboard");
        } else {
          console.log(response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <section className="staff-section" style={{ minHeight: "60vh" }}>
        <div className="container-xxl">
          <div className="row">
            <div className="col-lg-12">
              <Tabs
                className="tab"
                activeKey={tabKey}
                onSelect={(e) => tabChanged(e)}
              >
                <Tab eventKey="one" title="My Clients">
                  <div id="home" style={{ marginTop: "35px" }}>
                    <div className="staff-header">
                      <div className="staff-button">
                        <Link to="/createaccount" className="CreatenewAccount">
                          <span>
                            <img
                              style={{ height: "20px", width: "20px" }}
                              src={createaccounticon}
                              alt=""
                            />
                          </span>
                          Create new account
                        </Link>
                      </div>
                      <div className="paratext">
                        Your clients are listed below
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="table-responsive clients-isted">
                          <table className="table table-borderless clienttable">
                            <thead className="tableheadertext">
                              <tr>
                                <td>Company</td>
                                <td>Orders</td>
                                <td>Workshops</td>
                                <td>Participant License</td>
                                <td>Facilitator License</td>
                                <td>Use Now, Pay later</td>
                                <td>Status</td>
                                <td>Update Account</td>
                              </tr>
                            </thead>
                            <tbody className="tablebodytext">
                              {(appState.clientList || []).map(
                                (item, index) => {
                                  return (
                                    <tr key={"tr_" + index}>
                                      <td>{item.organization}</td>
                                      <td>{item.totalOrder}</td>
                                      <td>{item.workshopcount}</td>
                                      <td>
                                        <div className="liscencepricebackdiv">
                                          <div className="number-block">
                                            <div className="form_field ">
                                              <input
                                                id={"txtUpdate_" + index}
                                                name="updateUnitPrice"
                                                type="text"
                                                placeholder={item.licencePrice}
                                                className="input_field"
                                                onChange={(e) =>
                                                  onUpdateClick(e, item._id)
                                                }
                                              />
                                            </div>
                                          </div>
                                          <span className="chfbacktext">
                                            EUR
                                          </span>
                                          <button
                                            className="btntxt"
                                            onClick={() => {
                                              openModalConfirmSend(item._id);
                                              setLockedAccount(item.isLocked);
                                            }}
                                          >
                                            update
                                          </button>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="liscencepricebackdiv facilitator">
                                          <div className="number-block">
                                            <div className="form_field ">
                                              <input
                                                id={"txtUpdate_" + index}
                                                name="updateUnitPrice"
                                                type="text"
                                                placeholder={
                                                  item.facilitatorsPrice
                                                }
                                                className="input_field"
                                                onChange={(e) =>
                                                  onUpdateClick_F(e, item._id)
                                                }
                                              />
                                            </div>
                                          </div>
                                          <span className="chfbacktext">
                                            EUR
                                          </span>
                                          <button
                                            className="btntxt"
                                            onClick={() => {
                                              openModalConfirmSend_F(item._id);
                                              setLockedAccount(item.isLocked);
                                            }}
                                          >
                                            update
                                          </button>
                                        </div>
                                      </td>
                                      <td>
                                        {" "}
                                        <span
                                          className={`payLater ${
                                            item.isPayLater ? "on" : "off"
                                          }`}
                                          onClick={(e) => {
                                            const currentState =
                                              e.target.classList.contains("on");

                                            if (currentState) {
                                              e.target.classList.remove("on");
                                              e.target.classList.add("off");
                                            } else {
                                              e.target.classList.remove("off");
                                              e.target.classList.add("on");
                                            }
                                            onUpdatePayLaterState(
                                              !currentState,
                                              item._id
                                            );
                                          }}
                                        ></span>{" "}
                                      </td>
                                      <td>
                                        <div className="unlockButton">
                                          <button
                                            className={`locked_status ${
                                              item.isLocked ? "locked" : ""
                                            }`}
                                            onClick={() => {
                                              onUnlockAccount(
                                                item._id,
                                                item.isLocked,
                                                item.firstName
                                              );
                                            }}
                                          >
                                            {!item.isLocked && (
                                              <svg
                                                width="18"
                                                height="23"
                                                viewBox="0 0 18 23"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M10.4235 21.75H7.57647C7.15527 21.75 6.99802 21.7495 6.867 21.7417C4.73678 21.6157 2.95207 20.0845 2.5036 17.9983C2.47601 17.8699 2.45157 17.7146 2.38753 17.2983L2.38752 17.2983L1.78287 13.3681C1.61153 12.2544 2.4732 11.25 3.6 11.25H14.4C15.5268 11.25 16.3885 12.2544 16.2171 13.3681L15.6125 17.2983C15.5484 17.7146 15.524 17.8699 15.4964 17.9983C15.0479 20.0845 13.2632 21.6157 11.133 21.7417C11.002 21.7495 10.8447 21.75 10.4235 21.75Z"
                                                  stroke="#F19202"
                                                  strokeWidth="2.5"
                                                />
                                                <path
                                                  d="M5 10V6.57143C5 4.0467 6.79086 2 9 2C11.2091 2 13 4.0467 13 6.57143"
                                                  stroke="#F19202"
                                                  strokeWidth="2.5"
                                                  strokeLinecap="round"
                                                />
                                                <circle
                                                  cx="9"
                                                  cy="15.5"
                                                  r="1"
                                                  fill="#F19202"
                                                  stroke="#F19202"
                                                />
                                                <path
                                                  d="M9 18V16"
                                                  stroke="#F19202"
                                                  strokeWidth="1.5"
                                                  strokeLinecap="round"
                                                />
                                              </svg>
                                            )}
                                            {item.isLocked && (
                                              <svg
                                                width="18"
                                                height="23"
                                                viewBox="0 0 18 23"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M10.4235 21.75H7.57647C7.15527 21.75 6.99802 21.7495 6.867 21.7417C4.73678 21.6157 2.95207 20.0845 2.5036 17.9983C2.47601 17.8699 2.45157 17.7146 2.38753 17.2983L2.38752 17.2983L1.78287 13.3681C1.61153 12.2544 2.4732 11.25 3.6 11.25H14.4C15.5268 11.25 16.3885 12.2544 16.2171 13.3681L15.6125 17.2983C15.5484 17.7146 15.524 17.8699 15.4964 17.9983C15.0479 20.0845 13.2632 21.6157 11.133 21.7417C11.002 21.7495 10.8447 21.75 10.4235 21.75Z"
                                                  stroke="#D80027"
                                                  strokeWidth="2.5"
                                                />
                                                <path
                                                  d="M5 10V6C5 3.79086 6.79086 2 9 2V2C11.2091 2 13 3.79086 13 6V10"
                                                  stroke="#D80027"
                                                  strokeWidth="2.5"
                                                />
                                                <circle
                                                  cx="9"
                                                  cy="15.5"
                                                  r="1"
                                                  fill="#D80027"
                                                  stroke="#D80027"
                                                />
                                                <path
                                                  d="M9 18V16"
                                                  stroke="#D80027"
                                                  strokeWidth="1.5"
                                                  strokeLinecap="round"
                                                />
                                              </svg>
                                            )}
                                          </button>
                                        </div>
                                      </td>

                                      <td>
                                        <div className="manageAccount">
                                          <button
                                            id={"manageaccount_" + index}
                                            className="managaccountbutton"
                                            onClick={() =>
                                              onManageChange(item._id)
                                            }
                                          >
                                            Manage Account
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="two" title="Prospects">
                  <div id="home1" style={{ marginTop: "35px" }}>
                    <h1 className="paratext">All Prospects are listed below</h1>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="table-responsive prospects-list">
                          <table className="table table-borderless clienttable">
                            <thead className="tableheadertext">
                              <tr>
                                <td>Company</td>
                                <td>Contact Email</td>
                                <td>Phone Number</td>
                                <td>Lead Name</td>
                                <td>Convert To Customer</td>
                              </tr>
                            </thead>
                            <tbody className="tablebodytext">
                              {(getLocalStorage("prospectsLocal") || []).map(
                                (itemp, index) => {
                                  return (
                                    <tr key={"itm_" + index}>
                                      <td>{itemp.organization}</td>
                                      <td>{itemp.email}</td>
                                      <td>{itemp.mobileNo}</td>
                                      <td>
                                        {itemp.firstName + " " + itemp.lastName}
                                      </td>
                                      <td>
                                        <div className="create-button">
                                          {/* <Link id='createaccountgrid' to="/createaccount" state={{itemp}} className='managaccountbutton'>Create Account</Link> */}
                                          <button
                                            id={"createaccountgrid_" + index}
                                            className="managaccountbutton"
                                            onClick={() =>
                                              onCreateAccount(itemp)
                                            }
                                          >
                                            Create Account
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </section>

      <Modal
        isOpen={modalIsOpendUnlocked}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => {
          setIsOpenUnlocked(false);
          loadClient();
        }}
        style={customStylesUnlocked}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        {" "}
        <section className="accountUnlocked">
          <h2 className="modeltext">Account unlocked</h2>
          <p className="modeltext2">
            The account for <span className="spanaccountunlocked">{fname}</span>{" "}
            has been unlocked!
          </p>
        </section>
      </Modal>
      <Modal
        isOpen={modalIsOpendConfirmSend}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStylesUnlocked}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        {" "}
        <section className="confirmSend">
          <h2 className="modeltext">
            Confirm {!lockedAccount && "& send"} price update
          </h2>
          <p className="modeltext2">
            Please confirm the new license price of{" "}
            <span className="spantext">{unitPriceUpdatedhk} EUR</span>.
          </p>
          <div className="button-group my-4">
            <button className="discardbutton" onClick={closeModal}>
              Discard
            </button>
            <button
              className="confirmbutton"
              onClick={() => {
                onUpdateUnitPrice();
              }}
            >
              Confirm {!lockedAccount && "& Send"}
            </button>
            <label>{apResponse}</label>
          </div>
        </section>
      </Modal>
      <Modal
        isOpen={modalIsOpendConfirmSend_F}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => setIsOpenConfirmSend_F(false)}
        style={customStylesUnlocked}
        ariaHideApp={false}
        contentLabel="Update Price"
      >
        {" "}
        <section className="confirmSend">
          <h2 className="modeltext">
            Confirm {!lockedAccount && "& send"} price update
          </h2>
          <p className="modeltext2">
            Please confirm the new Facilitator price of{" "}
            <span className="spantext">{unitPriceUpdatedhk_F} EUR</span>.
          </p>
          <div className="button-group my-4">
            <button
              className="discardbutton"
              onClick={() => setIsOpenConfirmSend_F(false)}
            >
              Discard
            </button>
            <button
              className="confirmbutton"
              onClick={() => {
                onUpdateUnitPrice_F();
              }}
            >
              Confirm {!lockedAccount && "& Send"}
            </button>
            <label>{apResponse}</label>
          </div>
        </section>
      </Modal>
    </div>
  );
};
export default Staff;
