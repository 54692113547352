import React, { useState } from 'react';
import "./RescheduleModal.css";
import { Modal } from "react-bootstrap";
import Calendar from 'react-calendar'

const RescheduleModal = ({ show, handleClose }) => {

    const [Timeupdated, setShowtimeupdated] = useState(false);

    const handleClosenTimeupdated = () => setShowtimeupdated(false);
    const handleShowTimeupdated = () => setShowtimeupdated(true);

    return (
        <>
            <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Body className='calmodalbody'>
                    <div className="row">
                        <div className="col-lg-4 cal">
                            <Calendar />
                            <div className="row">
                                <div className="col-lg-12">
                                    <h6 className='white-text' id="mission">Mission Details</h6>
                                </div>
                                <div className="col-lg-12">
                                    <h6 className="black-text" id="team">No.of Participants:<span className="white-text">12</span></h6>
                                </div>
                                <div className="col-lg-12">
                                    <input type="text" id="reschedule-input" placeholder='Team Name' />
                                </div>
                                <div className="col-lg-12">
                                    <p id="team-note"> The name of your team will appear as the heading of your unique team profile!</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4"></div>
                        <div className="col-lg-8 time-container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <span className='h6hdtext'>Reschedule your workshop date and starting time</span>
                                </div>
                                <div class="col-lg-12">
                                    <span className='head3'>
                                        Select the date of your workshop and then your desired time slot
                                    </span>

                                </div>
                                <div class="col-lg-12">
                                    <div class="row align-items-center">
                                        <div class="col-lg-3 my-2">
                                            <span className='berlintext'>CET:Berlin,Paris,Rome</span>
                                        </div>
                                        <div class="col-lg-4"></div>
                                        <div class="col-lg-5">
                                            <span className='dttimeaval'>Date or time unavailable?</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 time-area">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <button className="grey-button">7:30 AM</button>
                                            <button className="grey-button">8:00 AM</button>
                                            <button className="grey-button">8:30 AM</button>
                                            <button className="grey-button">9:00 AM</button>
                                            <button className="grey-button">9:30 AM</button>
                                            <button className="grey-button">10:00 AM</button>
                                        </div>
                                        <div className="col-lg-3">
                                            <button className="grey-button">10:30 AM</button>
                                            <button className="grey-button" onClick={handleShowTimeupdated}>Confirm  </button>
                                            <button className="grey-button">11:30 AM</button>
                                            <button className="grey-button">12:00 PM</button>
                                            <button className="grey-button">12:30 PM</button>
                                            <button className="grey-button">1:00 PM</button>
                                        </div>
                                        <div className="col-lg-3">
                                            <button className="grey-button">1:30 PM</button>
                                            <button className="grey-button">2:00 PM</button>
                                            <button className="grey-button">2:30 PM</button>
                                            <button className="grey-button">3:00 PM</button>
                                            <button className="grey-button">3:30 PM</button>
                                            <button className="grey-button">4:00 PM</button>
                                        </div>
                                        <div className="col-lg-3">
                                            <button className="grey-button">4:30 PM</button>
                                            <button className="grey-button">5:00 PM</button>
                                            <button className="grey-button">5:30 PM</button>
                                            <button className="grey-button">6:00 PM</button>
                                            <button className="grey-button">6:30 PM</button>
                                            <button className="grey-button">7:00 PM</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={Timeupdated} onHide={handleClosenTimeupdated} size="md" centered className="rounded-modal">
                <Modal.Header closeButton style={{ border: '0px' }}></Modal.Header>
                <Modal.Body className='modalcontwidt'>
                    <div className="row">
                        <div className="col-lg-12">
                            <span className='pop1'>Date and/or Time unavailable?</span>
                        </div>

                    </div>
                    <div className="row">

                        <div className="col-lg-12">
                            <span className='pop2'>
                                We are very sorry! Mission Team Digital Teambuilding is very popular and unfortunately, your chosen date and time are currently not available for a workshop.

                                However, we will provide best efforts to accommodate your request. Please contact us for assistance via <span className='emailtxt'>booking@mission-team.com</span> or call us <span className='emailtxt'>+41 41 500 04 77</span>.
                            </span>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>

    )
}

export default RescheduleModal