import React, { useContext } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { LanguageContext } from "../../context/language.context";
import { clearLocalStorage } from "../../utils/common";
import Footer from '../../components/Footer/Footer';
import LeftArrow from "../../images/leftarrow.svg";
import { Link } from 'react-router-dom';

import "./bt-thank-you.css";

const BTThankYou = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const isSalesContact = searchParams.get("sales");
  const [language] = useContext(LanguageContext);
  const usedLanguage = language.translations[language.selectedLanguage];

  const { BankTransfer } = usedLanguage.thankYouSalesTrue;
  const { h2, maintxt, part2, cemail, part3, phone } = BankTransfer;

  const sellang = language.selectedLanguage;
  if(sellang ==="")
  {
      sellang ="en";
  }

  const dashboardlinl ="/dashboard?lang="+sellang ;

  useEffect(() => {
    clearLocalStorage();
  }, []);

  return (
    <>

      <section id="thank_you" className="page-gradient-bg bt-thank_you">

        <div
          className="thank_you_page_container"
          style={{ maxWidth: isSalesContact ? "1200px" : "1200px" }}
        >
          <div style={{marginTop:'10px',marginBottom:'30px'}}>
            <img src={LeftArrow} className="leftarrow" alt="" />
            <Link to={dashboardlinl} id='goback' className='gobacktext'>Dashboard</Link>
          </div>
          <img
            src={require("../../assets/celebrate.png")}
            alt="Celebrate"
            className="celebrate_img"
          />

          {/* Thank you for your order! */}

          <div className="page_headings_container">
            <h2 className="page_heading">{h2}</h2>
            <div className="page_sub_heading">{maintxt}

            </div>
            <br />

            <div className="page_sub_heading">
              <span>{part2}
                <br></br><span style={{ color: '#f19202' }}> {cemail}
                </span>&nbsp;</span>
              {part3} <span style={{ color: '#f19202' }}>{phone}</span>

            </div>
          </div>
        </div>

      </section>
      <section>
        <Footer />
      </section>
    </>
  );
};

export default BTThankYou;
