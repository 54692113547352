import React, { useState } from 'react'
import logo from "../../images/logo.png";
import logout from "../../images/LogOutIcon.svg";
import "./DashboardNav.css";
import { Modal } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';

import RescheduleModal from '../Dashboard/RescheduleModal';
import { clearSession } from '../../utils/common';

const DashboardNav = () => {
    const navigate = useNavigate();  
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);


    const [show2, setShow2] = useState(false);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [show3, setShow3] = useState(false);

    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);
 const onLogout=()=>
 {
    clearSession();
    navigate("/");
 }

    return (
        <>
            <header className="d-flex flex-wrap justify-content-center">
                <div className="container" style={{ borderBottom: "1px solid #E9E9E9" }}>
                    <div className="row align-items-center">
                        <div className="col-lg-3">
                            <img src={logo} className="logo" alt="" />
                        </div>
                        <div className="col-lg-7" >
                        </div>
                        <div className="col-lg-2 alignright" >
                            <button name="btnLougout" className="ms-auto link-dark logout-button" onClick={()=>{ onLogout()}}>
                            <img src={logout} alt=""  />
                              Logout
                            </button>
                            {/* <Link to="/" className="ms-auto link-dark logout-button"><img src={logout} alt="" /> Logout</Link> */}
                        </div>
                    </div>
                </div>


            </header>

            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12">
                            <table className="table my-1">
                                <thead className="my-1">
                                    <tr>
                                        <td className="text-muted"><small>Workshop Date</small></td>
                                        <td className="text-muted"><small>Workshop Time</small></td>
                                        <td className="text-muted"><small>Group Size</small></td>
                                        <td className="text-muted"><small>Team Name</small></td>
                                        <td className="text-muted"><small>Language</small></td>
                                        <td className="text-muted"><small>Change Booking</small></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><h5><strong>29/02/2022</strong></h5></td>
                                        <td><strong>9:30am - 10:15am</strong></td>
                                        <td><div className="d-flex justify-content-space-between align-items-center">
                                            <h6><strong>12</strong></h6>
                                            <div className="button-group">
                                                <p className="quantity-btn">+</p>
                                                <p className="quantity-btn">-</p>
                                            </div>
                                        </div></td>
                                        <td><h5><strong>
                                            Group 2 UBS
                                        </strong></h5></td>
                                        <td> <h5><strong>
                                            ENG
                                        </strong></h5></td>
                                        <td>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <button className="btn btn-res"
                                                    onClick={setShow3}>
                                                    Reschedule
                                                </button>
                                                <i className="fa-regular fa-copy mx-3"></i>
                                                <i className="fa-solid fa-x mx-3 red-text delete-icon" onClick={handleShow1}></i>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={show1} onHide={handleClose1} size="md" centered className="rounded-modal">
                <Modal.Body>
                    <div className="row px-6">
                        <div className="col-lg-12">
                            <h2 className="text-center">Delete Booking?</h2>
                        </div>
                        <div className="col-lg-12">
                            <p className="text-muted text-center">
                                Are you sure you would like to delete this booking
                            </p>
                        </div>
                        <div className="col-lg-12 px-6">
                            <div className="button-group my-4">
                                <button className='keep-button'>No Keep</button>
                                <button className='delete-button' onClick={handleShow2}>Yes Delete</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={show2} onHide={handleClose2} size="md" centered className="rounded-modal">
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12">
                            <h3 className="text-center">Workshop Deleted!</h3>
                        </div>
                        <div className="col-lg-12 py-2">
                            <p className="text-muted text-center">
                                You have deleted an existing workshop.
                            </p>
                        </div>
                        <div className="col-lg-12 py-2">
                            <p className="text-muted text-center">
                                The number of seats allocated to the deleted workshop has now become available again.You may book a new workshop.
                            </p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <RescheduleModal show={show3} handleClose={handleClose3} />

        </>
    )
}

export default DashboardNav