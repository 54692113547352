import React, { useState, useEffect, useContext } from "react";
import "./PurchaseHist.css";
import { Link } from "react-router-dom";
import LeftArrow from "../../images/leftarrow.svg";
import { baseApiUrl } from "../../utils/base";
import {
  convertDate,
  getCurrencyFormated,
  getLocalStorage,
  setLocalStorage,
} from "../../utils/common";
import { downloadAccessCodes } from "./accessCodeDownload";
import { Buffer } from "buffer";
import { AppContext } from "../../context/app.context";
import Modal from "react-modal";
import { LanguageContext } from "../../context/language.context";

const PurchaseHist = () => {
  const [appState, setAppState] = useContext(AppContext);
  const [state, setState] = useState(true);
  const [orderData, setOrderData] = useState("");
  const [customerData, setCustomerData] = useState("");
  const [loadLoader, setloadLoader] = useState(true);

  const [language] = useContext(LanguageContext);
  const usedLanguage = language.translations[language.selectedLanguage];

  const sellang = language.selectedLanguage;
  if (sellang === "") {
    sellang = "en";
  }

  const gobacklink = "/update?lang=" + sellang;

  const { Dash_Yes, Dash_No } = usedLanguage.Dashboard;

  const {
    PH_goBack,
    PH_Ttl,
    PH_LSt_H1,
    PH_LSt_H2,
    PH_LSt_H3,
    PH_LSt_H4,
    PH_LSt_H5,
    PH_LSt_H6,
    PH_LSt_H7,
    PH_View,
    PH_Download,
  } = usedLanguage.PurchaseHist;

  const LoadData = () => {
    setAppState({ ...appState, loader: true });
    if (state) {
      var getAuthDetails = getLocalStorage("authDetailsLocal");
      const url = baseApiUrl + "client/purchase_history/";

      fetch(url + getAuthDetails.userId, {
        // body:JSON.stringify(data),
        method: "GET",
        mode: "cors",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${getAuthDetails.token}`,
          "Content-Type": "application/json",
        },
      })
        .then(function (promise) {
          return promise.json();
        })
        .then(function (response) {
          console.log(response);

          if (response.code === 200) {
            // debugger;
            setLocalStorage(response.data.orderData, "orderDataLocal");
            //setLocalStorage(response.data.orderData.customerData, "userDataInvoice");
            setOrderData(response.data.orderData);
            //setCustomerData(response.data.customerData);
          } else {
            //console.log(response);
          }
          setAppState({ ...appState, loader: false });
        })
        .catch((error) => {
          console.log(error);
          setAppState({ ...appState, loader: false });
        });
      setState(false);
    }
  };

  const onView = async (orderItem, index) => {
    var userData = orderData[index].customerData;
    console.log(userData); //getLocalStorage("userDataInvoice");
    const tempitems = [];
    if (orderItem.licenseCost === undefined) {
      orderItem.licenseCost = 0;
    }
    var item = {
      id: "1",
      description: orderItem.productName,
      unitPrice: getCurrencyFormated(orderItem.unitPrice) + " EUR", // "0,00 EUR",
      quantity: getCurrencyFormated(orderItem.totalQty),
      totalPrice: orderItem.licenseCost + " EUR", // "0,00 EUR"
    };
    tempitems.push(item);
    console.log(orderItem);
    if (orderItem?.items != null) {
      orderItem?.items.forEach((obj) => {
        tempitems.push({
          id: 2,
          description: obj.productName,
          unitPrice: obj?.unitPrice + " EUR",
          quantity: obj?.totalQty,
          totalPrice: obj?.totalPrice + " EUR",
        });
      });
    }

    var ccode = "";

    if (
      userData.country.toLowerCase() === "switzerland" ||
      userData.country.toLowerCase() === "ch"
    ) {
      ccode = "ch";
    }

    var tempVatPer = 0.0;
    var tempVatAmt = "0,00 EUR";
    if (ccode === "ch") {
      tempVatPer = "7.7";
      tempVatAmt = getCurrencyFormated(orderItem.vatAmount) + " EUR";
    }
    // var checkCountry = userData.country.toLowerCase() === ccode;
    // var tempVatPer = checkCountry ? 7.7 : 0.0;
    // var tempVatAmt = checkCountry ? getCurrencyFormated(orderItem.vatAmount) + " EUR" : "0,00 EUR";
    var tempInvoiceDate = convertDate(orderItem.createdAt, "dd/MM/yyyy").split(
      "/"
    );

    var tempData = {
      invoiceId: orderItem.orderId,
      invoiceDate:
        tempInvoiceDate[0] +
        "-" +
        tempInvoiceDate[1] +
        "-" +
        tempInvoiceDate[2],
      invoiceType: "Invoice",
      invoiceStatus: 1,
      companyName: userData.organization,
      contactPerson: userData.firstName + " " + userData.lastName,
      street: userData.address,
      city: userData.town + " " + userData.country,
      currency: "EUR",
      sum: getCurrencyFormated(orderItem.totalPrice) + " EUR",
      vat: tempVatAmt,
      vatPercent: tempVatPer,
      deliveryCosts: "0,00 EUR",
      total: getCurrencyFormated(orderItem.totalCost) + " EUR",
      paymentTarget: 0,
      qr: "",
      iban: "",
      bic: "",
      items: tempitems,
    };

    //console.log(tempData);
    //return;
    //var url = "https://service.psyfiers.ch/java/service/invoice/pdf?lang=en";
    var getAuthDetails = getLocalStorage("authDetailsLocal");
    var url = baseApiUrl + "client/invoice?lang=" + sellang;
    await fetch(url, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(tempData),
      headers: {
        "Content-Type": "application/json",
        //'Accept': 'application/octet-stream',
        Authorization: `Bearer ${getAuthDetails.token}`,
      },
    })
      .then((res) => res.arrayBuffer())
      .then((data) => {
        //
        var base64Str = Buffer.from(data).toString("base64");

        var binaryString = window.atob(base64Str);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
          var ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }
        var arrBuffer = bytes;

        var newBlob = new Blob([arrBuffer], { type: "application/pdf" });
        var blobURL = URL.createObjectURL(newBlob);
        window.open(blobURL);
      });
  };
  useEffect(() => {
    LoadData();
  }, []);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      height: "300px",
      width: "15%",
      transform: "translate(-50%, -50%)",
      borderRadius: "36px",
      border: 0,
      backgroundColor: "transparent",
    },
  };
  return (
    <div>
      <section>
        <div
          className="container"
          style={{ textAlign: "center", borderTop: "1px solid #E9E9E9" }}
        >
          <div className="row mb-3">
            <div
              className="col-12"
              style={{ textAlign: "left", marginTop: "30px" }}
            >
              <img src={LeftArrow} className="leftarrow" alt="" />
              <Link to={gobacklink} id="goback" className="gobacktext">
                {PH_goBack}
              </Link>
            </div>
          </div>
        </div>
        <div className="container" style={{ marginTop: "40px" }}>
          <div className="row mb-3">
            <div className="col-12">
              <span className="purchase-header">{PH_Ttl}</span>
            </div>
          </div>
        </div>
      </section>
      <section className="purchase-order">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="table-responsive purchaseHist">
                <table className="table table-borderless clienttable">
                  <thead className="tableheadertextpurch">
                    <tr>
                      <td>{PH_LSt_H1}</td>
                      <td>{PH_LSt_H2}</td>
                      <td>{PH_LSt_H3}</td>
                      <td>{PH_LSt_H4}</td>
                      <td>{PH_LSt_H5}</td>
                      <td>{PH_LSt_H6}</td>
                      <td>{PH_LSt_H7}</td>
                    </tr>
                  </thead>
                  <tbody className="tablebodytextpurch">
                    {(orderData || []).map((orderItem, index) => {
                      let qty = 0;
                      if (orderItem.items != null) {
                        // console.log(orderItem.orderId)
                        if (orderItem.items.length > 0) {
                          qty = orderItem.items[0].totalQty;
                        }
                      }
                      return (
                        <tr key={index}>
                          <td>{orderItem.orderId}</td>
                          <td>
                            {convertDate(orderItem.createdAt, "dd/MM/yyyy")}
                          </td>
                          <td>{qty}</td>

                          <td>{orderItem.totalQty}</td>
                          <td>{orderItem.isPaid ? Dash_Yes : Dash_No}</td>
                          <td>
                            <div className="btnview-action">
                              <button
                                className={`btnview ${
                                  !orderItem.isPaid && "disabled"
                                }`}
                                onClick={async () => {
                                  if (!orderItem.isPaid) return;
                                  await onView(orderItem, index);
                                }}
                              >
                                {PH_View}
                              </button>
                            </div>
                          </td>
                          <td>
                            <div className="btnview-action">
                              <button
                                className="btnview"
                                onClick={() => {
                                  downloadAccessCodes(
                                    orderItem.customerId,
                                    orderItem._id
                                  );
                                }}
                              >
                                {PH_Download}
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        isOpen={appState.loader}
        ariaHideApp={true}
        style={customStyles}
        contentLabel="Loader"
      >
        <div
          className="rotating"
          style={{ position: "absolute", left: 50, top: 120 }}
        >
          <img
            src={require("../../assets/loader.svg").default}
            alt="loader"
            height={80}
            width={80}
          />
        </div>
      </Modal>
    </div>
  );
};

export default PurchaseHist;
