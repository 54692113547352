import React from 'react'
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import UpdateForm from '../../components/NewAccount/UpdateForm';
const UpdateAccount = () => {
  return (
    <div className='home-page update-form-page'>
      <Navbar />
      <UpdateForm />
      <Footer />
    </div>
  )
}

export default UpdateAccount