import React, { useState, useContext, useEffect } from "react";
import "./CreateNewWorkshops.css";
import LanguageModal from "../../components/Dashboard/LanguageModal";
import PickDateTime from "../../components/Dashboard/PickDateTime";
import Thankyouimg from "../../images/thankyou.png";
import { AppContext } from "../../context/app.context";
import QuantityField from "../../components/quantity";
import Modal from "react-modal";
import { LanguageContext } from "../../context/language.context";
import close from "../../images/CloseIcon.svg";
import {
  convertTo12Hour,
  getLanguageCode,
  getLocalStorage,
  getTimeSlots,
  setLocalStorage,
  getApiFormateDate,
  getblockpasttime,
} from "../../utils/common";

const customStyleslogin = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "90%",
    padding: "0px",
    maxWidth: "901px",
    transform: "translate(-50%, -50%)",
    borderRadius: "36px",
    border: "none",
  },
};

const self_svg = (
  <svg
    width="111"
    height="110"
    viewBox="0 0 111 110"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="55.6645" cy="25" r="20" stroke="#F19202" strokeWidth="10" />
    <path
      d="M31.4348 69.1313L45.7545 63.9242C52.1564 61.5962 59.1736 61.5962 65.5756 63.9242L79.8953 69.1314C91.3914 73.3118 95.7329 87.3027 88.6229 97.2567C85.1519 102.116 79.5478 105 73.5761 105H37.7539C31.7822 105 26.1782 102.116 22.7072 97.2567C15.5971 87.3027 19.9387 73.3118 31.4348 69.1313Z"
      stroke="#F19202"
      strokeWidth="10"
      strokeLinecap="round"
    />
  </svg>
);

const faciliated_svg = (
  <svg
    width="140"
    height="110"
    viewBox="0 0 140 110"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M121.536 33.6111C121.536 40.0328 115.935 45.7222 108.412 45.7222C100.889 45.7222 95.2881 40.0328 95.2881 33.6111C95.2881 27.1894 100.889 21.5 108.412 21.5C115.935 21.5 121.536 27.1894 121.536 33.6111Z"
      stroke="black"
      strokeOpacity="0.6"
      strokeWidth="10"
    />
    <path
      className="fill_path"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M112.203 88.8943C111.954 91.2394 113.766 93.5001 116.262 93.5001H121.693C127.318 93.5001 132.597 90.9354 135.866 86.6141L136.663 85.5612C142.959 77.2384 139.114 65.5403 128.933 62.0449C121.642 59.5418 113.965 58.4155 106.327 58.666C102.902 58.7784 101.898 63.1668 104.18 65.5815C104.992 66.4411 106.136 66.9159 107.356 66.8921C113.643 66.7698 119.948 67.7385 125.947 69.7982C130.695 71.4284 132.489 76.8843 129.552 80.766L128.755 81.8189C127.126 83.9721 124.496 85.2501 121.693 85.2501H116.245C114.11 85.2501 112.416 86.8884 112.203 88.8943Z"
      fill="black"
      fillOpacity="0.6"
    />
    <path
      d="M80.3759 25C80.3759 35.7786 71.0338 45 58.896 45C46.7581 45 37.416 35.7786 37.416 25C37.416 14.2213 46.7581 5 58.896 5C71.0338 5 80.3759 14.2213 80.3759 25Z"
      stroke="black"
      strokeOpacity="0.6"
      strokeWidth="10"
    />
    <path
      d="M48.8892 63.7218L48.8893 63.7217L48.9167 63.7123L48.9168 63.7123C49.4876 63.5163 49.7191 63.437 49.9421 63.3646C55.7616 61.4756 62.0294 61.4756 67.8489 63.3646L69.3891 58.6198L67.8489 63.3646C68.0719 63.437 68.3035 63.5163 68.8743 63.7123L85.0123 69.2527C96.8375 73.3125 101.241 87.7423 93.6977 97.713C90.2252 102.303 84.8023 105 79.0469 105H38.7441C32.9887 105 27.5659 102.303 24.0933 97.713C16.5498 87.7423 20.9535 73.3125 32.7787 69.2527L48.8892 63.7218Z"
      stroke="black"
      strokeOpacity="0.6"
      strokeWidth="10"
      strokeLinecap="round"
    />
  </svg>
);

const CreateNewWorkshops = (props) => {
  const [appState, setAppState] = useContext(AppContext);
  const [showWorkshopType, setShowWorkshopType] = useState(true);
  const [self_mod, set_self_mod] = useState(true);
  const [showOder, setShowOder] = useState(false);
  const [showLanguage, setShowlanguageModal] = useState(false);
  const [showPickDateTime, setShowPickDateTime] = useState(false);
  const [showPickSuccessfullybooked, setShowSuccessfullybooked] =
    useState(false);
  const [personIngroupCount, setPersonIngroupCount] = useState(0);
  const [languageCode, setLanguageCode] = useState();
  const [selfModeratedWorkshopAvailable, set_selfModeratedWorkshopAvailable] =
    useState(false);
  const [facilitatorWorkshopAvailable, set_facilitatorWorkshopAvailable] =
    useState(false);
  const [openPurchaseLicensesModal, set_openPurchaseLicensesModal] =
    useState(false);

  const [language] = useContext(LanguageContext);
  const usedLanguage = language.translations[language.selectedLanguage];

  useEffect(() => {
    if (
      appState.licenseCalculator?.available &&
      appState.licenseCalculator?.available >= 2
    )
      set_selfModeratedWorkshopAvailable(true);
    else set_selfModeratedWorkshopAvailable(false);
    if (
      appState.licenseCalculator?.availableFacilitators &&
      appState.licenseCalculator?.available >= 2
    )
      set_facilitatorWorkshopAvailable(true);
    else set_facilitatorWorkshopAvailable(false);
  }, [appState]);

  const {
    Dash_CRN_H1,
    workshopTypeModal,
    Dash_CRN_H2,
    Dash_CRN_H3,
    Dash_CRN_H4,
    Dash_CRN_H5,
    Dash_CRN_H6,
    Dash_CRN_H7,
    Dash_CRN_H8,
    Dash_CRN_H9,
    Dash_CRN_H10,
    Dash_CRN_H11,
    Dash_CRN_H12,
    Dash_CRN_H13_0,
    Dash_CRN_H13,
    Dash_CRN_H14,
    Dash_CRN_H15,
    Dash_CRN_H16,
    Dash_CRN_H17,
    Dash_CRN_H17_facilitated,
    Dash_CRN_H18,
    Dash_CRN_H19,
    Dash_AVB_10_THK_7,
    Dash_MB_32,
    Dash_MB_33,
    Dash_MB_34,
    Dash_MB_35,
  } = usedLanguage.Dashboard;

  const {
    h1: typeModalH1,
    self,
    self_desc,
    faciliated,
    faciliated_desc,
  } = workshopTypeModal;

  setLocalStorage(personIngroupCount, "personInGroupCount");

  function openModalWorkshopType() {
    setShowWorkshopType(true);
    setShowOder(false);
    setShowPickDateTime(false);
    setShowlanguageModal(false);
    setShowSuccessfullybooked(false);
    window.location.reload(false);
  }

  function openModalOder() {
    setShowOder(true);
    setShowWorkshopType(false);
    setShowPickDateTime(false);
    setShowlanguageModal(false);
    setShowSuccessfullybooked(false);
  }

  function openModalLanguage() {
    setShowlanguageModal(true);
    setShowOder(false);
    setShowPickDateTime(false);
  }

  function openModalPickDateTime(language) {
    // console.log("Return language", language);
    // const languageCode = getLanguageCode(language);
    // console.log("lang code", languageCode);
    setLanguageCode(language);
    localStorage.setItem("Languagecodenew", languageCode);
    localStorage.removeItem("workshops");
    //updateWorkshopId(languageCode);
    setShowPickDateTime(true);
    setShowlanguageModal(false);
  }

  function openModalSuccessfullybooked() {
    setShowSuccessfullybooked(true);
    setShowPickDateTime(false);
  }

  const onPersonInGoupCalculate = (operator) => {
    var licDetails = getLocalStorage("licLocal");
    var availableLic = licDetails.available;

    var personinGroup = personIngroupCount;
    if (operator === "+") {
      if (personinGroup < 18) {
        if (personinGroup < availableLic && availableLic > 1) {
          personinGroup = personinGroup + 1;
          setPersonIngroupCount(personinGroup);
          setLocalStorage(personinGroup, "personInGroupCount");
        }
      }
    }

    if (operator === "-") {
      if (personinGroup > 0) {
        if (personinGroup > 0) {
          if (availableLic > 0) {
            personinGroup = personinGroup - 1;
            setPersonIngroupCount(personinGroup);
            setLocalStorage(personinGroup, "personInGroupCount");
          }
        }
      }
    }
  };

  const checkAvaiallbeLic = () => {
    var userData = getLocalStorage("licLocal");
    var check = userData.available < 2;
    return check;
  };

  const applyGrayStyle = (check) => {
    return {
      color: check ? "gray" : "black",
    };
  };

  return (
    <section style={{ marginTop: "60px" }}>
      <div className="container">
        <div className="row" style={{ marginTop: "60px" }}>
          <div className="col-lg-12">
            <span className="workshoptxt">{Dash_CRN_H1}</span>
          </div>
        </div>

        <div className="backboxmain">
          {showWorkshopType && (
            <div className="backboxmain-modal">
              <div className="row" style={{ textAlign: "center" }}>
                <div className="col-lg-12" style={{ marginTop: "26px" }}>
                  <h1 className="createmission" style={{ padding: "0 20px" }}>
                    {typeModalH1}
                  </h1>
                </div>
              </div>
              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col-lg-12">
                  <div className="workshop_types">
                    <div
                      className={`type_wrapper ${
                        !selfModeratedWorkshopAvailable ? "disabled" : ""
                      }`}
                    >
                      <div
                        className={`workshop_type self`}
                        onClick={() => {
                          if (!selfModeratedWorkshopAvailable) {
                            set_openPurchaseLicensesModal(true);
                            return;
                          }
                          set_self_mod(true);
                          openModalOder();
                        }}
                      >
                        {self_svg}
                      </div>
                      <p className="type_heading">{self}</p>
                      <p className="type_desc">{self_desc}</p>
                    </div>
                    <div
                      className={`type_wrapper ${
                        !facilitatorWorkshopAvailable ? "disabled" : ""
                      }`}
                    >
                      <div
                        className={`workshop_type facilitated`}
                        onClick={() => {
                          if (!facilitatorWorkshopAvailable) {
                            set_openPurchaseLicensesModal(true);
                            return;
                          }
                          set_self_mod(false);
                          openModalOder();
                        }}
                      >
                        {faciliated_svg}
                      </div>
                      <p className="type_heading">{faciliated}</p>
                      <p className="type_desc">{faciliated_desc}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {showOder && (
            <div className="backboxmain-modal">
              <div className="row" style={{ textAlign: "center" }}>
                <div className="col-lg-12" style={{ marginTop: "26px" }}>
                  <h1 className="createmission">{Dash_CRN_H2}</h1>
                  <span className="subtxt3">{Dash_CRN_H3}</span>
                </div>
              </div>
              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col-lg-12">
                  <div className="table-responsive ">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <div className="persongroup">{Dash_CRN_H4}</div>
                          </td>
                          <td>
                            <div className="timeapprox">{Dash_CRN_H5}</div>
                          </td>
                          <td></td>
                        </tr>
                        <tr className="mdlsec1backlay">
                          <td style={applyGrayStyle(checkAvaiallbeLic())}>
                            <QuantityField
                              qty={personIngroupCount}
                              onIncrement={() => {
                                onPersonInGoupCalculate("+");
                              }}
                              onDecrement={() => {
                                onPersonInGoupCalculate("-");
                              }}
                              disabled={checkAvaiallbeLic()}
                            />
                          </td>
                          <td>
                            <span
                              className="notext"
                              style={applyGrayStyle(checkAvaiallbeLic())}
                            >
                              {Dash_CRN_H6}
                            </span>
                          </td>
                          <td>
                            <div
                              style={{
                                marginTop: "5px",
                                textAlign: "-webkit-right",
                              }}
                            >
                              <div className="modal-proceed">
                                {(() => {
                                  var userData = getLocalStorage("licLocal");
                                  var checkUserData = userData !== null;
                                  var checkAvailalbeLic =
                                    userData.available > 1;
                                  var funalCondition =
                                    checkUserData && checkAvailalbeLic;
                                  if (personIngroupCount > 1)
                                    return (
                                      <button
                                        onClick={openModalLanguage}
                                        className="mdlsec1btnproceed"
                                      >
                                        {Dash_CRN_H7}
                                      </button>
                                    );
                                  else
                                    return (
                                      <button
                                        className="mdlsec1btnproceed"
                                        style={{ opacity: "50%" }}
                                        disabled
                                      >
                                        {Dash_CRN_H7}
                                      </button>
                                    );
                                })()}
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              backgroundColor: " transparent",
                              marginTop: "10px",
                              display: "flex",
                            }}
                            colSpan={3}
                          >
                            <div className="persongroup ">
                              {Dash_CRN_H8}
                              <b style={{ color: "#000000" }}>
                                {(() => {
                                  var userData = getLocalStorage("licLocal");
                                  var checkUserData = userData !== null;
                                  var checkAvailalbeLic =
                                    userData.available > 1;
                                  var funalCondition =
                                    checkUserData && checkAvailalbeLic;
                                  if (funalCondition)
                                    return (
                                      userData.available - personIngroupCount
                                    );
                                  else return 0;
                                })()}
                              </b>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row" style={{ textAlign: "center" }}>
                <div className="col-lg-12" style={{ marginTop: "39px" }}>
                  <div className="importantNotice">
                    <h1 className="impnote">{Dash_CRN_H9}</h1>
                    <p className="notetext1">{Dash_CRN_H10}</p>
                  </div>
                </div>
                <div className="col-lg-12" style={{ marginTop: "24px" }}>
                  <div className="workshop-active">
                    <a
                      id="calqut"
                      className="calfooter"
                      onClick={openModalWorkshopType}
                    >
                      {Dash_CRN_H13_0}
                    </a>
                    <a id="calqut" className="calfooter active">
                      {Dash_CRN_H13}
                    </a>
                    <a
                      id="odsum"
                      onClick={openModalLanguage}
                      className="calfooter"
                      style={{ marginLeft: "15px" }}
                    >
                      {Dash_CRN_H11}
                    </a>
                    <a
                      id="pay"
                      className="calfooter"
                      style={{ marginLeft: "15px" }}
                    >
                      {Dash_CRN_H12}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
          {showLanguage && (
            <div className="backboxmain-modal">
              <LanguageModal
                openModalPickDateTime={openModalPickDateTime}
                self_mod={self_mod}
              ></LanguageModal>
              <div className="row">
                <div
                  className="col-lg-12"
                  style={{ marginTop: "24px", textAlign: "center" }}
                >
                  <div className="workshop-active">
                    <a
                      id="calqut"
                      className="calfooter"
                      onClick={openModalWorkshopType}
                    >
                      {Dash_CRN_H13_0}
                    </a>
                    <a
                      id="calqut"
                      onClick={openModalOder}
                      className="calfooter "
                    >
                      {Dash_CRN_H13}
                    </a>
                    <a
                      id="odsum"
                      className="calfooter active"
                      style={{ marginLeft: "15px" }}
                    >
                      {Dash_CRN_H11}
                    </a>
                    <a
                      id="pay"
                      className="calfooter"
                      style={{ marginLeft: "15px" }}
                    >
                      {Dash_CRN_H12}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
          {showPickDateTime && (
            <div className="backboxmain-modal">
              <PickDateTime
                heading={Dash_CRN_H14}
                changelanguage={true}
                openModalSuccessfullybooked={openModalSuccessfullybooked}
                openModalLanguage={openModalLanguage}
                languageCode={languageCode}
                dateUnavailable={false}
                showPickDateTime={showPickDateTime}
                IsRechedule={false}
                WorkshopId={""}
                selectedDate={""}
                PersonInGroupCount={personIngroupCount}
                TeamName={""}
                BookingId={""}
                self_mod={self_mod}
              ></PickDateTime>
            </div>
          )}
          {showPickSuccessfullybooked && (
            <div className="backboxmain-modal">
              <div className="Successfullybooked">
                <div className="successfully-header">
                  <div className="successfully-title">
                    <img
                      height="106px"
                      width="106px"
                      src={Thankyouimg}
                      alt=""
                    />
                    <h1>{Dash_CRN_H15} </h1>
                  </div>
                  <div className="">
                    <button
                      onClick={openModalWorkshopType}
                      className="btnproceedpay"
                    >
                      {Dash_CRN_H16}
                    </button>
                  </div>
                </div>
                <p>{Dash_CRN_H17}</p>

                {!self_mod && (
                  <p className="orange">{Dash_CRN_H17_facilitated}</p>
                )}

                <p>
                  {Dash_CRN_H18}{" "}
                  <a href="mailto:booking@mission-team.com">
                    booking@mission-team.com
                  </a>{" "}
                  {Dash_AVB_10_THK_7} <a href="#">+41 41 500 04 77</a>.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="container" style={{ marginTop: "16px" }}>
        <div className="row">
          <div className="col-lg-12">
            <span className="notetext1">{Dash_CRN_H19}</span>
          </div>
        </div>
      </div>
      <Modal
        isOpen={
          openPurchaseLicensesModal || appState.openPurchaseLicensesModalForce
        }
        onRequestClose={() => {
          set_openPurchaseLicensesModal(false);
          setAppState({ ...appState, openPurchaseLicensesModalForce: false });
        }}
        style={customStyleslogin}
        ariaHideApp={false}
        contentLabel="Purchase Licenses"
      >
        <div className="modal-number">
          <div className="close-modal" style={{ textAlign: "right" }}>
            <img
              src={close}
              alt=""
              onClick={() => {
                set_openPurchaseLicensesModal(false);
                setAppState({
                  ...appState,
                  openPurchaseLicensesModalForce: false,
                });
              }}
            />
          </div>
          <h1 className="noofparttextmodel" style={{ textAlign: "center" }}>
            {Dash_MB_32}
          </h1>
          <p className="popuptext" style={{ textAlign: "center" }}>
            {Dash_MB_33}
          </p>
          <div className="purchase_btns">
            <button
              className="yes"
              onClick={() => {
                set_openPurchaseLicensesModal(false);
                setAppState({
                  ...appState,
                  openPurchaseLicensesModalForce: false,
                  openPurchaseLicenseModalForce: true,
                });
              }}
            >
              {Dash_MB_34}
            </button>
            <button
              className="no"
              onClick={() => {
                set_openPurchaseLicensesModal(false);
                setAppState({
                  ...appState,
                  openPurchaseLicensesModalForce: false,
                });
              }}
            >
              {Dash_MB_35}
            </button>
          </div>
        </div>
      </Modal>
    </section>
  );
};
export default CreateNewWorkshops;
