import React, { } from 'react';
import StaffNav from '../../components/DashboardHeaderFooter/StaffNav';
import Staff from '../../components/Staff/Staff';
import Footer from '../../components/Footer/Footer';

const StaffHome = ({props}) => {
    return (
        <div className='home-page staf-page'>
            <StaffNav />
            <Staff />
            <Footer />
        </div>
    );
};

export default StaffHome;